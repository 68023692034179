import React from 'react';

import { BrowserRouter, Navigate, useNavigate, useRoutes } from 'react-router-dom';

import Login from './pages/Auth/LoginPage';

import Register from './pages/Auth/RegisterPage';

import PBC_Login from './pages/Auth/PBC/LoginPage';

import PBC_Register from './pages/Auth/PBC/RegisterPage';

import Logs from './pages/Logs';

import BalanceSheet from './pages/BalanceSheet';

import Collects from './pages/Collects';

import Inventories from './pages/Inventories';

import CreateInventory from './pages/Inventories/CreateInventory';

import OrderInventory from './pages/Inventories/CreateInventory/Monitoring/Checagem';

import MonitoringInventory from './pages/Inventories/CreateInventory/Monitoring/TagPicking';

import Almoxarifado from './pages/Inventories/CreateInventory/Monitoring/Almoxarifado';

import ExternInventories from './pages/ExternInventories';

import EILink from './pages/EILink';

import Monitoring from './pages/Monitoring';

import Maps from './pages/Maps';

import MapsC from './pages/MapsContelurb';

import BI from './pages/BI';

import Items from './pages/Items';

import ItemsCopy from './pages/Items/ItemsCopy';

import ItemsDetails from './pages/Items/ItemsDetails';

import ItemsTimeline from './pages/Items/ItemsDetails/ItemsTimeline';

import Partners from './pages/Partners';

import PartnerDetails from './pages/Partners/details';

import Readers from './pages/Readers';

import ReaderDetails from './pages/Readers/ReaderDetails';

import GroupList from './pages/Readers/GroupReaders/GroupList';

import GroupDetails from './pages/Readers/GroupReaders/GroupDetails';

import Export from './components/Export';

import Categories from './pages/Categories';

import Locations from './pages/Locations';

import LocationsImport from './pages/Locations/LocationsImport';

import LocationsImportConfig from './pages/Locations/LocationsImport/ImportConfig';

import Types from './pages/Types';

import Print from './pages/Print';

import Account from './pages/Account';

import Redirect from './components/Redirect';

import PrintOrder from './pages/PrintOrder';

import PrintAdress from './pages/PrintAdress';

import Dashboard from './pages/Dashboard';

import PrivacyPolicy from './pages/Terms/privacyPolicy';

import TermsAndConditions from './pages/Terms/termsConditions';

import UserGuide from './pages/Guide';

import Home from './pages/Home';

import LogActivity from './pages/Activities/index';

import ExportActivity from './pages/Activities/export-page';

import TransportLogin from './pages/Transport/TransportLogin';

import Transport from './pages/Transport';

import ScanQRCode from './pages/Transport/ScanQRCode';

import UploadPhoto from './pages/Transport/UploadPhoto';

import SendData from './pages/Transport/SendData';

// @ NTI

import NTILogin from './pages/NTI/NTILogin';

import NTI from './pages/NTI';

import NTIScanQRCode from './pages/NTI/NTIScanQRCode';

import NTIUploadPhoto from './pages/NTI/NTIUploadPhoto';

import NTISendData from './pages/NTI/NTISendData';

//

import Settings from './pages/settings';

import Orders from './pages/Orders';

import OrderImport from './pages/Orders/OrderImport';

import Layout from './layout';

import OrderDetails from './pages/Orders/OrderDetails';

import dayjs from 'dayjs';

import secureLocalStorage from 'react-secure-storage';

import ImportXML from './pages/Items/Import/ImportXML';

import ImportCSV from './pages/Items/Import/ImportCSV';

import ImportConfig from './pages/Items/Import/ImportConfig';

import ItemsMap from './pages/Items/ItemsMap';

// @ Form
import FormsCreate from './pages/Forms/Form/FormCreate';

import Forms from './pages/Forms';

import FormView from './pages/Forms/Form/FormView';

import FormGroupCreate from './pages/Forms/Group/GroupCreate';

import FormGroupView from './pages/Forms/Group/GroupView';

// @ QRCode Scan
import QRCode from './pages/QRCode';

// @ Registros - Monitoramento
import Registers from './pages/Registers';

// ----------------------------------------------------------------------

function isAuthenticated() {
  const hasToken = sessionStorage.getItem('idConta') ? true : false;

  if (sessionStorage.getItem('disconnect')) {
    const disconnectDate = new Date(sessionStorage.getItem('disconnect'));
    if (dayjs().isSame(disconnectDate) || dayjs().isAfter(disconnectDate)) {
      secureLocalStorage.clear();
      sessionStorage.clear();
    }
  }

  return hasToken;
}

export default function Router() {
  const authenticated = isAuthenticated();

  const navigate = useNavigate();

  const PrivateRoute = ({ element, ...rest }) => {
    if (authenticated) {
      return element;
    }
    return <Redirect />;
  };

  const routes = useRoutes([
    {
      path: '',
      element: <Layout />,
      children: [
        {
          element: <PrivateRoute element={<Navigate to="/home" />} />,
          index: true,
        },
        { path: '/home', element: <PrivateRoute element={<Home />} /> },
        { path: '/logs', element: <PrivateRoute element={<Logs />} /> },
        {
          path: '/balance-sheet',
          element: <PrivateRoute element={<BalanceSheet />} />,
        },
        {
          path: '/collections/collects',
          element: <PrivateRoute element={<Collects />} />,
        },
        {
          path: '/collections/inventories',
          element: <PrivateRoute element={<Inventories />} />,
        },
        {
          path: '/collections/create/:id_modelo?',
          element: <PrivateRoute element={<CreateInventory />} />,
        },
        {
          path: '/collections/extern-inventories/:identificador',
          element: <PrivateRoute element={<EILink />} />,
        },
        {
          path: '/collections/extern-inventories',
          element: <PrivateRoute element={<ExternInventories />} />,
        },
        {
          path: '/registers',
          element: <PrivateRoute element={<Registers />} />,
        },
        {
          path: '/monitoring',
          element: <PrivateRoute element={<Monitoring />} />,
        },
        { path: '/maps', element: <PrivateRoute element={<Maps />} /> },
        {
          path: '/mapsc/:conta',
          element: <PrivateRoute element={<MapsC />} />,
        },
        { path: '/BI', element: <PrivateRoute element={<BI />} /> },
        {
          path: '/items/:params_tag?',
          element: <PrivateRoute element={<Items />} />,
        },
        {
          path: '/items/details',
          element: <PrivateRoute element={<ItemsDetails />} />,
        },
        {
          path: '/items/log',
          element: <PrivateRoute element={<ItemsTimeline />} />,
        },
        {
          path: '/items/import/csv',
          element: <PrivateRoute element={<ImportCSV />} />,
        },
        {
          path: '/items/import/xml',
          element: <PrivateRoute element={<ImportXML />} />,
        },
        {
          path: '/items/import/config/:file',
          element: <PrivateRoute element={<ImportConfig />} />,
        },
        {
          path: '/items/V2',
          element: <PrivateRoute element={<ItemsCopy />} />,
        },
        {
          path: '/items/map',
          element: <PrivateRoute element={<ItemsMap />} />,
        },
        {
          path: '/partners/details/:id_parceiro',
          element: <PrivateRoute element={<PartnerDetails />} />,
        },
        { path: '/partners', element: <PrivateRoute element={<Partners />} /> },
        { path: '/readers', element: <PrivateRoute element={<Readers />} /> },
        {
          path: '/readers/details/:id?',
          element: <PrivateRoute element={<ReaderDetails />} />,
        },
        {
          path: '/readers/groups',
          element: <PrivateRoute element={<GroupList />} />,
        },
        {
          path: '/readers/groups/details/:id?',
          element: <PrivateRoute element={<GroupDetails />} />,
        },
        { path: '/export', element: <PrivateRoute element={<Export />} /> },
        {
          path: '/categories',
          element: <PrivateRoute element={<Categories />} />,
        },
        {
          path: '/locations',
          element: <PrivateRoute element={<Locations />} />,
        },
        {
          path: '/locations/import',
          element: <PrivateRoute element={<LocationsImport />} />,
        },
        {
          path: '/locations/import/config',
          element: <PrivateRoute element={<LocationsImportConfig />} />,
        },
        { path: '/types', element: <PrivateRoute element={<Types />} /> },
        { path: '/print', element: <PrivateRoute element={<Print />} /> },
        {
          path: '/print-adress',
          element: <PrivateRoute element={<PrintAdress />} />,
        },
        {
          path: '/print-order',
          element: <PrivateRoute element={<PrintOrder />} />,
        },
        {
          path: '/account/:page?',
          element: <PrivateRoute element={<Settings />} />,
        },
        {
          path: '/v2/account/:page?',
          element: <PrivateRoute element={<Account />} />,
        },
        {
          path: '/guides/user_guide',
          element: <PrivateRoute element={<UserGuide />} />,
        },
        {
          path: '/dashboard',
          element: <PrivateRoute element={<Dashboard />} />,
        },
        { path: '/home', element: <PrivateRoute element={<Home />} /> },
        {
          path: '/activity',
          element: <PrivateRoute element={<LogActivity />} />,
        },
        {
          path: '/activityexport',
          element: <PrivateRoute element={<ExportActivity />} />,
        },
        { path: '/orders', element: <PrivateRoute element={<Orders />} /> },
        {
          path: '/orders/details/:id_registro?',
          element: <PrivateRoute element={<OrderDetails />} />,
        },
        {
          path: '/orders/upload',
          element: <PrivateRoute element={<OrderImport />} />,
        },

        // @ Formulários dinamicos//
        { path: '/form', element: <PrivateRoute element={<Forms />} /> },
        {
          path: '/form/detail',
          element: <PrivateRoute element={<FormsCreate />} />,
        },
        {
          path: '/form-group/detail',
          element: <PrivateRoute element={<FormGroupCreate />} />,
        },
      ],
    },
    {
      path: '/collections/create/checagem/:id',
      element: <PrivateRoute element={<OrderInventory />} />,
    },
    {
      path: '/collections/create/tag_picking/:id',
      element: <PrivateRoute element={<MonitoringInventory />} />,
    },
    {
      path: '/collections/create/almoxarifado/:id',
      element: <PrivateRoute element={<Almoxarifado />} />,
    },
    { path: '/login/:id_conta?/:token?', element: <Login /> },
    { path: '/register', element: <Register /> },
    { path: '/PBC/login', element: <PBC_Login /> },
    { path: '/PBC/register', element: <PBC_Register /> },
    { path: '/privacy_policy', element: <PrivacyPolicy /> },
    { path: '/terms-and-conditions', element: <TermsAndConditions /> },
    { path: '*', element: <PrivateRoute element={<Navigate to="home" />} /> },
    {
      path: '/transport',
      element: <Transport />,
      children: [
        { element: <Navigate to="qrcode" />, index: true },
        { path: 'login', element: <TransportLogin /> },
        { path: 'qrcode/:id_conta?/:tag?', element: <ScanQRCode /> },
        { path: 'upload-foto', element: <UploadPhoto /> },
        { path: 'confirm-data', element: <SendData /> },
      ],
    },
    {
      path: '/nti',
      element: <NTI />,
      children: [
        { element: <Navigate to="qrcode" />, index: true },
        { path: 'login', element: <NTILogin /> },
        { path: 'qrcode/:id_conta?/:tag?', element: <NTIScanQRCode /> },
        { path: 'upload-foto', element: <NTIUploadPhoto /> },
        { path: 'confirm-data', element: <NTISendData /> },
      ],
    },
    {
      path: '/form/:cod?/:epc?',
      element: <FormView />,
    },
    {
      path: '/form-group/:cod?/:epc?',
      element: <FormGroupView />,
    },
    {
      path: '/qrcode',
      element: <QRCode />,
    },
  ]);

  return routes;
}
