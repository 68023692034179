import { Container } from "@mui/material"
import { GoogleMap, Marker } from "@react-google-maps/api"

import { useLocation } from "react-router-dom"

const mapContainerStyle = {
    width: '100%',
    height: 'calc(100vh - 200px)'
}

export default function ItemsMap(){
    const location = useLocation()

    const { mapItems } = location?.state || []

    return (
        <>
            <Container>
                <GoogleMap
                    className="rounded-5"
                    mapContainerStyle={mapContainerStyle}
                    zoom={20}
                    center={{lat: Number.parseFloat(mapItems[0]?.latitude), lng: Number.parseFloat(mapItems[0]?.longitude)}}
                >
                    {mapItems.filter((item) => item.longitude && item.latitude).map((item, index)=> {
                        return (
                            <Marker
                                key = {index}
                                position={{
                                    lat: Number.parseFloat(item.latitude),
                                    lng: Number.parseFloat(item.longitude)
                                }}
                            />
                        )}
                )} 
                </GoogleMap>
            </Container>
        </>
    )
}