import { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { t } from 'i18next';
import { Icon } from '@iconify/react/dist/iconify.js';
import { grey } from '@mui/material/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/loading';
import Alert from '../../../components/ui/Alert';
import CustomModal from '../../../components/ui/CustomModal';
import QrcodeGenerator from '../../../components/QrcodeGenerator';

//----------------------------------------------------------

const optionsField = [
  {
    value: 'text',
    icon: 'material-symbols:short-text-rounded',
    label: 'Resposta Curta',
  },
  {
    value: 'paragraph',
    icon: 'mdi:text-long',
    label: 'Parágrafo',
  },
  { divider: true },
  {
    value: 'select',
    icon: 'fluent:checkbox-checked-16-regular',
    label: 'Seleção única',
  },
  {
    icon: 'fluent-mdl2:task-list',
    value: 'multiselect',
    label: 'Múltipla escolha',
  },
  {
    value: 'radio-select',
    icon: 'fluent:radio-button-24-filled',
    label: 'Botão de opção (única)',
  },
  { divider: true },
  {
    value: 'upload-image',
    icon: 'mage:image',
    label: 'Upload Imagem (desenvolvendo)',
  },
];

const rastreamentoItensCampos = [
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Observação', value: 'observacao', disabled: false },
  { label: 'Acompanhar Status', value: 'acompanharChamado', disabled: false },
  { label: 'Nome', value: 'nome', disabled: false },
  { label: 'Telefone', value: 'telefone', disabled: false },
  { label: 'Imagem', value: 'imagem', disabled: false },
];

const rastreamentoInventarioCampos = [
  { label: 'Nome (Entregador)', value: 'entregador', disabled: false },
  { label: 'Telefone (Entregador)', value: 'telefone', disabled: false },
  {
    label: 'Documento (recebedor)',
    value: 'recebedor_documento',
    disabled: false,
  },
  { label: 'Nome (recebedor)', value: 'recebedor_nome', disabled: false },
  { label: 'Data de entrega', value: 'dt_entrega', disabled: false },
  { label: 'Cliente Final', value: 'cliente_final', disabled: false },
  { label: 'Cód. de Identificação', value: 'cod_identificacao', disabled: false },
  { label: 'Entrega Efetuada', value: 'entrega_efetuada', disabled: false },
  { label: 'Imagem', value: 'imagem', disabled: false },
];

const modelActions = {
  actionType: '', // POST, GET, PUT, DELETE
  url: '', // URL da API
  preValueBody: { '': '' }, // Valor do body (opcional) - Será adicionado no envio do formulário
  function: '',
};

const modelField = {
  label: '',
  type: 'text',
  required: false,
  options: [
    {
      label: '',
      value: '',
    },
  ],
  value: '',
};

const modelPage = {
  sessionTitle: '',
  fields: [modelField],
};

export default function FormCreate() {
  const navigate = useNavigate();

  const location = useLocation();

  const { form } = location?.state || {};

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState();

  const [title, setTitle] = useState(); // Titulo do formulário

  const [description, setDescription] = useState(''); // Descrição do formulário

  const [pages, setPages] = useState([modelPage]); // Armazena as sessões (sessions)

  const [actions, setActions] = useState([modelActions]); // Armazena as ações

  const [optionsDestination, setOptionsDestination] = useState([]);

  const [saveLocate, setSaveLocate] = useState(false); //Salvar Localização latitude/longitude

  const [QRCode, setQRCode] = useState(null); // Armazena a URL do formulário para gerar QRCode

  useEffect(() => {
    function handleDetails(form) {
      const { title, description, sessions, actions, save_locate } = form;
      setTitle(title || '');
      setDescription(description || '');
      setPages(sessions || [{}]);
      setActions(actions || [{}]);
      setSaveLocate(save_locate || false);
    }

    if (form) {
      handleDetails(form);
    }
  }, [form]);

  useEffect(() => {
    if (actions) {
      let opts = [];

      if (actions.find((act) => act.function === 'rastreamentoItem')) {
        opts = opts.concat(rastreamentoItensCampos);
      }

      if (actions.find((act) => act.function === 'rastreamentoInventario')) {
        opts = opts.concat(rastreamentoInventarioCampos);
      }

      if (actions.find((act) => act.function === 'enviarEmail')) {
        opts = opts.concat({ label: 'Email', value: 'email', disabled: false });
      }

      setOptionsDestination(opts);
    }
  }, [actions]);

  function handleDelete() {
    setLoading(true);
    api
      .delete(`/forms/${form?._id}`)
      .then((response) => {
        setMessage({
          type: 'success',
          message: t('messages.success_save'),
          title: t('messages.success'),
        });
        setLoading(false);
      })
      .catch(() => {
        setMessage({
          type: 'error',
          message: t('messages.errors.500'),
          title: t('messages.errors.error'),
        });
        setLoading(false);
      });
  }

  function handleSave() {
    const upcommingForm = {
      ...form,
      id_conta: sessionStorage.getItem('idConta'),
      title: title || '',
      description: description || '',
      sessions: pages,
      actions: actions,
      save_locate: saveLocate,
    };

    setLoading(true);

    if (form?._id) {
      api
        .put(`/forms/${form?._id}`, [upcommingForm])
        .then((response) => {
          setMessage({
            type: 'success',
            message: t('messages.success_save'),
            title: t('messages.success'),
          });
          setLoading(false);
        })
        .catch(() => {
          setMessage({
            type: 'error',
            message: t('messages.errors.500'),
            title: t('messages.errors.error'),
          });
          setLoading(false);
        });
    } else {
      api
        .post('/forms', [upcommingForm])
        .then((response) => {
          setMessage({
            type: 'success',
            message: t('messages.success_save'),
            title: t('messages.success'),
          });
          setLoading(false);
        })
        .catch(() => {
          setMessage({
            type: 'error',
            message: t('messages.errors.500'),
            title: t('messages.errors.error'),
          });
          setLoading(false);
        });
    }
  }

  function handlePreview() {
    const upcommingForm = {
      ...form,
      title: title,
      description: description,
      sessions: pages,
      actions: actions,
    };
    navigate('/form/preview', {
      state: { formState: upcommingForm, preview: true },
    });
  }

  function addOption() {
    AlertDialog.fire({
      title: `Nova Opção`,
      confirmText: t('actions.continue'),
      cancelText: t('actions.cancel'),
      fields: [{ name: 'option', formLabel: 'Opção', type: 'text' }],
      onConfirm: (data) => {
        const { option } = data;
        if (option) {
          setOptionsDestination([...optionsDestination, { label: option, value: option, disabled: false }]);
        }
      },
      onCancel: () => console.log('Formulário cancelado'),
    });
  }

  return (
    <>
      <Container maxWidth="md">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton>
                <Icon icon="fluent:form-sparkle-20-regular" />
              </IconButton>
              <div>
                <Typography variant="h1">{t('forms.form')}</Typography>
                {form?.cod && (
                  <Typography variant="subtitle" textTransform="uppercase">
                    IDENTIFICADOR: {form?.cod}
                  </Typography>
                )}
              </div>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Tooltip title={!form?.cod ? 'Salve antes de gerar o qrcode' : ''}>
                <span>
                  <Button
                    disabled={!form?._id}
                    variant="outlined"
                    color="dark"
                    startIcon={<Icon icon="icomoon-free:qrcode" />}
                    onClick={() => {
                      setQRCode(`https://www.inovacode.app.br/form/${form?.cod}`);
                    }}
                  >
                    QRCode
                  </Button>
                </span>
              </Tooltip>
              <Button
                variant="outlined"
                color="dark"
                startIcon={<Icon icon="fluent:eye-tracking-24-regular" />}
                onClick={() => {
                  handlePreview();
                }}
              >
                Preview
              </Button>
              {form?._id && (
                <Button
                  variant="outlined"
                  color="dark"
                  startIcon={<Icon icon="mage:trash" />}
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  {t('actions.delete')}
                </Button>
              )}
              <Button
                variant="outlined"
                color="dark"
                onClick={() => {
                  navigate('/form');
                }}
              >
                {t('actions.exit')}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleSave();
                }}
              >
                {t('actions.save')}
              </Button>
            </Stack>
          </Stack>

          <Stack as={Paper} spacing={3} sx={{ borderRadius: 3, p: 5 }}>
            <TextField
              variant="standard"
              placeholder={t('forms.no_title')}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              InputProps={{
                style: {
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                },
              }}
            />
            <TextField
              variant="standard"
              placeholder={t('forms.description')}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Stack>

          <Stack as={Paper} spacing={3} sx={{ borderRadius: 3, p: 5 }}>
            <Typography variant="h3">Função do Formulário</Typography>

            <Stack direction="column">
              <Typography variant="subtitle">Funções que serão acessadas ao clicar em enviar.</Typography>
              <FormControlLabel
                fullWidth
                control={
                  <Checkbox
                    fullWidth
                    checked={saveLocate}
                    onChange={(e) => {
                      setSaveLocate(e.target.checked);
                    }}
                  />
                }
                label="Salvar Localização (latitude e longitude)"
              />
            </Stack>
            <Stack spacing={3} divider={<Divider sx={{ borderStyle: 'dashed', borderColor: grey[500] }} />}>
              {actions.map((action, actionIndex) => (
                <>
                  <FormControl>
                    <FormLabel>Função ao Enviar Formulário</FormLabel>
                    <Select
                      value={action?.function}
                      onChange={(e) => {
                        const updatedPages = [...actions];
                        updatedPages[actionIndex].function = e.target.value;
                        setActions(updatedPages);
                      }}
                      size="small"
                    >
                      <MenuItem value="rastreamentoInventario">Rastreio Inventário (adiciona Rastreio)</MenuItem>
                      <MenuItem value="rastreamentoItem">Rastreio Item (Ex.: Chamado, Manutenção, etc.)</MenuItem>
                      <MenuItem value="enviarEmail">Enviar Email</MenuItem>
                      <MenuItem value="outro">Outro</MenuItem>
                    </Select>
                  </FormControl>
                  {action.function == 'enviarEmail' && (
                    <>
                      <FormControl>
                        <FormLabel>Corpo do email</FormLabel>
                        <TextField
                          multiline
                          value={action?.preValueBody?.message}
                          onChange={(e) => {
                            const updatedPages = [...actions];
                            updatedPages[actionIndex].preValueBody.message = e.target.value;
                            setActions(updatedPages);
                          }}
                        />
                      </FormControl>
                    </>
                  )}
                  {action.function == 'outro' && (
                    <>
                      <FormControl>
                        <FormLabel>Ação</FormLabel>
                        <Select
                          size="small"
                          value={action.actionType}
                          onChange={(e) => {
                            const updatedPages = [...actions];
                            updatedPages[actionIndex].actionType = e.target.value;
                            setActions(updatedPages);
                          }}
                        >
                          <MenuItem value="POST">POST</MenuItem>
                          <MenuItem value="PUT">PUT</MenuItem>
                          <MenuItem value="GET">GET</MenuItem>
                          <MenuItem value="DELETE">DELETE</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Rota (url)</FormLabel>
                        <TextField
                          size="small"
                          value={action.url}
                          onChange={(e) => {
                            const updatedPages = [...actions];
                            updatedPages[actionIndex].url = e.target.value;
                            setActions(updatedPages);
                          }}
                        />
                      </FormControl>
                      <div>
                        <Typography variant="h6">Corpo da requisição</Typography>
                        <Typography variant="subtitle">Será adicionado ao resultado do formulário</Typography>
                      </div>
                      {Object.keys(action?.preValueBody).map((body, bodyIndex) => (
                        <Stack direction="row" alignItems="end" spacing={3}>
                          <FormControl fullWidth>
                            <FormLabel>Campo</FormLabel>
                            <TextField
                              size="small"
                              value={body}
                              onChange={(e) => {
                                const updatedPages = [...actions];
                                updatedPages[actionIndex].preValueBody[e.target.value] =
                                  updatedPages[actionIndex].preValueBody[bodyIndex];
                                setActions(updatedPages);
                              }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <FormLabel>Resultado</FormLabel>
                            <TextField
                              size="small"
                              value={action?.preValueBody[bodyIndex]}
                              onChange={(e) => {
                                const updatedPages = [...actions];
                                updatedPages[actionIndex].preValueBody[bodyIndex] = e.target.value;
                                setActions(updatedPages);
                              }}
                            />
                          </FormControl>
                          <IconButton
                            onClick={(e) => {
                              const updatedPages = [...actions];
                              updatedPages[actionIndex].preValueBody[
                                'campo ' + Object.keys(action?.preValueBody).length
                              ] = '';
                              setActions(updatedPages);
                            }}
                          >
                            <Icon icon="mage:plus" />
                          </IconButton>
                        </Stack>
                      ))}
                    </>
                  )}
                </>
              ))}
              <Button
                variant="contained"
                startIcon={<Icon icon="mage:plus" />}
                onClick={() => {
                  setActions((prev) => [...prev, modelActions]);
                }}
              >
                Função
              </Button>
            </Stack>
          </Stack>

          {pages.map((page, pageIndex) => (
            <Stack key={pageIndex} as={Paper} sx={{ borderRadius: 3 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{
                  p: 3,
                  py: 1,
                  border: 0,
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                {/* Page Title Section */}
                <Stack direction="row" alignItems="center">
                  <IconButton>
                    <Icon icon="proicons:document" />
                  </IconButton>
                  <TextField
                    variant="standard"
                    label=""
                    placeholder={`${t('forms.page')} ${pageIndex + 1}`}
                    value={page.sessionTitle}
                    onChange={(e) => {
                      const updatedPages = [...pages];
                      updatedPages[pageIndex].sessionTitle = e.target.value;
                      setPages(updatedPages);
                    }}
                  />
                </Stack>

                <Typography variant="subtitle">
                  Pg. {pageIndex + 1} de {pages.length}
                </Typography>
              </Stack>

              {/* Fields Section */}
              <Stack
                sx={{ p: 3 }}
                spacing={3}
                divider={<Divider sx={{ borderColor: grey[500], borderStyle: 'dashed' }} />}
              >
                {page?.fields?.map((field, fieldIndex) => (
                  <Stack key={fieldIndex} spacing={1}>
                    <Typography variant="h6">
                      {t('forms.question')} {fieldIndex + 1}
                    </Typography>

                    <Stack direction="row" spacing={3}>
                      {/* TextField for Question */}
                      <FormControl fullWidth>
                        <FormLabel>{t('forms.question')}</FormLabel>
                        <TextField
                          fullWidth
                          size="small"
                          value={field.label || ''}
                          onChange={(e) => {
                            const updatedPages = [...pages];
                            updatedPages[pageIndex].fields[fieldIndex] = {
                              ...updatedPages[pageIndex].fields[fieldIndex],
                              label: e.target.value,
                            };
                            setPages(updatedPages);
                          }}
                        />
                      </FormControl>

                      {/* Select for Field Type */}
                      <FormControl fullWidth>
                        <FormLabel>Campo de destino</FormLabel>
                        <Select
                          fullWidth
                          size="small"
                          value={field.value || ''}
                          onChange={(e) => {
                            const updatedPages = [...pages];
                            updatedPages[pageIndex].fields[fieldIndex] = {
                              ...updatedPages[pageIndex].fields[fieldIndex],
                              value: e.target.value,
                            };
                            setPages(updatedPages);
                          }}
                        >
                          {optionsDestination.map((destination) => (
                            <MenuItem value={destination.value}>{destination.label}</MenuItem>
                          ))}
                          <ListItemButton
                            onClick={() => {
                              addOption();
                            }}
                          >
                            <Stack direction="row" spacing={1}>
                              <Icon icon="mage:plus" />
                              <Typography variant="subtitle">Adicionar opção</Typography>
                            </Stack>
                          </ListItemButton>
                        </Select>
                      </FormControl>
                    </Stack>

                    {/* Select for Field Type */}
                    <FormControl>
                      <FormLabel>Tipo de campo</FormLabel>
                      <Select
                        fullWidth
                        size="small"
                        value={field.type || ''}
                        onChange={(e) => {
                          const updatedPages = [...pages];
                          updatedPages[pageIndex].fields[fieldIndex] = {
                            ...updatedPages[pageIndex].fields[fieldIndex],
                            type: e.target.value,
                          };
                          setPages(updatedPages);
                        }}
                      >
                        {optionsField.map((item, index) => (
                          <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
                            <Stack direction="row" alignItems="center" spacing={3}>
                              <Icon icon={item.icon} />
                              <Typography>{item.label}</Typography>
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {field?.type?.includes('select') && (
                      <>
                        <Typography>Opções:</Typography>
                        {field?.options?.map((option, optionIndex) => (
                          <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Icon icon="pepicons-pop:circle" width={20} />
                              <TextField
                                variant="standard"
                                size="small"
                                placeholder={`${t('forms.option')} visível ${optionIndex + 1}`}
                                value={option.label || ''}
                                onChange={(e) => {
                                  const updatedPages = [...pages];
                                  const options = field.options;
                                  options[optionIndex] = {
                                    ...options[optionIndex],
                                    label: e.target.value,
                                  };
                                  updatedPages[pageIndex].fields[fieldIndex] = {
                                    ...updatedPages[pageIndex].fields[fieldIndex],
                                    options: options,
                                  };
                                  setPages(updatedPages);
                                }}
                                sx={{
                                  width: '25ch',
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none', // remove a borda inferior
                                  },
                                }}
                              />
                              <TextField
                                variant="standard"
                                size="small"
                                placeholder={`Valor na API (opcional)`}
                                value={option.value || ''}
                                onChange={(e) => {
                                  const updatedPages = [...pages];
                                  const options = field.options;
                                  options[optionIndex] = {
                                    ...options[optionIndex],
                                    value: e.target.value,
                                  };
                                  updatedPages[pageIndex].fields[fieldIndex] = {
                                    ...updatedPages[pageIndex].fields[fieldIndex],
                                    options: options,
                                  };
                                  setPages(updatedPages);
                                }}
                                sx={{
                                  width: '25ch',
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none', // remove a borda inferior
                                  },
                                }}
                              />
                              <IconButton
                                color="error"
                                onClick={(e) => {
                                  const updatedPages = [...pages];
                                  const options = field.options.filter((op) => op !== option);
                                  updatedPages[pageIndex].fields[fieldIndex] = {
                                    ...updatedPages[pageIndex].fields[fieldIndex],
                                    options: options,
                                  };
                                  setPages(updatedPages);
                                }}
                              >
                                <Icon icon="mage:multiply" width={18} />
                              </IconButton>
                              {optionIndex + 1 === field?.options?.length && (
                                <Tooltip title={`${t('actions.create')} ${t('forms.option')}`}>
                                  <IconButton
                                    color="primary"
                                    sx={{
                                      backgroundColor: 'primary.lighter',
                                    }}
                                    onClick={(e) => {
                                      const updatedPages = [...pages];
                                      const options = field.options;
                                      options.push([]);
                                      updatedPages[pageIndex].fields[fieldIndex] = {
                                        ...updatedPages[pageIndex].fields[fieldIndex],
                                        options: options,
                                      };
                                      setPages(updatedPages);
                                    }}
                                  >
                                    <Icon icon="mage:plus" width={18} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Stack>
                          </>
                        ))}
                      </>
                    )}

                    <Stack direction="row" justifyContent="end">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field?.required || ''}
                            onChange={(e) => {
                              const updatedPages = [...pages];
                              updatedPages[pageIndex].fields[fieldIndex] = {
                                ...updatedPages[pageIndex].fields[fieldIndex],
                                required: e.target.checked,
                              };
                              setPages(updatedPages);
                            }}
                          />
                        }
                        label={t('forms.required')}
                      />

                      {/* Deletar campo */}
                      <Button
                        color="error"
                        startIcon={<Icon icon="mage:trash" />}
                        onClick={() => {
                          const updatedPages = [...pages];
                          updatedPages[pageIndex].fields = updatedPages[pageIndex].fields.filter(
                            (_, index) => index !== fieldIndex
                          );
                          setPages(updatedPages);
                        }}
                      >
                        {t('actions.delete')}
                      </Button>
                    </Stack>
                  </Stack>
                ))}
              </Stack>

              {/* Footer Section */}
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                sx={{
                  p: 3,
                  border: 0,
                  borderTop: 1,
                  borderStyle: 'dashed',
                  borderColor: 'divider',
                }}
              >
                {/* Add and Delete Buttons */}
                <Button
                  variant="contained"
                  startIcon={<Icon icon="mage:plus" />}
                  onClick={() => {
                    const updatedPages = [...pages];
                    updatedPages[pageIndex] = {
                      ...updatedPages[pageIndex],
                      fields: [...(updatedPages[pageIndex].fields || []), structuredClone(modelField)],
                    };
                    setPages(updatedPages);
                  }}
                >
                  {t('forms.question')}
                </Button>
                <IconButton
                  onClick={() => {
                    const updatedPages = pages.filter((_, idx) => idx !== pageIndex);
                    setPages(updatedPages);
                  }}
                >
                  <Icon icon="mage:trash" />
                </IconButton>
              </Stack>
            </Stack>
          ))}

          <Button
            variant="contained"
            startIcon={<Icon icon="mage:plus" />}
            onClick={() => {
              setPages((prev) => [...prev, structuredClone(modelPage)]);
            }}
          >
            {t('forms.page')}
          </Button>
        </Stack>
      </Container>

      <Loading show={loading} />

      {message ? (
        <Alert
          type={message.type}
          title={message.title}
          message={message.message}
          onClose={(e) => {
            setMessage(null);
          }}
        />
      ) : (
        ''
      )}

      {QRCode ? (
        <CustomModal title="QRCode" dividers={false} icon="icomoon-free:qrcode">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <QrcodeGenerator value={QRCode} width={200} />
          </Stack>
        </CustomModal>
      ) : (
        ''
      )}
    </>
  );
}
