import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';

import { grey } from '@mui/material/colors';

import {
  Stack,
  Typography,
  Button,
  Grid,
  Box,
  IconButton,
  TextField,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  Paper,
  Grid2,
  Container,
} from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

import api from '../../services/api';

import inovasup from '../../services/inovasup';

import emailjs from 'emailjs-com';

import Alert from '../../components/ui/Alert';
import { t } from 'i18next';

//-----------------------------------------------------------------

export default function SendData() {
  const navigate = useNavigate();

  const location = useLocation();

  const props = location?.state || {};

  const { item, foto } = props || {};

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState();

  const usuario = secureLocalStorage.getItem('transport-user');

  const [status, setStatus] = useState();

  const [observacao, setObservacao] = useState('');

  const [acompanharChamado, setAcompanharChamado] = useState(false);

  useEffect(() => {
    if (!item || !usuario?.nome) {
      setMessage({
        type: 'error',
        message: t('messages.errors.500'),
        title: t('messages.errors.error'),
        function: () => {
          navigate('/transport/qrcode');
        },
      });
    }
  }, []);

  function handleSave() {
    if (foto) {
      uploadFoto();
    } else {
      uploadItem();
    }
  }

  function uploadFoto() {
    setLoading(true);

    const option = {
      headers: { 'Content-Type': ['application/json'] },
    };

    const { id_conta } = item;

    //Upload Imagem
    api.post(`/save_jpeg/${id_conta}`, [{ foto: foto }], option).then(
      (response) => {
        uploadItem(response?.data[0]?.id_foto);
      },
      (error) => {
        setLoading(false);
        setMessage({
          type: 'error',
          message: t('messages.errors.500'),
          title: t('messages.errors.error'),
        });
      }
    );
  }

  function uploadItem(id_imagem = '') {
    setLoading(true);

    const { _id, id_conta } = item;
    const upcommingReg = {
      id_item: _id,
      id_conta,
      status,
      observacao,
      acompanharChamado,
      imagem: id_imagem,
      nome: usuario?.nome,
      telefone: usuario?.telefone,
    };

    const option = {
      headers: { 'Content-Type': ['application/json'] },
    };

    api.post(`/rastreamentoItem`, [upcommingReg], option).then(
      (response) => {
        setLoading(false);
        setMessage({
          type: 'success',
          message: t('messages.success_save'),
          title: t('messages.success'),
        });
      },
      (error) => {
        setLoading(false);
        setMessage({
          type: 'error',
          message: t('messages.errors.500'),
          title: t('messages.errors.error'),
        });
      }
    );
  }

  return (
    <>
      <Stack spacing={3}>
        <Stack direction="row">
          <Button
            color="dark"
            startIcon={<Icon icon="iconamoon:arrow-left-2-bold" />}
            onClick={() => {
              navigate('/transport/upload-foto', { state: props });
            }}
          >
            Voltar
          </Button>
        </Stack>

        <div>
          <Typography variant="h1" textAlign="left">
            Informações
          </Typography>
          <Typography variant="subtitle">Confirme os dados antes de confirmar envio</Typography>
        </div>

        <Stack as={Paper} sx={{ borderRadius: 3 }}>
          <Stack
            spacing={2}
            sx={{
              p: 3,
              border: 0,
              borderBottom: 1,
              borderStyle: 'dashed',
              borderColor: 'divider',
            }}
          >
            <Typography variant="h3">Item</Typography>
            <Grid2 container direction="row" spacing={1} wrap={{ xs: 'wrap-reverse', md: 'nowrap' }}>
              {foto && (
                <Grid2 size={1}>
                  <Box
                    component="img"
                    src={foto}
                    sx={{
                      borderRadius: 1,
                      width: '100%',
                    }}
                  />
                </Grid2>
              )}
              <Grid2 size="grow">
                <Typography>{item?.id_categoria?.descricao}</Typography>
                <Typography variant="subtitle">{item?.tag}</Typography>
              </Grid2>
            </Grid2>
          </Stack>

          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="h3">Dados</Typography>
            <Stack direction="row" spacing={2}>
              <Stack spacing={2}>
                <Typography color="secondary">Nome</Typography>
                <Typography color="secondary">Telefone</Typography>
              </Stack>
              <Stack spacing={2}>
                <Typography>{usuario?.nome}</Typography>
                <Typography>{usuario?.telefone}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack as={Paper} sx={{ borderRadius: 3, p: 3 }} spacing={3}>
          <Typography variant="h3">Formulário</Typography>
          <Select
            size="small"
            fullWidth
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <MenuItem value={'Manutenção'}>Manutenção</MenuItem>
            <MenuItem value={'Reportar Problema'}>Reportar Problema</MenuItem>
            <MenuItem value={'Movimentar Item'}>Movimentar Item</MenuItem>
            <MenuItem value={'Validar recebimento'}>Validar recebimento</MenuItem>
          </Select>

          <FormControl>
            <Typography>Observação</Typography>
            <TextField
              size="small"
              value={observacao}
              onChange={(e) => {
                setObservacao(e.target.value);
              }}
              multiline
            />
          </FormControl>

          <FormControl>
            <Typography>
              Acompanhar chamado? <Typography variant="subtitle">(em desenvolvimento)</Typography>
            </Typography>
            <RadioGroup
              row
              aria-labelledby="entrega-final-check"
              name="row-radio-buttons-group"
              value={acompanharChamado}
              onChange={(e) => {
                setAcompanharChamado(e.target.value);
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Sim" />
              <FormControlLabel value={false} control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Stack>

        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            handleSave();
          }}
        >
          Enviar
        </Button>
      </Stack>

      {message ? (
        <Alert
          type={message.type}
          title={message.title}
          message={message.message}
          onClose={() => {
            if (message.function) {
              message.function(); // Executa a função definida
            }
            setMessage(null); // Reseta a mensagem
          }}
        />
      ) : (
        ''
      )}
    </>
  );
}
