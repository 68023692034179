import { useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { Button, IconButton, Stack } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function QrcodeGenerator({ value = "", width="100%" }) {
    const qrRef = useRef();

    const downloadQRCode = () => {
        const canvas = qrRef.current.querySelector("canvas");
        const url = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = url;
        link.download = "Inovacode_QRCode.png";
        link.click();
    };

    return (
        <>
            <div ref={qrRef}>
                <Stack direction="column" alignItems="center" spacing={5}>
                    <QRCodeCanvas
                        size={256}
                        value={value}
                        style={{ height: "auto", maxWidth: "100%", width: width }}
                    />
                    <Button fullWidth size="small" variant="contained" endIcon={<Icon icon="material-symbols-light:download" width={24}/>} onClick={downloadQRCode}>Download</Button>
                </Stack>
            </div>
        </>
    );
}
