import { Box, Button, Stack, styled, Typography } from '@mui/material';

import ScanOverlay from './ScanOverlay';

import { useState } from 'react';

import { QrReader } from 'react-qr-reader';

import jsQR from 'jsqr';

import { t } from 'i18next';

import { Icon } from '@iconify/react/dist/iconify.js';
import JSQRScan from './JSQRScan';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '100%',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: '100%',
});

const QrCodeScanner = ({ onChange = () => {} }) => {
  const [data, setData] = useState('No result');

  function handleChange(qrdata) {
    setData(qrdata);
    onChange(qrdata);
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, img.width, img.height);
          const imageData = ctx.getImageData(0, 0, img.width, img.height);
          const code = jsQR(imageData.data, img.width, img.height);
          if (code) {
            handleChange(code.data);
          } else {
            alert('QR Code não detectado. Tente novamente com uma imagem mais nítida.');
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Stack spacing={3}>
      <JSQRScan
        onScanQR={(result, error) => {
          console.log(result);
          if (!!result) {
            handleChange(result);
          }
        }}
      />

      <Typography variant="subtitle" textAlign="center">
        ou
      </Typography>

      <Box
        component="label"
        role="button"
        variant="standard"
        tabIndex={0}
        onDrop={handleImageUpload}
        sx={{
          p: 1.5,
          borderRadius: 3,
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          boxShadow: 2,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: 'primary.dark',
            boxShadow: 4,
          },
          '&:active': {
            backgroundColor: 'primary.light',
            boxShadow: 1,
          },
          '&:focus': {
            outline: '2px solid',
            outlineColor: 'primary.contrastText',
          },
        }}
      >
        <Icon icon="solar:camera-add-line-duotone" />
        <Typography textAlign="center" sx={{ ml: 1, fontWeight: 'bold' }}>
          Upload QRCode
        </Typography>
        <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageUpload} />
      </Box>
    </Stack>
  );
};

export default QrCodeScanner;
