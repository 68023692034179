import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineConnector,
  TimelineSeparator,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from '@mui/lab';

import { Backdrop, Box, Button, Container, Divider, Grid2, IconButton, Paper, Stack, Typography } from '@mui/material';

import dayjs from 'dayjs';

import { useEffect, useState } from 'react';

import Maps from '../../../components/Maps/Maps';

import { borderRadius } from '@mui/system';

import { Icon } from '@iconify/react/dist/iconify.js';

import { t } from 'i18next';

import 'dayjs/locale/pt-br';

import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import api from '../../../services/api';

import Loading from '../../../components/ui/Loading';

const formatKey = (key) => {
  return key.replace(/_/g, ' ');
};

export default function ItemsTimeline() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  dayjs.locale('pt-br');

  const location = useLocation();

  const { item } = location.state || {};

  const { _id } = item || {};

  const [movimentacoes, setMovimentacoes] = useState();

  const [expandFoto, setExpandFoto] = useState();

  useEffect(() => {
    getModuloTransport();
  }, []);

  async function getModuloTransport() {
    try {
      setLoading(true);
      const response = await api.get(`/rastreamentoItem/${sessionStorage.getItem('idConta')}/*/${_id}`);
      const data = response.data.map((item) => ({
        ...item,
        date: item.dt_entrega || '',
        status: item?.status || 'Status Indefinido',
        observacao: item.observacao,
        imagem: item?.imagem || null,
        nome: item?.nome,
        contato: item?.telefone,
      }));
      setMovimentacoes(data);
    } catch (error) {
      console.error('Error fetching activities:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container>
        <Stack spacing={3}>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" color="dark" onClick={() => navigate(-1)}>
              {t('actions.exit')}
            </Button>
            <Button
              variant="outlined"
              color="dark"
              onClick={() => {
                getModuloTransport();
              }}
              startIcon={<Icon icon="solar:refresh-bold-duotone" />}
            >
              {t('actions.load')}
            </Button>
          </Stack>

          <Stack as={Paper} spacing={3} sx={{ borderRadius: 5, p: 3 }}>
            {/* Display dos campos abertos (custom) */}
            {movimentacoes && movimentacoes?.length ? (
              <>
                <Timeline
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  {movimentacoes?.map((movimentacao, index) => (
                    <>
                      <TimelineItem>
                        {/* Lado OPOSTO */}
                        <TimelineOppositeContent>
                          {movimentacao?.dt_entrega && (
                            <>
                              <Typography variant="subtitle" color="dark" component="p" noWrap>
                                {dayjs(movimentacao?.dt_entrega).format('DD MMMM, YYYY')}
                              </Typography>
                              <Typography variant="subtitle" component="p">
                                {dayjs(movimentacao?.dt_entrega).format('HH:mm')}
                              </Typography>
                            </>
                          )}
                        </TimelineOppositeContent>

                        {/* Dot */}
                        <TimelineSeparator>
                          <TimelineDot
                            variant={index ? 'filled' : 'outlined'}
                            color={index ? 'secondary' : 'primary'}
                          />
                          {index === movimentacoes.length - 1 ? '' : <TimelineConnector />}
                        </TimelineSeparator>

                        {/* Lado direito - com as infos */}
                        <TimelineContent>
                          <Stack spacing={3}>
                            <div>
                              <Typography sx={{ fontWeight: 600 }}>{movimentacao?.status}</Typography>
                              <Stack direction="row" spacing={1} alignItems="center" sx={{ color: 'grey.500' }}>
                                <Icon icon="heroicons:user-16-solid" />
                                <Typography variant="subtitle">{movimentacao?.nome}</Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                sx={{ color: 'grey.500', display: movimentacao?.contato ? 'flex' : 'none' }}
                              >
                                <Icon icon="fluent:contact-card-28-regular" />
                                <Typography variant="subtitle">{movimentacao?.contato}</Typography>
                              </Stack>
                            </div>

                            <Stack direction="row" spacing={2}>
                              {/* Observacoes e Campos Abertos */}
                              <Stack
                                spacing={1}
                                sx={{ ml: 2, width: '70%', backgroundColor: 'grey.100', p: 2, borderRadius: 3 }}
                              >
                                <Typography variant="subtitle">Informações</Typography>
                                <Divider sx={{ borderStyle: 'dashed', borderColor: 'grey.400' }} />

                                {/* Campos Abertos */}
                                {movimentacao.campos &&
                                  Object.entries(movimentacao.campos).map(([key, value]) => (
                                    <Grid2 container spacing={1}>
                                      <Grid2 size={5} sx={{ textTransform: 'capitalize' }}>
                                        <Typography variant="subtitle">{formatKey(key)}:</Typography>
                                      </Grid2>
                                      <Grid2 size="grow">
                                        <Typography
                                          color={
                                            String(value) === 'true'
                                              ? 'success'
                                              : String(value) === 'false'
                                                ? 'error'
                                                : 'textPrimary'
                                          }
                                        >
                                          {String(value) === 'true'
                                            ? t('common.yes')
                                            : String(value) === 'false'
                                              ? t('common.no')
                                              : String(value)}
                                        </Typography>
                                      </Grid2>
                                    </Grid2>
                                  ))}

                                {/* Observacão */}
                                <Grid2 container spacing={1}>
                                  <Grid2 size={4}>
                                    <Typography variant="subtitle">Observação:</Typography>
                                  </Grid2>
                                  <Grid2 size="grow">{movimentacao.observacao}</Grid2>
                                </Grid2>
                              </Stack>

                              {/* Imagem cadastrada - se tiver */}
                              {movimentacao?.imagem && (
                                <Grid2 size={1}>
                                  <Box
                                    component="img"
                                    src={
                                      movimentacao?.imagem.includes('data') ??
                                      'https://api.inovacode.app.br/' + movimentacao?.imagem
                                    }
                                    sx={{
                                      borderRadius: 1,
                                      width: '150px',
                                    }}
                                    onClick={() => {
                                      setExpandFoto(movimentacao?.imagem);
                                    }}
                                  />
                                </Grid2>
                              )}
                            </Stack>
                          </Stack>
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  ))}
                </Timeline>
              </>
            ) : (
              <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ color: 'grey.400', p: 5 }}>
                <Icon icon="subway:error" width={48} />
                <Typography variant="subtitle">Sem registros ainda</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Container>

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={expandFoto}
        onClick={() => {
          setExpandFoto();
        }}
      >
        <Box
          component="img"
          src={expandFoto}
          sx={{
            borderRadius: 1,
            height: '60vh',
          }}
        />
      </Backdrop>

      <Loading show={loading} />
    </>
  );
}
