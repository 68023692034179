import { useEffect, useState } from "react";

import { t } from "i18next";

import Loading from "../../components/ui/Loading";

import api from "../../services/api";

import { Autocomplete, Box, Button, FormControl, Grid2, InputAdornment, InputLabel, Modal, Paper, Stack, styled, TextField, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js"

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ptBR } from '@mui/x-data-grid/locales';

import CustomModal from "../../components/ui/CustomModal";

import Alert from "../../components/ui/Alert";

// ----------------------------------------------------------

export default function OrderDetails(){
    const { id_registro } = useParams()

    const location = useLocation()

    const { identificador } = location.state?.pedido || {}

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState()

    // Form criar pedido
    const [showModal, setShowModal] = useState(false)

    const [tag, setTag] = useState()

    const [categoria, setCategoria] = useState()

    const [ean, setEan] = useState()

    const [quantidade, setQuantidade] = useState(0)

    const [quantidadeLida, setQuantidadeLida] = useState(0)

    const [quantidadeAuditada, setQuantidadeAuditada] = useState(0)

    const [observacao, setObservacao] = useState()

    // Listas
    const [orders, setOrders] = useState([])

    const [deleteOrders, setDeleteOrders] = useState([])

    const [categorias, setCategorias] = useState([])

    const columns = [
        { 
            field: 'id', 
            headerName: 'ID', 
            width: 90
        },
        {
          field: 'categoria',
          headerName: 'Categoria',
          width: 200 
        },
        {
          field: 'ean',
          headerName: 'EAN',
          width: 200 
        },
        {
            field: 'quantidade',
            headerName: 'Quantidade', 
            editable: true,
            width: 200
        },
        {
            field: 'quantidade_lida',
            headerName: 'Quantidade lida', 
            editable: true,
            width: 200
        },
        {
            field: 'quantidade_auditada',
            headerName: 'Quantidade auditada', 
            editable: true,
            width: 200
        },
        {
            field: 'observacao',
            headerName: 'Observacao',
            width: 300, 
            editable: true,
            width: 200 
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<Icon icon="solar:trash-bin-minimalistic-linear" width="20"/>}
                        label="Cancel"
                        className="textPrimary"
                        onClick={e=>{handleDelete(id)}}
                        color="inherit"
                    />
                ]
            }
        }
    ];

    useEffect(()=>{
        function getcategorias() {
            setLoading(true)
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/1`, {})
            .then(
                response => {
                    setCategorias(response.data.slice(0, -1))
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }

        getcategorias()
    }, [])

    useEffect(()=>{
        async function getOrders() {
            setLoading(true)
            await api.get(`/registro_pedido/*/${id_registro}`, {})
            .then(
                response => {
                    const res = response.data.map((item, index) => ({
                        ...item, 
                        categoria: item.id_categoria.descricao,
                        id: index
                    }))
                    setOrders(res)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }

        if(id_registro){
            getOrders()
        }
    }, [])

    function randomIdentificador(){
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var id = '';
        for (var i = 0; i < 6; i++) {
            var randomIndex = Math.floor(Math.random() * characters.length);
            id += characters[randomIndex];
        }
        return id;
    }

    function handleSave(){
        if(deleteOrders.length){
            console.log('deleteorders')
            uploadDelete()
        }

        if(!id_registro){
            console.log('id_registro')

            createInventory()
            return
        }

        if(orders.length){
            uploadItems(id_registro)
            return
        }

        navigate('/orders')
    }

    function handleAddItem(){
        setShowModal(false)
        const order = {
            id_conta: sessionStorage.getItem('idConta'),
            id_registro: id_registro,
            tag: tag || "",
            categoria: categoria?.descricao,
            id_categoria: categoria?._id,
            ean: categoria?.ean,
            quantidade: quantidade || 0,
            quantidade_lida: quantidadeLida || 0,
            quantidade_auditada: quantidadeAuditada || 0, 
            observacao: observacao,
            id: orders.length + 1
        }
        setOrders(prev => [...prev, order])
    }

    function handleDelete(id){ // Remove items
        const item = orders.find((row)=> row.id === id)
        setOrders(orders.filter((row) => row.id !== id)) // Remove da lista de items
        if(item?._id){
            setDeleteOrders(prev => [...prev, item?._id]) // Adiciona na lista de deletar
        }
    }

    function createInventory(){ // Cria inventário de ele nao existe
        setLoading(true)
        let option = {headers: { 'Content-Type': ['application/json']}}

        const registro = [{
            "id_conta": sessionStorage.getItem('idConta'),
            "id_usuario": sessionStorage.getItem('idUsuario'),
            "modo": "p",
            "identificador": randomIdentificador(),
            "qtd_ausente": 0,
            "qtd_encontrado": 0,
            "qtd_auditado": 0,
            "qtd_excedente": 0,
            "qtd_incorporado": 0,
            "qtd_outro_ambiente": 0,
            "status": 1
        }]
        api.post(`/registro`, registro, option)
        .then(
            response => {
                const idRegistro = response?.data[0]?._id
                uploadItems(idRegistro)
            }
        )  
    }

    function uploadItems(idRegistro){ // Sobe os items adicionados
        const newOrders = orders.map((order, index) => ({
            ...order,
            id_registro: idRegistro
        }));

        api.post(`/registro_pedido`, newOrders, {headers: { 'Content-Type': ['application/json']}})
        .then(
            response => {
                setMessage({
                    "type": "success",
                    "title": t('messages.success'),
                    "message": t('settings.clean_base.success_message')
                })
            },
            error=>{
                setMessage({
                    "type": "error",
                    "title": t('messages.errors.error'),
                    "message": t('messages.errors.500')
                })
            }
        ).finally(
            () => {
                setShowModal(false)
                setLoading(false)
            }
        )
    }

    function uploadDelete(){ // Upload/Delete dos items deletados da api
        let deleteError = false;
    
        const deleteRequests = deleteOrders.map((_id) => 
            api.delete(`/registro_pedido/${_id}`).catch(() => {
                deleteError = true;
            })
        );
    
        Promise.all(deleteRequests).finally(() => {
            setLoading(false);
            setMessage({
                type: deleteError ? "error" : "success",
                title: deleteError ? t('messages.errors.error') : t('messages.success'),
                message: deleteError ? t('messages.errors.500') : t('settings.clean_base.success_message')
            });
        });        
    }  

    return (
        <>
            <Stack spacing={3}>
                
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <Typography variant="h1">{t('common.demand')}</Typography>
                        {identificador && (
                            <Typography variant="subtitle" sx={{textTransform: "uppercase"}}><Icon icon="ri:loader-line"/> {t('common.identify')}: {identificador}</Typography>
                        )}
                    </div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="outlined" color="dark" onClick={e=> {navigate('/orders')}}>{t('actions.exit')}</Button>
                        <Button variant="contained" color="error" onClick={e=> {handleSave()}}>{t('actions.save')}</Button>
                    </Stack>
                </Stack>

                <Stack spacing={3} as={Paper} sx={{borderRadius: 3, p: 3}}>
                    
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h2">{t('common.items')}</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" color="dark" onClick={e=> {setShowModal(true)}}>{t('actions.add')}</Button>
                        </Stack>
                    </Stack>
                    <DataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={orders}
                        columns={columns}
                        editMode="row"
                        hideFooterPagination
                        disableRowSelectionOnClick={true}
                        sx={{
                            border: 0
                        }}
                        processRowUpdate={(updatedRow, originalRow) => {
                            setOrders(prevRows => {
                                const newRows = [...prevRows];
                                newRows[originalRow.id] = updatedRow;
                                return newRows;
                            });
                        }}                        
                        getRowHeight={({ id, densityFactor }) => {
                            if (id % 2 === 0) {
                            return 50 * densityFactor;
                            }
                
                            return null;
                        }}
                        slotProps={{
                            columnsManagement: {
                                toggleAllMode: 'filteredOnly', 
                            },
                        }}
                    />
                </Stack>
            </Stack>

            <Modal
                open={showModal}
                onClose={e=>{setShowModal(false)}}
            >
                <Stack 
                    as={Paper} 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width:  '60vw',
                        height: 'auto',
                        borderRadius: 3,
                    }}
                >
                    <Stack sx={{p: 3}} justifyContent="space-between">
                        <Typography variant="h1">{t('actions.create')}  {t('common.demand')}</Typography>
                    </Stack>
                    <Stack sx={{border: 1, borderColor: 'divider', p: 3}} spacing={3}>
                        <div>
                            <InputLabel>Tag</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                value={tag}
                                onChange={e=>{setTag(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>{t('common.categories')}</InputLabel>
                            <Autocomplete
                                size="small"
                                options={categorias}
                                getOptionLabel={(option) => option.descricao}
                                value={categorias.find(cat => cat._id === categoria?._id) || null}
                                onChange={(event, newValue) => {
                                    setCategoria(newValue ? newValue : null);
                                    setEan(newValue?.ean ? newValue?.ean : null);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                noOptionsText={'Não encontrado'}
                            />
                        </div>
                        <div>
                            <InputLabel>EAN</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                value={ean}
                                onChange={e=>{setEan(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>Quantidade</InputLabel>
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                min={0}
                                value={quantidade}
                                onChange={e=>{setQuantidade(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>Quantidade Lida</InputLabel>
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                min={0}
                                value={quantidadeLida}
                                onChange={e=>{setQuantidadeLida(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>Quantidade Auditada</InputLabel>
                            <TextField
                                fullWidth
                                type="number"
                                min={0}
                                size="small"
                                value={quantidadeAuditada}
                                onChange={e=>{setQuantidadeAuditada(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>Observação</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                value={observacao}
                                onChange={e=>{setObservacao(e.target.value)}}
                            />
                        </div>
                    </Stack>
                    <Stack direction="row" justifyContent="end" sx={{p: 3}} spacing={1}>
                        <Button variant="outlined" color="dark" onClick={e=>{setShowModal(false)}}>{t('actions.cancel')}</Button>
                        <Button variant="contained" color="error" onClick={e=>{handleAddItem()}}>{t('actions.save')}</Button>
                    </Stack>
                </Stack>
                
            </Modal>

            <Loading show={loading}/>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{
                        setMessage(e)
                    }}
                />
            : ''}
        </>
    )
}