import {
	Timeline as MuiTimeline,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineConnector,
	TimelineSeparator,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
} from "@mui/lab";

import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Grid2,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from "@mui/material";

import dayjs from "dayjs";

import { useEffect, useState } from "react";

import Maps from "../../../components/Maps/Maps";

import { borderRadius } from "@mui/system";

import { Icon } from "@iconify/react/dist/iconify.js";

import { t } from "i18next";

import "dayjs/locale/pt-br";

export default function Timeline({ movimentacoes = [] }) {
	dayjs.locale("pt-br");

	const [data, setData] = useState(movimentacoes);

	const [location, setLocation] = useState("");

	const [image, setImage] = useState("");

	const [filterCod, setFilterCod] = useState("");

	useEffect(() => {
		const filteredArray = filterCod
			? movimentacoes.filter((item) => item?.cod_identificacao.includes(filterCod))
			: movimentacoes;
		setData(filteredArray);
	}, [filterCod]);

	return (
		<>
			<Stack spacing={3} sx={{ p: 3 }}>
				<Stack sx={{ backgroundColor: "primary.lighter", p: 3, borderRadius: 3 }}>
					<Typography variant="h6">{t("common.filter")}</Typography>
					<Grid2 container flexWrap={{ xs: "wrap", md: "nowrap" }}>
						<Grid2 size={{ md: 4 }}>
							<FormControl>
								<FormLabel>Cód.</FormLabel>
								<TextField
									size="small"
									value={filterCod}
									onChange={(e) => {
										setFilterCod(e.target.value);
									}}
									slotProps={{
										input: {
											startAdornment: (
												<InputAdornment position="start">
													<Icon icon="mingcute:search-line" />
												</InputAdornment>
											),
										},
									}}
								/>
							</FormControl>
						</Grid2>
					</Grid2>
				</Stack>

				<Grid2 container flexWrap={{ xs: "wrap-reverse", md: "nowrap" }}>
					<Grid2 size={{ xs: "12", md: "grow" }}>
						<MuiTimeline
							sx={{
								[`& .${timelineOppositeContentClasses.root}`]: {
									flex: 0.2,
								},
							}}
						>
							{data.length &&
								data?.map((mov, i) => (
									<TimelineItem>
										<TimelineOppositeContent>
											{mov?.dt_entrega && (
												<>
													<Typography variant="subtitle" color="dark" component="p" noWrap>
														{dayjs(mov?.dt_entrega).format("DD MMMM, YYYY")}
													</Typography>
													<Typography variant="subtitle" component="p">
														{dayjs(mov?.dt_entrega).format("HH:mm")}
													</Typography>
												</>
											)}
										</TimelineOppositeContent>
										<TimelineSeparator>
											<TimelineDot
												variant={i ? "filled" : "outlined"}
												color={i ? "secondary" : "primary"}
											/>
											{i === data.length - 1 ? "" : <TimelineConnector />}
										</TimelineSeparator>
										<TimelineContent>
											<Typography sx={{ fontWeight: "bold" }}>{mov.cod_identificacao}</Typography>
											<Typography>
												{t("common.demand")}{" "}
												{mov.entrega_efetuada
													? mov?.cliente_final
														? "entregue"
														: "em transito"
													: "não entregue - Cliente ausente no momento da tentativa de entrega"}
											</Typography>
											<Stack spacing={2} sx={{ m: 2 }}>
												<Stack direction="row" spacing={0.5} sx={{ color: "grey" }}>
													<Typography>Entregue por:</Typography>
													<div>
														<Typography>{mov?.entregador}</Typography>
														<Typography>
															<Icon icon="mingcute:phone-line" />
															{mov?.telefone}
														</Typography>
													</div>
												</Stack>
												<Stack direction="row" spacing={0.5} sx={{ color: "grey" }}>
													{mov?.recebedor_nome ||
														(mov?.recebedor_documento && (
															<>
																<Typography>Recebido por:</Typography>
																<div>
																	<Typography>{mov?.recebedor_nome}</Typography>
																	<Typography>{mov?.recebedor_documento}</Typography>
																</div>
															</>
														))}
												</Stack>
												<Stack direction="row" spacing={1}>
													<Button
														variant="text"
														onClick={(e) => {
															setImage(mov?.imagem);
															setLocation("");
														}}
														startIcon={<Icon icon="mage:image" />}
													>
														{t("actions.view")} {t("common.images")}
													</Button>
													<Button
														variant="text"
														onClick={(e) => {
															setLocation({ latitude: mov?.lat, longitude: mov?.long });
															setImage("");
														}}
														startIcon={<Icon icon="mdi:map-search-outline" />}
													>
														{t("actions.view")} {t("common.locate")}
													</Button>
												</Stack>
											</Stack>
										</TimelineContent>
									</TimelineItem>
								))}
						</MuiTimeline>
					</Grid2>

					<Grid2 size={{ xs: "12", md: "grow" }}>
						<Stack
							justifyContent="center"
							alignItems="center"
							sx={{ p: 3, position: "sticky", top: "80px" }}
						>
							{image && (
								<Box
									component="img"
									alt="Imagem do produto localizado"
									src={`https://api.inovacode.app.br/${image}`}
									sx={{
										width: "50%",
										height: "auto",
										mb: 3,
										p: 5,
										border: 1,
										borderRadius: 3,
										borderColor: "grey.100",
										boxShadow: 1,
									}}
								/>
							)}

							{location && (
								<Maps latitude={location.latitude} longitude={location.longitude} height="400px" />
							)}
						</Stack>
					</Grid2>
				</Grid2>
			</Stack>
		</>
	);
}
