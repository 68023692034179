import { useEffect, useState } from "react";

import {
	Box,
	Button,
	Container,
	FormControl,
	FormLabel,
	Grid2,
	Paper,
	Stack,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";

import Loading from "../../components/ui/Loading";

import { t } from "i18next";

import { Icon } from "@iconify/react/dist/iconify.js";

import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";

import api from "../../services/api";

import axios from "axios";

import "dayjs/locale/pt-br";

import FormList from "./Form/FormList";

import FormGroupList from "./Group/GroupList";

import { TabContext, TabPanel } from "@mui/lab";

export default function Forms() {
	const navigate = useNavigate();

	const [display, setDisplay] = useState("form");

	return (
		<>
			<Container>
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between">
						<Typography variant="h1">{t("forms.form")}</Typography>
						<Stack direction="row" spacing={1}>
							<Button
								variant="outlined"
								startIcon={<Icon icon="heroicons:plus-16-solid" />}
								onClick={() => {
									navigate("/form-group/detail");
								}}
							>
								{t("forms.group")}
							</Button>
							<Button
								variant="contained"
								startIcon={<Icon icon="heroicons:plus-16-solid" />}
								onClick={() => {
									navigate("/form/detail");
								}}
							>
								{t("forms.form")}
							</Button>
						</Stack>
					</Stack>

					<TabContext value={display}>
						<Stack as={Paper} sx={{ borderRadius: 3 }} spacing={3}>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Tabs
									value={display}
									onChange={(event, newValue) => {
										setDisplay(newValue);
									}}
									sx={{ borderColor: "divider" }}
								>
									<Tab
										value="form"
										label="Formulários"
										icon={<Icon icon="hugeicons:google-doc" width={20} />}
										iconPosition="start"
									/>
									<Tab
										value="group"
										label="Grupos de Formulários"
										icon={<Icon icon="hugeicons:files-02" width={20} />}
										iconPosition="start"
									/>
								</Tabs>
							</Box>
							<TabPanel value="form" sx={{ p: 0 }}>
								<FormList />
							</TabPanel>
							<TabPanel value="group" sx={{ p: 0 }}>
								<FormGroupList />
							</TabPanel>
						</Stack>
					</TabContext>
				</Stack>
			</Container>
		</>
	);
}
