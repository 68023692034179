import { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { t } from 'i18next';
import { Icon } from '@iconify/react/dist/iconify.js';
import { grey } from '@mui/material/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/loading';
import Alert from '../../../components/ui/Alert';
import CustomModal from '../../../components/ui/CustomModal';
import QRcodeGenerator from '../../../components/QrcodeGenerator';

//----------------------------------------------------------

const optionsField = [
  {
    value: 'text',
    icon: 'material-symbols:short-text-rounded',
    label: 'Resposta Curta',
  },
  {
    value: 'paragraph',
    icon: 'mdi:text-long',
    label: 'Parágrafo',
  },
  { divider: true },
  {
    value: 'select',
    icon: 'fluent:checkbox-checked-16-regular',
    label: 'Seleção única',
  },
  {
    icon: 'fluent-mdl2:task-list',
    value: 'multiselect',
    label: 'Múltipla escolha',
  },
  {
    value: 'radio-select',
    icon: 'fluent:radio-button-24-filled',
    label: 'Botão de opção (única)',
  },
  { divider: true },
  {
    value: 'upload-image',
    icon: 'mage:image',
    label: 'Upload Imagem (desenvolvendo)',
  },
];

const rastreamentoItensCampos = [
  { label: 'Status', value: 'status', disabled: false },
  { label: 'Observação', value: 'observacao', disabled: false },
  { label: 'Acompanhar Status', value: 'acompanharChamado', disabled: false },
  { label: 'Nome', value: 'nome', disabled: false },
  { label: 'Telefone', value: 'telefone', disabled: false },
  { label: 'Imagem', value: 'imagem', disabled: false },
];

const rastreamentoInventarioCampos = [
  { label: 'Nome (Entregador)', value: 'entregador', disabled: false },
  { label: 'Telefone (Entregador)', value: 'telefone', disabled: false },
  {
    label: 'Documento (recebedor)',
    value: 'recebedor_documento',
    disabled: false,
  },
  { label: 'Nome (recebedor)', value: 'recebedor_nome', disabled: false },
  { label: 'Data de entrega', value: 'dt_entrega', disabled: false },
  { label: 'Cliente Final', value: 'cliente_final', disabled: false },
  { label: 'Imagem', value: 'imagem', disabled: false },
];

const modelActions = {
  actionType: '', // POST, GET, PUT, DELETE
  url: '', // URL da API
  preValueBody: { '': '' }, // Valor do body (opcional) - Será adicionado no envio do formulário
  function: '',
};

const modelField = {
  label: '',
  type: 'text',
  required: false,
  options: [''],
  value: '',
};

const modelPage = {
  sessionTitle: '',
  fields: [modelField],
};

export default function FormGroupCreate() {
  const navigate = useNavigate();

  const location = useLocation();

  const { group } = location?.state || {};

  const [title, setTitle] = useState(group?.title || ''); // Titulo do formulário

  const [descricao, setDescricao] = useState(group?.descricao || ''); // Descrição do formulário

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState();

  const [forms, setForms] = useState([]);

  const [QRCode, setQRCode] = useState(null);

  useEffect(() => {
    const getForms = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/forms/*/${sessionStorage.getItem('idConta')}/*`);
        const formList = response.data
          .filter((item) => item._id)
          .map((item) => ({
            ...item,
            checked: group?.formularios.find((i) => i?.id_formulario?._id === item._id || i._id === item._id)
              ? true
              : false,
          }));

        setForms(formList || []);
      } catch (error) {
        console.error('Error fetching forms:', error);
      } finally {
        setLoading(false);
      }
    };

    getForms();
  }, []);

  function handleDelete() {
    setLoading(true);
    api
      .delete(`/agrupamentoFormularios/${group?._id}`)
      .then((response) => {
        setMessage({
          type: 'success',
          message: t('messages.success_save'),
          title: t('messages.success'),
        });
        setLoading(false);
      })
      .catch(() => {
        setMessage({
          type: 'error',
          message: t('messages.errors.500'),
          title: t('messages.errors.error'),
        });
        setLoading(false);
      });
  }

  function handleSave() {
    //setLoading(true);
    const res = {
      _id: group?._id,
      id_conta: sessionStorage.getItem('idConta'),
      title,
      descricao,
      formularios: forms.filter((i) => i.checked).map((form) => ({ id_formulario: form._id })),
    };
    api
      .post(`/agrupamentoFormularios`, [res])
      .then((response) => {
        setMessage({
          type: 'success',
          message: t('messages.success_save'),
          title: t('messages.success'),
        });
        setLoading(false);
      })
      .catch(() => {
        setMessage({
          type: 'error',
          message: t('messages.errors.500'),
          title: t('messages.errors.error'),
        });
        setLoading(false);
      });
  }

  function handlePreview() {
    const upcommingForm = {
      ...group,
      title,
      descricao,
      formularios: forms.filter((i) => i.checked).map((i) => ({ id_formulario: i })),
    };
    navigate('/form-group/preview', {
      state: { groupState: upcommingForm, preview: true },
    });
  }

  const handleChange = (index, event) => {
    setForms((prevForms) =>
      prevForms.map((form, i) => (i === index ? { ...form, checked: event.target.checked } : form))
    );
  };

  function handleQRCode() {}

  return (
    <>
      <Container maxWidth="md">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton>
                <Icon icon="fluent:form-sparkle-20-regular" />
              </IconButton>
              <div>
                <Typography variant="h1">{t('forms.form_group')}</Typography>
                <Typography variant="subtitle">Cód: {group?._id}</Typography>
              </div>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Tooltip title={!group?._id ? 'Salve antes de gerar o qrcode' : ''}>
                <span>
                  <Button
                    disabled={!group?._id}
                    variant="outlined"
                    color="dark"
                    startIcon={<Icon icon="icomoon-free:qrcode" />}
                    onClick={() => {
                      setQRCode(`https://www.inovacode.app.br/form-group/${group?._id}`);
                    }}
                  >
                    QRCode
                  </Button>
                </span>
              </Tooltip>
              <Button
                variant="outlined"
                color="dark"
                startIcon={<Icon icon="fluent:eye-tracking-24-regular" />}
                onClick={() => {
                  handlePreview();
                }}
              >
                Preview
              </Button>

              <Button
                variant="outlined"
                color="dark"
                onClick={() => {
                  navigate('/form');
                }}
              >
                {t('actions.exit')}
              </Button>
              {group?._id && (
                <Button
                  variant="outlined"
                  color="dark"
                  startIcon={<Icon icon="mage:trash" />}
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  {t('actions.delete')}
                </Button>
              )}
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleSave();
                }}
              >
                {t('actions.save')}
              </Button>
            </Stack>
          </Stack>
          <Stack spacing={3} as={Paper} sx={{ borderRadius: 3, p: 3 }}>
            <Stack spacing={1}>
              <TextField
                variant="standard"
                placeholder={t('common.title')}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                variant="standard"
                placeholder={t('common.description')}
                value={descricao}
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
              />
            </Stack>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel>Formulários vinculados</FormLabel>
              <FormGroup>
                {forms?.map((form, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.checked}
                        onChange={(e) => {
                          handleChange(index, e);
                        }}
                        name={form.title}
                      />
                    }
                    label={`${form.title} (cód. ${form.cod})`}
                  />
                ))}
                {!forms.length && !loading ? (
                  <Typography variant="subtitle1" color="error">
                    Você não possui formulários cadastrados
                  </Typography>
                ) : (
                  ''
                )}
              </FormGroup>
            </FormControl>
          </Stack>
        </Stack>
      </Container>
      <Loading show={loading} />
      {message ? (
        <Alert
          type={message.type}
          title={message.title}
          message={message.message}
          onClose={(e) => {
            setMessage(null);
          }}
        />
      ) : (
        ''
      )}
      {QRCode ? (
        <CustomModal title="QRCode" dividers={false} icon="icomoon-free:qrcode">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <QRcodeGenerator value={QRCode} width={200} />
          </Stack>
        </CustomModal>
      ) : (
        ''
      )}
    </>
  );
}
