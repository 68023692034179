import React, { useEffect, useRef, useState } from 'react';

import jsQR from 'jsqr';

import { Box, Typography, Link, Paper } from '@mui/material';

const JSQRScan = ({ onScanQR }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [qrData, setQrData] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(
    '🎥 Carregando câmera... (Certifique-se de que o site tem permissão para acessar a câmera).'
  );

  useEffect(() => {
    const video = videoRef.current;
    const canvasElement = canvasRef.current;
    const canvas = canvasElement.getContext('2d');

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then((stream) => {
        video.srcObject = stream;
        video.setAttribute('playsinline', true); // Prevents fullscreen on iOS
        video.play();
        requestAnimationFrame(tick);
      })
      .catch(() => setLoadingMessage('⚠️ Unable to access the camera'));

    function tick() {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        setLoadingMessage('');
        canvasElement.hidden = false;

        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });

        if (code && code.data != qrData) {
          drawLine(canvas, code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58');
          drawLine(canvas, code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58');
          drawLine(canvas, code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58');
          drawLine(canvas, code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58');
          setQrData(code.data);
          onScanQR(code.data);
        } else {
          setQrData(null);
        }
      }
      requestAnimationFrame(tick);
    }
  }, []);

  function drawLine(canvas, begin, end, color) {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
  }

  return (
    <Box>
      {loadingMessage && (
        <Paper sx={{ textAlign: 'center', padding: 4, backgroundColor: '#eee' }}>{loadingMessage}</Paper>
      )}
      <video ref={videoRef} style={{ display: 'none' }}></video>
      <canvas ref={canvasRef} style={{ width: '100%' }} hidden></canvas>
    </Box>
  );
};

export default JSQRScan;
