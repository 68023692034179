import { useEffect, useState } from "react";

import { t } from "i18next";

import api from "../../../services/api";

import b64Resize from "../../../services/b64Resize";

import useToken from "../../../hooks/useToken";

// Styles 

import { Icon } from "@iconify/react/dist/iconify.js";

import { Box, Button, Container, Paper, Stack, Typography, MenuItem, Select, TextField, styled, FormGroup, Grid, Divider, IconButton, Autocomplete, InputAdornment, OutlinedInput, FormControlLabel, Switch, Menu, ListItemText, ListItemIcon, createFilterOptions, FormControl, FormLabel, Radio, RadioGroup, Tooltip, Grid2 } from "@mui/material";

import AlertDialog from "../../../components/AlertDialog";

import Alert from "../../../components/ui/Alert";

import Dropdown from "../../../components/ui/Dropdown";

import Loading from "../../../components/loading"
import { useLocation, useNavigate } from "react-router-dom";
import ItemsQrcode from "./ItemsQrcode";

//----------------------------------------------------------------

const filter = createFilterOptions();

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: '100%',
});

export default function ItemDetails(){
    const navigate = useNavigate()

    const { token } = useToken()

    const location = useLocation()

    const { item, propsInfoCompl, config } = location?.state || {}

    const [message, setMessage] = useState()

    const [loading, setLoading] = useState(false)
    
    const [IDItem, setIDItem] = useState(item?._id)

    const [categoria, setCategoria] = useState(item?.id_categoria?._id)

    const [marca, setMarca] = useState(item?.id_marca?._id)

    const [modelo, setModelo] = useState(item?.id_modelo)

    const [parceiro, setParceiro] = useState(item?.id_parceiro?._id)

    const [tag, setTag] = useState(item?.tag)

    const [IDExterno, setIDExterno] = useState(item?.id_externo)

    const [ativo, setAtivo] = useState(item?.ativo || 1)

    const [infoCompl1, setInfoCompl1] = useState(item?.inf_compl_1 || null)

    const [infoCompl2, setInfoCompl2] = useState(item?.inf_compl_2 || null)

    const [infoCompl3, setInfoCompl3] = useState(item?.inf_compl_3 || null)

    const [infoCompl4, setInfoCompl4] = useState(item?.inf_compl_4 || null)

    const [infoCompl5, setInfoCompl5] = useState(item?.inf_compl_5 || null)

    const [infoCompl6, setInfoCompl6] = useState(item?.inf_compl_6 || null)

    const [infoCompl7, setInfoCompl7] = useState(item?.inf_compl_7 || null)

    const [infoCompl8, setInfoCompl8] = useState(item?.inf_compl_8 || null)

    const [infoCompl9, setInfoCompl9] = useState(item?.inf_compl_9 || null)

    const [infoCompl10, setInfoCompl10] = useState(item?.inf_compl_10 || null)

    const [nivel1, setNivel1] = useState(item?.id_nivel_loc_1?._id)

    const [nivel2, setNivel2] = useState(item?.id_nivel_loc_2?._id)

    const [nivel3, setNivel3] = useState(item?.id_nivel_loc_3?._id)

    const [nivel4, setNivel4] = useState(item?.id_nivel_loc_4?._id)

    const [latitude, setLatitude] = useState(item?.latitude ?? "")

    const [longitude, setLongitude] = useState(item?.longitude ?? "")

    const [radius, setRadius] = useState(item?.raio)

    const [valor, setValor] = useState(item?.valor || 0)

    const [registro, setRegistro] = useState(item?.id_registro ?? [])

    const [quantidade, setQuantidade] = useState(item?.quantidade)

    const [observacao, setObservacao] = useState(item?.observacao)

    const [fotoPrincipal, setFotoPrincipal]  = useState(item?.foto ? "https://api.inovacode.app.br/" + item.foto : "")

    const [fotosComplementares, setFotosComplementares]  = useState(item?.foto_compl || [])

    const [fotosRemovalList, setFotosRemovalList] = useState([])

    // List //
    const [categoriaList, setCategoriaList] = useState([])

    const [marcaList, setMarcaList] = useState([])

    const [modeloList, setModeloList] = useState([])

    const [parceiroList, setParceiroList] = useState([])

    const [nivel1List, setNivel1List] = useState([])

    const [nivel2List, setNivel2List] = useState([])

    const [nivel3List, setNivel3List] = useState([])

    const [nivel4List, setNivel4List] = useState([])

    const [infoComplLabels, setInfoComplLabels] = useState(propsInfoCompl || []);

    const [infoComplList, setInfoComplList] = useState([]); // Informações complementares

    const [infoDetails, setInfoDetails] = useState([]) 

    // Functions options
    const [expandInfos, setExpandInfos] = useState(false) // Mostrar todos os campos de informação complementares

    useState(()=>{
        function categoriaGet() {
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(response => {
                setCategoriaList(response.data.filter(c => c.descricao));
            })
        }

        categoriaGet()
    }, [])

    useEffect(() => {
        async function getMarcas() {
            try {
                const response = await api.get(`/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=${categoria}&marca=&modelo=`);
                const marcaList = response.data;
                setMarcaList(marcaList);

                if (marca && !marcaList.find(item => item._id === marca)) {
                    // reset marca e modelo if it's not found in the list
                    setMarca(null)
                    setModelo(null); 
                    return
                }

                getModelos(marcaList)

            } catch (error) {
                console.error("Failed to fetch marcas:", error);
                // Handle the error, e.g., set an error message in the state
            }
        }
    
        if (categoria) {
            getMarcas();
        }
    }, [categoria]);

    useEffect(() => {
        function parceiroGet() {
            api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(response => {setParceiroList(response.data)})
        }

        parceiroGet()
    }, [])

    useEffect(() => {
        function nivel1Get() {
            api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel1List(response.data)
                }
            )
        }

        nivel1Get()
    }, [])

    useEffect(() => {
        function nivel2Get(nivel1) {
            if (nivel1 && nivel1 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel1Path = nivel1 || '*';
        
                api.get(`/nivel_loc2/${idConta}/${nivel1Path}/*/*/*/*`)
                .then(
                    response => {
                        setNivel2List(response.data)
                        if(!response?.data.find(item => item._id === nivel2)){
                            setNivel2()
                            setNivel3()
                            setNivel4()
                        }
                    }
                )
            } else {
                setNivel2List([]);
            }
        } 

        nivel2Get(nivel1)
    }, [nivel1])

    useEffect(() => {
        function nivel3Get(nivel2) {
            if (nivel2 && nivel2 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel2Path = nivel2 || '*';
        
                api.get(`/nivel_loc3/${idConta}/${nivel2Path}/*/*/*/*`)
                    .then(response => {
                        setNivel3List(response.data);
                        if(!response?.data.find(item => item._id === nivel3)){
                            setNivel3()
                            setNivel4()
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching data:", error);
                        // Optional: handle error state, show user feedback, etc.
                    });
            } else {
                setNivel3List([]);
            }
        }

        nivel3Get(nivel2)
    }, [nivel2])
    
    useEffect(() => {
        function nivel4Get(nivel3) {
            if (nivel3 && nivel3 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel3Path = nivel3 || '*';
        
                api.get(`/nivel_loc4/${idConta}/${nivel3Path}/*/*/*/*`)
                    .then(response => {
                        setNivel4List(response.data);
                    })
            } else {
                setNivel4List([]);
            }
        } 

        nivel4Get(nivel3)
    }, [nivel3])

    useEffect(() => {
        function getInfoCompl(){
            api.get(`/select_box/*/${sessionStorage.getItem('idConta')}`).then(
                response => {
                    let lista = []
                    for(let i = 0; i < 10; i++){
                        let aux = response.data.filter((item) => {return item.destino == `inf_compl_${i+1}`})
                        lista[i] = aux ? aux[0] : null 
                    }
                    setInfoComplList(lista)
                }
            )
        }

        getInfoCompl()
    }, [])

    useEffect(()=>{
        setInfoDetails([
            {
                label: infoComplLabels[0] ? infoComplLabels[0] : `${t('common.info_label')} 1`,
                modo: infoComplList[0] && infoComplList[0]?.valores?.length ? 'lista' : 'texto',
                show: true
            },
            {
                label: infoComplLabels[1] ? infoComplLabels[1] : `${t('common.info_label')} 2`,
                modo: infoComplList[1] && infoComplList[1]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[1] != '' || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[2] ? infoComplLabels[2] : `${t('common.info_label')} 3`,
                modo: infoComplList[2] && infoComplList[2]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[2] != '' || infoCompl3 ? true : false,
            },
            {
                label: infoComplLabels[3] ? infoComplLabels[3] : `${t('common.info_label')} 4`,
                modo: infoComplList[3] && infoComplList[3]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[3] != '' || infoCompl4 ? true : false,
            },
            {
                label: infoComplLabels[4] ? infoComplLabels[4] : `${t('common.info_label')} 5`,
                modo: infoComplList[4] && infoComplList[4]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[4] != '' || infoCompl5 ? true : false,
            },
            {
                label: infoComplLabels[5] ? infoComplLabels[5] : `${t('common.info_label')} 6`,
                modo: infoComplList[5] && infoComplList[5]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[5] != '' || infoCompl6 ? true : false,
            },
            {
                label: infoComplLabels[6] ? infoComplLabels[6] : `${t('common.info_label')} 7`,
                modo: infoComplList[6] && infoComplList[6]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[6] != '' || infoCompl7 ? true : false,
            },
            {
                label: infoComplLabels[7] ? infoComplLabels[7] : `${t('common.info_label')} 8`,
                modo: infoComplList[7] && infoComplList[7]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[7] != '' || infoCompl8 ? true : false,
            },
            {
                label: infoComplLabels[8] ? infoComplLabels[8] : `${t('common.info_label')} 9`,
                modo: infoComplList[8] && infoComplList[8]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[8] != '' || infoCompl9 ? true : false,
            },
            {
                label: infoComplLabels[9] ? infoComplLabels[9] : `${t('common.info_label')} 10`,
                modo: infoComplList[9] && infoComplList[9]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[9] != '' || infoCompl10 ? true : false,
            }
        ])
    }, [expandInfos, infoComplList])

    function getModelos(marcaList = []) {
        const selectedMarca = marcaList.find(item => item._id === marca);
        const modelos = selectedMarca ? selectedMarca.modelo : [];
        setModeloList(modelos);

        if (modelos && !modelos.find(item => item._id === modelo)) {
            setModelo(null); // reset modelo if it's not found in the list
        }
    }

    function handleImageInput(event, type){
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            if(type === 'principal'){
                setFotoPrincipal(imageUrl);
            }else{
                setFotosComplementares([...fotosComplementares, imageUrl])
            }
        }
    };

    function handleRemoveFoto(value, type) {
        let image
        if(type === 'complementar'){
            image = fotosComplementares[value].replace('https://api.inovacode.app.br/', '').replace('.jpeg', '')                      

            setFotosComplementares(prevFotos => {
                const newFotos = [...prevFotos];
                newFotos.splice(value, 1);
                return newFotos;
            });
        }else{
            image = value.replace('https://api.inovacode.app.br/', '').replace('.jpeg', '')
            setFotoPrincipal("")
        }
        if(!image.includes('blob')){
            setFotosRemovalList([...fotosRemovalList], image)
        }
    }

    async function removeFotos() {
        const header = {
            headers: { 'id_conta': sessionStorage.idConta, 'Authorization': `Bearer ${token}` }
        };

        const uploadImagesPromises = fotosComplementares.map(async (foto) => {
            return await api.delete(`/imgDel/${foto}`, header);
        });
    
        const uploadImagesList = await Promise.all(uploadImagesPromises);
    }

    async function uploadImages() {
        const uploadImagesPromises = fotosComplementares.map(async (foto) => {
            if (foto.includes('blob')) {
                return 'https://api.inovacode.app.br/' + await saveImage(foto);
            } else {
                return foto;
            }
        });
    
        const uploadImagesList = await Promise.all(uploadImagesPromises);

        return uploadImagesList;
    }    
    
    async function saveImage(image) {
        const option = {
            headers: { 'Content-Type': 'application/json' }
        };
    
        if (image && image.includes('blob')) {
            const b64 = await b64Resize(image, 500, 500);
            const response = await api.post(`/save_jpeg/${sessionStorage.getItem('idConta')}`, [{ foto: b64 }], option);
            return response.data[0].id_foto
        }
    }  

    // Salva alterações no item
    async function saveItem(){
        const uploadImageList = await uploadImages()
        setFotosComplementares(uploadImageList)

        const reg = [{
            ativo: ativo || 1,
            foto: await saveImage(fotoPrincipal),
            foto_compl: uploadImageList,
            id_categoria: categoria,
            id_conta: sessionStorage.getItem('idConta'),
            id_usuario: sessionStorage.getItem('idUser'),
            id_externo: IDExterno,
            id_item_vinculado: null,
            id_nivel_loc_1: nivel1,
            id_nivel_loc_2: nivel2,
            id_nivel_loc_3: nivel3,
            id_nivel_loc_4: nivel4,
            id_parceiro: parceiro,
            id_registro: item?.id_registro?._id,
            inf_compl_1: infoCompl1,
            inf_compl_2: infoCompl2,
            inf_compl_3: infoCompl3,
            inf_compl_4: infoCompl4,
            inf_compl_5: infoCompl5,
            inf_compl_6: infoCompl6,
            inf_compl_7: infoCompl7,
            inf_compl_8: infoCompl8,
            inf_compl_9: infoCompl9,
            inf_compl_10: infoCompl10,
            status_estoque: item?.status_estoque,
            latitude: latitude || "",
            longitude: longitude || "",
            raio: radius || "",
            lido_registro: 1,
            observacao: observacao,
            tag: tag,
            valor: Number(valor),
            _id: item._id || 0, 
            id_marca: marca,
            id_modelo: modelo
        }]

        const option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        api.post('/itemBO', reg, option
        ).then(
            response=> { 
                if(fotosRemovalList.length){
                    removeFotos(uploadImageList)
                }else{
                    navigate('/items')
                }
            }
        )
    }

    // Exclui item
    async function deleteItem(){
        AlertDialog.fire({
            title: 'Atenção',
            message: 'Deseja realmente excluir este Item ?',
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            onConfirm: () => {
                let option = {headers: { 'Content-Type': ['application/json'] }}
                api.delete(`/item/${IDItem}?id_conta=${sessionStorage.getItem('idConta')}?id_usuario=${sessionStorage.getItem('idUser')}`, option)
                .then(
                    response => {
                        setMessage({
                            "type": "success",
                            "message": t('messages.success_save'),
                            "title": t('messages.success')
                        })
                        navigate('/items')
                    },
                    error =>{
                        setMessage({
                            "type": "error",
                            "title": t('messages.errors.error'),
                            "message": t('messages.errors.500')
                        })
                    }
                )
            },
            onCancel: () => console.log('Formulário cancelado'),
        }); 
    }

    // Função para criar marcas
    function handleCreateMarca(){
        AlertDialog.fire({
            title: 'Nova Marca',
            message: 'Insira o nome da marca',
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            fields: [
              { name: 'marca', label: 'Marca', type: 'text' }
            ],
            onConfirm: (data) => {
                if(data?.marca){
                    const reg = [{
                        "_id": "0",
                        "id_conta": sessionStorage.getItem('idConta'),
                        "id_categoria": categoria,
                        "marca": data?.marca,
                        "modelo": []
                    }]
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.post('/infs_compl', reg, option)
                    .then(
                        response => {
                            setMarcaList(prev => [...prev, response.data[0][0]])
                            setMessage({
                                "type": "success",
                                "message": t('messages.success_save'),
                                "title": t('messages.success')
                            })
                        },
                        error =>{
                            setMessage({
                                "type": "error",
                                "title": t('messages.errors.error'),
                                "message": t('messages.errors.500')
                            })
                        }
                    )
                }
            },
            onCancel: () => console.log('Formulário cancelado'),
        });
    }

    // Função para excluir marcas
    function handleDeleteMarca(id_marca){
        AlertDialog.fire({
            title: 'Atenção',
            message: 'Deseja realmente excluir este Item ?',
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            onConfirm: () => {
                api.delete(`/infs_compl/${id_marca}`)
                .then(
                    response => {
                        setMarcaList(marcaList.filter(m =>  m._id != id_marca))
                        setMessage({
                            "type": "success",
                            "message": t('messages.success_save'),
                            "title": t('messages.success')
                        })
                    },
                    error =>{
                        setMessage({
                            "type": "error",
                            "title": t('messages.errors.error'),
                            "message": t('messages.errors.500')
                        })
                    }
                )
            },
            onCancel: () => console.log('Formulário cancelado'),
        }); 
    }

    // Função para criar modelos
    function handleCreateModelo(){
        AlertDialog.fire({
            title: 'Novo modelo',
            message: 'Insira o nome do modelo',
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            fields: [
              { name: 'input_modelo', label: 'Modelo', type: 'text' }
            ],
            onConfirm: (data) => {
                const { input_modelo } = data
                if(input_modelo && marca){
                    const reg = marcaList.find(m => m._id == marca)
                    const newModelos = modeloList?.length ? modeloList : []
                    newModelos.push({
                        "_id": "0", 
                        "descricao": input_modelo
                    })
                    reg._id = "0"
                    reg.modelo = newModelos
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.post('/infs_compl', [reg], option)
                    .then(
                        response => {
                            setModeloList(newModelos)
                            setModelo(response?.data[0]?.modelo?.find(m => m.descricao == input_modelo)?._id)
                            setMessage({
                                "type": "success",
                                "message": t('messages.success_save'),
                                "title": t('messages.success')
                            })
                        },
                        error =>{
                            setMessage({
                                "type": "error",
                                "title": t('messages.errors.error'),
                                "message": t('messages.errors.500')
                            })
                        }
                    )
                }
            },
            onCancel: () => console.log('Formulário cancelado'),
        });
    }

    // Função para excluir modelos
    function handleDeleteModelo(id_modelo){
        AlertDialog.fire({
            title: 'Atenção',
            message: 'Deseja realmente excluir este modelo ?',
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            onConfirm: () => {
                if(id_modelo && marca){
                    const reg = marcaList.find(m => m._id == marca)
                    reg.modelo = modeloList.filter(m => m._id !== id_modelo)
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.post('/infs_compl', [reg], option)
                    .then(
                        response => {
                            setModeloList(response.data[0]?.modelo)
                            setMessage({
                                "type": "success",
                                "message": t('messages.success_save'),
                                "title": t('messages.success')
                            })
                        },
                        error =>{
                            setMessage({
                                "type": "error",
                                "title": t('messages.errors.error'),
                                "message": t('messages.errors.500')
                            })
                        }
                    )
                }
            },
            onCancel: () => console.log('Formulário cancelado'),
        });
    }

    // Função para criar parceiros
    function handleCreatePartner(){
        AlertDialog.fire({
            title: 'Novo modelo',
            message: 'Insira o nome do parceiro',
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            fields: [
              { name: 'partner', label: 'Parceiro', type: 'text' }
            ],
            onConfirm: (data) => {
                if(data?.partner){
                    const reg = [{
                        ativo: 1,
                        endereco: "",
                        id_conta: sessionStorage.getItem('idConta'),
                        id_externo: "",
                        id_parceiro: "0",
                        nome: data?.partner,
                        observacao: "",
                        telefone: "",
                        uuid: ""
                    }]
                    
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }

                    api.post('/parceiro', reg, option)
                    .then(
                        response=> {
                            setParceiroList(prev => [...prev, response.data[0]])
                            setMessage({
                                "type": "success",
                                "message": t('messages.success_save'),
                                "title": t('messages.success')
                            })
                        },
                        error=> {
                            setMessage({
                                "type": "error",
                                "title": t('messages.errors.error'),
                                "message": t('messages.errors.500')
                            })
                        }
                    )
                }
            },
            onCancel: () => console.log('Formulário cancelado'),
        });
    }

    // Função para criar categorias
    function handleCreateCategory(){
        AlertDialog.fire({
            title: 'Nova Categoria',
            message: 'Insira o nome da categoria',
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            fields: [
              { name: 'input_categoria', label: 'Categoria', type: 'text' }
            ],
            onConfirm: (data) => {
                const { input_categoria } = data
                if(!input_categoria?.length){
                    setMessage({
                        "type": "error",
                        "title": t('messages.errors.error'),
                        "message": t('messages.errors.missing_field')
                    })
                    return
                }
                if(input_categoria){
                    const reg = [{
                        ativo: 1,
                        descricao: input_categoria,
                        ean: "",
                        id_categoria: "0",
                        id_conta: sessionStorage.getItem('idConta'),
                        id_externo: "",
                        inf_compl_1: "",
                        inf_compl_2: "",
                        inf_compl_3: "",
                        inf_compl_4: "",
                        inf_compl_5: "",
                        observacao: ""
                    }]
                    
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }

                    api.post('/categoria', reg, option)
                    .then(
                        response=> {
                            setCategoriaList(prev => [...prev, response.data[0]])
                            setMessage({
                                "type": "success",
                                "message": t('messages.success_save'),
                                "title": t('messages.success')
                            })
                        },
                        error=> {
                            const message = error?.response?.data?.error
                            if(message){
                                if(message.includes('Descrição já existente!')){
                                    setMessage({
                                        "type": "error",
                                        "title": t('messages.errors.error'),
                                        "message": t('messages.errors.duplicated')
                                    })
                                    return
                                }
                                console.log('Error: ' + message)
                            }
                            setMessage({
                                "type": "error",
                                "title": t('messages.errors.error'),
                                "message": t('messages.errors.500')
                            })
                        }
                    )
                }
            },
            onCancel: () => console.log('Formulário cancelado'),
        });
    }

    // Função criar niveis 
    function handleCreateNivel(index, preValue, updateNivelFunction){
        AlertDialog.fire({
            title: `Novo Nivel ${index}`,
            message: `Insira o nome do nivel ${index}`,
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            fields: [
              { name: 'input_nivel', label: 'Nivel', type: 'text' }
            ],
            onConfirm: (data) => {
                const { input_nivel } = data
                if(!input_nivel?.length){
                    setMessage({
                        "type": "error",
                        "title": t('messages.errors.error'),
                        "message": t('messages.errors.missing_field')
                    })
                    return
                }
                if(input_nivel){
                    const reg = {
                        ativo: 1,
                        descricao: input_nivel,
                        id_conta: sessionStorage.getItem('idConta'),
                        id_externo: "",
                        observacao: "",
                        tag: ""
                    }
                    if(index !== 1){
                        reg[`id_nivel_loc${index-1}`] = preValue
                    }
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }

                    api.post(`/nivel_loc${index}`, [reg], option)
                    .then(
                        response=> {
                            updateNivelFunction(prev => [...prev, response.data[0]])
                            setMessage({
                                "type": "success",
                                "message": t('messages.success_save'),
                                "title": t('messages.success')
                            })
                        },
                        error=> {
                            const message = error?.response?.data
                            if(message){
                                if(message.includes("Nivel com descrição já cadastrado")){
                                    setMessage({
                                        "type": "error",
                                        "title": t('messages.errors.error'),
                                        "message": t('messages.errors.duplicated')
                                    })
                                    return
                                }
                                console.log('Error: ' + message)
                            }
                            setMessage({
                                "type": "error",
                                "title": t('messages.errors.error'),
                                "message": t('messages.errors.500')
                            })
                        }
                    )
                }
            },
            onCancel: () => console.log('Formulário cancelado'),
        });
    }

    // Função criar campo informação complementar 
    function handleInfo(i, name){
        const index = i ?? infoDetails.findIndex(i => i.show === false);       
        AlertDialog.fire({
            title: `Nova Inf. Complementar ${index + 1}`,
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            fields: [
                {
                    name: 'info_type',
                    label: 'Tipo de Campo:',
                    type: 'radio',
                    defaultValue: 'texto', 
                    row: true,
                    options: [
                        { value: 'lista', label: 'Selecionável (lista)' },
                        { value: 'texto', label: 'Texto' }
                    ],
                },
                { name: 'info_name', formLabel: 'Nome do campo (opcional)', type: 'text', defaultValue: name || ""}
            ],
            onConfirm: (data) => {
                const {info_type, info_name} = data
                
                setLoading(true)
                const update = {
                    _id: config._id,
                    id_conta: sessionStorage.getItem('idConta')
                }
                update[`info_compl_${index + 1}`] = info_name || null
        
                let option = {
                    headers: { 'Content-Type': ['application/json'] }
                }

                api.post('/conta_config', [update], option)
                .then(
                    response => {
                        const detailsUpdate = infoDetails
                        if(info_name){
                            detailsUpdate[index].label = info_name 
                        }
                        detailsUpdate[index].show = true
                        if(info_type == 'lista'){
                            detailsUpdate[index].modo = 'lista'
                        }
                        setInfoDetails(detailsUpdate)

                        const newlabels = []
                        for(let i = 1; i <= 10; i++){
                            const newInfo = response.data[0][`info_compl_${i}`]
                            newlabels.push(newInfo) 
                        }
                        setInfoComplLabels(newlabels)
                        setLoading(false)
                    }
                ).finally(()=>{
                    setLoading(false)
                })
            },
            onCancel: () => console.log('Formulário cancelado'),
        });
    }

    // Função adicionar info complementar na lista de info complementar
    function handleInfoList(index){
        AlertDialog.fire({
            title: `Nova Inf. Complementar ${index + 1}`,
            confirmText: t('actions.continue'),
            cancelText: t('actions.cancel'),
            fields: [
                { name: 'info_name', formLabel: 'Nome do campo (opcional)', type: 'text' }
            ],
            onConfirm: (data) => {                
                const {info_name} = data
                if(info_name){
                    console.log(info_name)
                    handleSaveInfoCompl(index, info_name)
                }
            },
            onCancel: () => console.log('Formulário cancelado'),
        });
    }

    function handleSaveInfoCompl(index, newValue) {
        const accountId = sessionStorage.getItem('idConta');
        const updatedInfoComplList = [...infoComplList];
    
        let infoComplItem = updatedInfoComplList.find(item => item?.destino === `inf_compl_${index + 1}`);
    
        if (!infoComplItem) {
            // Create a new item if it doesn't exist
            infoComplItem = {
                _id: "0",
                id_conta: accountId,
                label: `Inf. Complementar ${index + 1}`,
                valores: [newValue],
                destino: `inf_compl_${index + 1}`,
            };
        } else {
            // Append the new value to the existing item
            infoComplItem = {
                ...infoComplItem,
                valores: [...infoComplItem.valores, newValue],
            };
        }
    
        // Update the list
        updatedInfoComplList[index] = infoComplItem;
    
        // Update the state and trigger API call
        setInfoComplList(updatedInfoComplList);
        setLoading(true);

        api.post(`/select_box`, [infoComplItem])
        .then(response => {
            console.log('Update successful', response);
        })
        .catch(error => {
            console.error('Error updating info', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }
    
    return (
        <Container>
            <Stack spacing={3}>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h1">{t('common.items')}</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" color="dark" onClick={() => navigate(-1)}>{t('actions.exit')}</Button>
                            {IDItem &&(
                                <Button variant="outlined" onClick={e=>{deleteItem()}}>{t('actions.delete')}</Button>
                            )}
                            <Button variant="contained" onClick={e=>{saveItem()}}>{t('actions.save')}</Button>
                        </Stack>
                    </Stack>
                </Box>

                <Stack as={Paper} spacing={3} useFlexGap sx={{border: 1, borderColor: 'divider', p: 3, borderRadius: 3}}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">{t('common.basic_info')}</Typography>
                        {IDItem &&(
                            <Stack direction="row" spacing={1}>
                                <ItemsQrcode tag={tag}/>
                                <Button variant="outlined" color="dark" onClick={()=>{ navigate('/items/map', { state: { mapItems: [{latitude: latitude, longitude: longitude}] } })  }} startIcon={<Icon icon="uiw:map"/>}>{t('common.map')}</Button> {/* Mapa*/}
                                <Button variant="outlined" color="dark" startIcon={<Icon icon="pepicons-pencil:rewind-time"/>} onClick={e => { navigate('/items/log', { state: { item } });}}>{t('menu.history')}</Button>
                            </Stack>
                        )}
                    </Stack>
                    
                    <Grid2 container spacing={3}>
                        <Grid2 item size={{xs: 12, md: 9}}>
                            <FormControl fullWidth>
                                <Typography>Tag</Typography>
                                <TextField 
                                    size="small" 
                                    value={tag} 
                                    onChange={e=>{setTag(e.target.value)}} 
                                    onBlur={e=>{setTag(e.target.value.padStart(24, '0'))}} 
                                    inputProps={{ maxLength: 24 }}
                                />
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 3}}>
                            <FormControl fullWidth>
                                <Typography>Status</Typography>
                                <Select size="small" value={ativo} onChange={e=>{setAtivo(e.target.value)}}>
                                    <MenuItem value={'0'}>{t('common.inactive')}</MenuItem>
                                    <MenuItem value={'1'}>{t('common.active')}</MenuItem>
                                    <MenuItem value={'2'}>{t('common.disposal')}</MenuItem>
                                    <MenuItem value={'3'}>{t('common.deprecated')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 6}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.categories')}</Typography>
                                <Stack direction="row" spacing={1}>
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        options={categoriaList}
                                        getOptionLabel={(option) => option.descricao}
                                        value={categoriaList.find(cat => cat._id === categoria) || null}
                                        onChange={(event, newValue) => {
                                            setCategoria(newValue ? newValue._id : null);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        noOptionsText={'Não encontrado'}
                                    />
                                    <Button variant="contained" startIcon={<Icon icon="mage:plus"/>} sx={{textWrap: 'nowrap', minWidth: '30%'}} onClick={()=>{handleCreateCategory()}}>{t('items.new_categories')}</Button>
                                </Stack>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 3}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.brand')}</Typography>
                                <Select
                                    size="small"
                                    value={marca}
                                    onChange={e => {setMarca(e.target.value); getModelos(e.target.value?.modelo)}}
                                    renderValue={(selected) => marcaList.find(m => m._id == selected)?.marca}
                                >
                                    {marcaList.map((marcas)=>(
                                        <MenuItem value={marcas?._id}>
                                            <ListItemText>{marcas?.marca}</ListItemText>
                                            <IconButton onClick={e=>{ e.stopPropagation(); handleDeleteMarca(marcas?._id) }}>
                                                <Icon icon="mynaui:trash"/>
                                            </IconButton>
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={e=>{handleCreateMarca()}}>
                                        <ListItemIcon>
                                            <Icon icon="mage:plus" />
                                        </ListItemIcon>
                                        <ListItemText>{t('actions.new')}</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 3}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.model')}</Typography>
                                <Select
                                    size="small"
                                    value={modelo}
                                    disabled={!marca}
                                    onChange={e => {setModelo(e.target.value)}}
                                    renderValue={(selected) => modeloList.find(m => m._id == selected)?.descricao}
                                >
                                    {modeloList.map((modelos)=>(
                                        <MenuItem value={modelos?._id}>
                                            <ListItemText>{modelos?.descricao}</ListItemText>
                                            <IconButton onClick={e=>{ e.stopPropagation(); handleDeleteModelo(modelos?._id) }}>
                                                <Icon icon="mynaui:trash"/>
                                            </IconButton>
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={e=>{handleCreateModelo()}}>
                                        <ListItemIcon>
                                            <Icon icon="mage:plus" />
                                        </ListItemIcon>
                                        <ListItemText>{t('actions.new')}</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>                    
                        <Grid2 item size={{xs: 12, md: 6}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.partners')}</Typography>
                                <Select size="small" value={parceiro} onChange={e=>{setParceiro(e.target.value)}}>
                                    {parceiroList.map((parceiros)=>(
                                        <MenuItem value={parceiros._id}>{parceiros.nome}</MenuItem>
                                    ))}
                                    <MenuItem onClick={e=>{handleCreatePartner()}}>
                                        <ListItemIcon>
                                            <Icon icon="mage:plus" />
                                        </ListItemIcon>
                                        <ListItemText>{t('actions.new')}</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 6}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.value')}</Typography>
                                <OutlinedInput
                                    type="number" 
                                    size="small" 
                                    value={valor} 
                                    onChange={e=>{setValor(e.target.value)}}
                                    endAdornment={
                                        <InputAdornment position="end" sx={{color: "grey.600"}}>
                                            <Icon icon="material-symbols:attach-money-rounded"/>
                                        </InputAdornment>
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                        min: 0
                                    }}
                                />
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={12}>
                            <FormControl fullWidth>
                                <Typography>{t('common.quantity')}</Typography>
                                <TextField type="number" size="small" value={quantidade} onChange={e=>{setQuantidade(e.target.value)}}/>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={12}>
                            <FormControl fullWidth>
                                <Typography>{t('common.observation')}</Typography>
                                <TextField size="small" value={observacao} onChange={e=>{setObservacao(e.target.value)}} rows={1} multiline/>
                            </FormControl>
                        </Grid2>
                    </Grid2>
                </Stack>

                <Stack as={Paper} spacing={3} useFlexGap sx={{border: 1, borderColor: 'divider', p: 3, borderRadius: 3}}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5">{t('common.info')}</Typography>
                        <FormControlLabel 
                            control={
                                <Switch
                                    checked={expandInfos}
                                    onChange={e=>{setExpandInfos(e.target.checked)}}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } 
                            label="Mostrar Todas" 
                        />
                    </Stack>
                    <Grid2 container spacing={3}>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[0]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[0]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(0, infoDetails[0]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[0]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl1} onChange={e=>{setInfoCompl1(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl1} onChange={e=>{setInfoCompl1(e.target.value)}}>
                                            {infoComplList[0]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(0)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[1]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[1]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(1, infoDetails[1]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[1]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl2} onChange={e=>{setInfoCompl2(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl2} onChange={e=>{setInfoCompl2(e.target.value)}}>
                                            {infoComplList[1]?.valores?.map((info)=>(
                                                <MenuItem value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(1)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[2]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[2]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(2, infoDetails[2]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[2]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl3} onChange={e=>{setInfoCompl3(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl3} onChange={e=>{setInfoCompl3(e.target.value)}}>
                                            {infoComplList[2]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(2)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[3]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[3]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(3, infoDetails[3]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[3]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl4} onChange={e=>{setInfoCompl4(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl4} onChange={e=>{setInfoCompl4(e.target.value)}}>
                                            {infoComplList[3]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(3)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[4]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[4]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(4, infoDetails[4]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[4]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl5} onChange={e=>{setInfoCompl5(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl5} onChange={e=>{setInfoCompl5(e.target.value)}}>
                                            {infoComplList[4]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(4)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[5]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[5]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(5, infoDetails[5]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[5]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl6} onChange={e=>{setInfoCompl6(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl6} onChange={e=>{setInfoCompl6(e.target.value)}}>
                                            {infoComplList[5]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(5)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[6]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[6]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(6, infoDetails[6]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[6]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl7} onChange={e=>{setInfoCompl7(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl7} onChange={e=>{setInfoCompl7(e.target.value)}}>
                                            {infoComplList[6]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(6)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[7]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[7]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(7, infoDetails[7]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[7]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl8} onChange={e=>{setInfoCompl8(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl8} onChange={e=>{setInfoCompl8(e.target.value)}}>
                                            {infoComplList[7]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(7)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[8]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[8]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(8, infoDetails[8]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[8]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl9} onChange={e=>{setInfoCompl9(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl9} onChange={e=>{setInfoCompl9(e.target.value)}}>
                                            {infoComplList[8]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(8)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 4}} sx={{display: infoDetails[9]?.show ? "" : "none"}}>
                            <FormControl fullWidth>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{ infoDetails[9]?.label }</Typography>
                                    <Tooltip title={t('actions.edit')} placement="bottom">
                                        <IconButton onClick={()=>{handleInfo(9, infoDetails[9]?.label)}}>
                                            <Icon width={16} icon="cuida:edit-outline"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                { infoDetails[9]?.modo == 'texto' ? 
                                    <TextField size="small" value={infoCompl10} onChange={e=>{setInfoCompl10(e.target.value)}}/>
                                :
                                    <>
                                        <Select size="small" value={infoCompl10} onChange={e=>{setInfoCompl10(e.target.value)}}>
                                            {infoComplList[9]?.valores?.map((info, index) => (
                                                <MenuItem key={index} value={info}>{info}</MenuItem>
                                            ))}
                                            <MenuItem onClick={e=>{handleInfoList(9)}}>
                                                <ListItemIcon>
                                                    <Icon icon="mage:plus" />
                                                </ListItemIcon>
                                                <ListItemText>{t('actions.new')}</ListItemText>
                                            </MenuItem>
                                        </Select>
                                    </>
                                }
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={12}>
                            <Button variant="contained" startIcon={<Icon icon="mage:plus"/>} disabled={!infoDetails.find(i => i.show === false)} onClick={()=>{handleInfo()}}>{t('common.info_label')}</Button>
                        </Grid2>
                    </Grid2>    
                </Stack>

                <Stack as={Paper} spacing={3} useFlexGap sx={{border: 1, borderColor: 'divider', p: 3, borderRadius: 3}}>
                    <Typography variant="h5">{t('common.locate')}</Typography>
                    <Grid2 container spacing={3}>
                        <Grid2 item size={{xs: 12, md: "grow"}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.level')} 1</Typography>
                                <Select size="small" value={nivel1} onChange={e=>{setNivel1(e.target.value)}}>
                                    {nivel1List.map((nivel)=>(
                                        <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                    ))}
                                    <MenuItem onClick={e=>{handleCreateNivel(1, '', setNivel1List)}}>
                                        <ListItemIcon>
                                            <Icon icon="mage:plus" />
                                        </ListItemIcon>
                                        <ListItemText>{t('actions.new')}</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: "grow"}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.level')} 2</Typography>
                                <Select size="small" value={nivel2} onChange={e=>{setNivel2(e.target.value)}}>
                                    {nivel2List.map((nivel)=>(
                                        <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                    ))}
                                    <MenuItem onClick={e=>{handleCreateNivel(2, nivel1, setNivel2List)}}>
                                        <ListItemIcon>
                                            <Icon icon="mage:plus" />
                                        </ListItemIcon>
                                        <ListItemText>{t('actions.new')}</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: "grow"}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.level')} 3</Typography>
                                <Select size="small" value={nivel3} onChange={e=>{setNivel3(e.target.value)}}>
                                    {nivel3List.map((nivel)=>(
                                        <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                    ))}
                                    <MenuItem onClick={e=>{handleCreateNivel(3, nivel2, setNivel3List)}}>
                                        <ListItemIcon>
                                            <Icon icon="mage:plus" />
                                        </ListItemIcon>
                                        <ListItemText>{t('actions.new')}</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: "grow"}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.level')} 4</Typography>
                                <Select size="small" value={nivel4} onChange={e=>{setNivel4(e.target.value)}}>
                                    {nivel4List.map((nivel)=>(
                                        <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                    ))}
                                    <MenuItem onClick={()=>{handleCreateNivel(4, nivel3, setNivel4List)}}>
                                        <ListItemIcon>
                                            <Icon icon="mage:plus" />
                                        </ListItemIcon>
                                        <ListItemText>{t('actions.new')}</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                    </Grid2>

                    
                    <Grid2 container spacing={3}>
                        <Grid2 item size={{xs: 12, md: 3}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.latitude')}</Typography>
                                <TextField size="small" value={latitude} onChange={e=>{setLatitude(e.target.value)}}/>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 3}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.longitude')}</Typography>
                                <TextField size="small" value={longitude} onChange={e=>{setLongitude(e.target.value)}}/>
                            </FormControl>
                        </Grid2>
                        <Grid2 item size={{xs: 12, md: 3}}>
                            <FormControl fullWidth>
                                <Typography>{t('common.radius')}</Typography>
                                <TextField size="small" value={radius} onChange={e=>{setRadius(e.target.value)}}/>
                            </FormControl>
                        </Grid2>
                    </Grid2>

                    <Typography variant="h5">{t('common.images')}</Typography>
                    <Stack direction="row" alignItems="center" spacing={5}>
                        <Box
                            component="label"
                            role={undefined}
                            variant="standard"
                            tabIndex={-1}
                            sx={{
                                width: '200px',
                                height: '230px',
                                border: 1,
                                borderColor: "grey.200",
                                boxShadow: 1,
                                background: fotoPrincipal ? `url(${fotoPrincipal})` : "",
                                backgroundColor: fotoPrincipal ? "" : "grey.300",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                color: fotoPrincipal ? 'transparent' : "grey.600",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 3,
                                position: 'relative'
                            }}
                        >
                            {!fotoPrincipal && (
                                <>
                                    <Icon icon="fluent:camera-add-24-regular" width="32" />
                                    <Typography textAlign="center" textTransform="capitalize">Upload imagem</Typography>
                                </>
                            )}
                            {fotoPrincipal && (
                                <>
                                    <IconButton onClick={e=>{handleRemoveFoto(fotoPrincipal, 'principal')}} sx={{position: "absolute", top: -15, right: -15, backgroundColor: 'white', boxShadow: 2, color: "error.main", zIndex: 2}}>
                                        <Icon icon="mingcute:close-fill" width="16"/>
                                    </IconButton>
                                </>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={e=>{handleImageInput(e, 'principal')}}
                            />
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem sx={{borderColor: "grey.600"}}/>
                        {fotosComplementares.map((foto, index)=>(
                            <>
                                <Box
                                    component="label"
                                    role={undefined}
                                    variant="standard"
                                    tabIndex={-1}
                                    sx={{
                                        width: '200px',
                                        height: '230px',
                                        border: 1,
                                        borderColor: "grey.200",
                                        boxShadow: 1,
                                        background: foto ? `url(${foto})` : "",
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: 3,
                                        position: 'relative'
                                    }}
                                >
                                    {foto && (
                                        <>
                                            <IconButton onClick={e=>{handleRemoveFoto(index, 'complementar')}} sx={{position: "absolute", top: -15, right: -15, backgroundColor: 'white', boxShadow: 2, color: "error.main", zIndex: 2}}>
                                                <Icon icon="mingcute:close-fill" width="16"/>
                                            </IconButton>
                                        </>
                                    )}
                                </Box>
                            </>
                        ))}
                        <Stack alignItems="center" justifyContent="center" spacing={3}>
                            <Box
                                component="label"
                                role={undefined}
                                variant="standard"
                                tabIndex={-1}
                                sx={{
                                    p: 1,
                                    backgroundColor: "white",
                                    boxShadow: 3,
                                    borderRadius: 100,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: 'relative'
                                }}
                            >
                                <Icon icon="mage:plus"/>
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={e=>{handleImageInput(e, 'complementar')}}
                                />
                            </Box>
                            <Typography variant="subtitle" textAlign="center">{t('items.adicional_image')}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

            <Loading show={loading}/>

            { message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{setMessage(null)}}
                />
            : ''}

        </Container>
    )
}