import React, { useState, useEffect } from 'react';

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Stack,
  Container,
  IconButton,
  Snackbar,
  AlertTitle,
  Alert,
} from '@mui/material';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { styled } from '@mui/system';

import { Icon } from '@iconify/react/dist/iconify.js';

import { t } from 'i18next';

import api from '../../../services/api';

import Loading from '../../../components/loading';

import { default as CustomAlert } from '../../../components/ui/Alert';

import dayjs from 'dayjs';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const DynamicForm = () => {
  const navigate = useNavigate();

  const { cod, epc } = useParams(); // Parametros

  const location = useLocation();

  const { formState, preview } = location?.state || {};

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState();

  const [form, setForm] = useState(formState || null); // Armazena o esquema do formulário

  const [formData, setFormData] = useState({}); // Armazena os dados preenchidos pelo usuário

  const [actions, setActions] = useState(form?.actions || [{}]);

  const [currentSessionIndex, setCurrentSessionIndex] = useState(0); // Índice da sessão atual

  const [errors, setErrors] = useState({}); // Armazena os erros de

  const [locate, setLocate] = useState(); // Localização (lat e longitude)

  const [alertMessage, setAlertMessage] = useState(); // Mensagem que aparece no Snackbar

  const [upcommingReg, setUpcommingReg] = useState();

  const [upcommingImage, setUpcommingImage] = useState();

  // # Verifica de possui EPC nos parametros
  useEffect(() => {
    if (!epc && !preview) {
      setAlertMessage({
        severity: 'error',
        title: 'EPC não informado',
        text: 'Nenhum EPC passado como parametro',
      });
    }
  }, []);

  // # Requisita o Formulário pelo código
  useEffect(() => {
    function getForm() {
      // Chamada à API para buscar o formulário
      setLoading(true);
      api
        .get(`/forms/*/*/${cod}`)
        .then((response) => {
          const result = response.data.filter((item) => item._id);
          if (result.length) {
            setForm(result[0]);
            setActions(result[0]?.actions);
          } else {
            setMessage({
              type: 'error',
              message: 'Formulário não encontrado',
              title: t('messages.errors.error'),
            });
          }
        })
        .catch((error) => {
          setMessage({
            type: 'error',
            message: 'Formulário não encontrado',
            title: t('messages.errors.error'),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (cod && !preview) {
      getForm();
    }
  }, []);

  // # Busca localização do celular
  useEffect(() => {
    function getLocate() {
      // Verifica a permissão de localização
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocate({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (error) => {
          // Erro ao obter a localização
          console.error(error);
        }
      );
    }

    if (form?.save_locate) {
      getLocate();
    }
  }, [form]);

  // # Requisita item ou inventário
  useEffect(() => {
    function getInv() {
      api
        .get(`/registroPage/${form?.id_conta}/*/inventario/*/*/*/*/*/*/0?tags=${epc}`)
        .then((response) => {
          const inventario = response.data.find((item) => item?._id);
          setUpcommingReg(inventario);
        })
        .catch(() => {
          setMessage({
            type: 'error',
            message: t('messages.errors.500'),
            title: t('messages.errors.error'),
          });
        });
    }
    function getItem() {
      api
        .get(`/itensPage/${form?.id_conta}/*/*/${epc}/*/*/*/*/*/*/*/*/*/*/*/0?limit=10`)
        .then((response) => {
          const item = response.data.find((item) => item?._id);
          setUpcommingReg(item);
        })
        .catch(() => {
          setMessage({
            type: 'error',
            message: t('messages.errors.500'),
            title: t('messages.errors.error'),
          });
        });
    }

    if (actions && epc) {
      const functionList = actions.map((item, index) => item.function);

      if (functionList.includes('rastreamentoInventario')) {
        getInv();
      }

      if (functionList.includes('rastreamentoItem')) {
        getItem();
      }
    }
  }, [actions]);

  // Retorna erro ou carregando se não possui o formulário ainda
  if (!form) {
    return (
      <>
        <Loading show={loading} />
        {message ? (
          <CustomAlert
            type={message.type}
            title={message.title}
            message={message.message}
            onClose={(e) => {
              setMessage(null);
            }}
          />
        ) : (
          ''
        )}
      </>
    );
  }

  // # Upload de fotos
  function handleUpload(e, label) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_SIZE = 1000; // Defina o tamanho máximo de largura ou altura que você deseja

        let width = img.width;
        let height = img.height;

        // Verifica se é necessário redimensionar
        if (width > height && width > MAX_SIZE) {
          height *= MAX_SIZE / width;
          width = MAX_SIZE;
        } else if (height > MAX_SIZE) {
          width *= MAX_SIZE / height;
          height = MAX_SIZE;
        }

        // Redimensiona o canvas
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // Converte o canvas para uma imagem comprimida
        canvas.toBlob(
          (blob) => {
            if (blob.size > 1000000) {
              // Compressão adicional, ajusta a qualidade para ficar abaixo de 1MB
              canvas.toBlob(
                (compressedBlob) => {
                  const compressedReader = new FileReader();
                  compressedReader.onloadend = () => {
                    setFormData({
                      ...formData,
                      [label]: compressedReader.result || null,
                    });
                  };
                  compressedReader.readAsDataURL(compressedBlob);
                },
                'image/jpeg',
                0.8
              ); // 0.8 é a qualidade de compressão (de 0 a 1)
            } else {
              const newReader = new FileReader();
              newReader.onloadend = () => {
                setFormData({
                  ...formData,
                  [label]: newReader.result || null,
                });
              };
              newReader.readAsDataURL(blob);
            }
          },
          'image/jpeg',
          0.9
        ); // 0.9 é a qualidade inicial
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  // Função para lidar com a alteração dos campos
  const handleChange = (e, label) => {
    setFormData({
      ...formData,
      [label]: e?.target?.value || null,
    });
    setErrors({
      ...errors,
      [label]: '',
    });
  };

  // Função para validar os campos obrigatórios
  const validateFields = () => {
    const sessionErrors = {};
    currentSession.fields.forEach((field) => {
      if (field.required && !formData[field.value]) {
        sessionErrors[field.value] = 'Este campo é obrigatório';
      }
    });
    setErrors(sessionErrors);
    return Object.keys(sessionErrors).length === 0;
  };

  // # Armazena as sessões ou páginas do formulario
  const sessions = form.sessions;

  // # Armazena a sessão atual
  const currentSession = sessions[currentSessionIndex];

  // Funções de navegação
  // Função prox. sessão
  const nextSession = () => {
    if (validateFields()) {
      if (currentSessionIndex < sessions.length - 1) {
        setCurrentSessionIndex(currentSessionIndex + 1);
      }
    }
  };

  // Função voltar sessão
  const prevSession = () => {
    if (currentSessionIndex > 0) {
      setCurrentSessionIndex(currentSessionIndex - 1);
    }
  };

  // Função para enviar o formulário
  const handleSubmit = () => {
    if (validateFields()) {
      const functionList = actions.map((item, index) => item.function);

      let reg = {
        id_conta: form?.id_conta,
        ...formData,
        ...locate,
      };

      if (form?.save_locate) {
        if (locate) {
          reg = { ...reg, locate };
        } else {
          setMessage({
            type: 'error',
            message: 'O site não possui permissão para acessar a localização.',
            title: t('messages.errors.error'),
          });
          return;
        }
      }

      if (functionList.includes('rastreamentoInventario')) {
        rastreamentoInventario(reg);
      }

      if (functionList.includes('rastreamentoItem')) {
        rastreamentoItem(reg);
      }
    }
  };

  // # Função para salvar rastreio de inventário
  function rastreamentoInventario(reg) {
    setLoading(true);
    if (reg?.imagem && reg?.imagem?.includes('data')) {
      uploadFoto(reg, 'inv');
      return;
    }

    const upcommingRastreamento = {
      entregador: '',
      cod_identificacao: '',
      dt_entrega: dayjs().format(),
      entrega_efetuada: true,
      telefone: '',
      imagem: upcommingImage || '',
      ...reg,
    };

    const upcommingInventory = {
      ...upcommingReg,
      rastreamento: [...(upcommingReg?.rastreamento ?? []), upcommingRastreamento],
    };

    delete upcommingInventory._id;

    if (preview) {
      return;
    }

    const option = {
      headers: { 'Content-Type': ['application/json'] },
    };

    api
      .post(`/registro?tr=icd3vatgr@23!`, [upcommingInventory], option)
      .then((response) => {
        setLoading(false);
        setMessage({
          type: 'success',
          message: t('messages.success_save'),
          title: t('messages.success'),
        });
      })
      .catch(() => {
        {
          setLoading(false);
          setMessage({
            type: 'error',
            message: t('messages.errors.500'),
            title: t('messages.errors.error'),
          });
        }
      });
  }

  // # Função para salvar rastreio de itens
  function rastreamentoItem(reg) {
    setLoading(true);

    console.log(reg?.imagem);
    if (reg?.imagem && reg?.imagem?.includes('data')) {
      uploadFoto(reg, 'item');
      return;
    }

    const option = {
      headers: { 'Content-Type': ['application/json'] },
    };

    const upcommingItem = {
      id_item: upcommingReg?._id,
      id_conta: '',
      status: '',
      observacao: '',
      acompanharChamado: '',
      imagem: upcommingImage || '',
      nome: '',
      telefone: '',
      ...reg,
      ...upcommingReg,
    };

    delete upcommingItem._id;

    if (preview) {
      console.log(upcommingItem);
      return;
    }

    api
      .post(`/rastreamentoItem`, [upcommingItem], option)
      .then((response) => {
        setLoading(false);
        setMessage({
          type: 'success',
          message: t('messages.success_save'),
          title: t('messages.success'),
        });
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          type: 'error',
          message: t('messages.errors.500'),
          title: t('messages.errors.error'),
        });
      });
  }

  // # Função para salvar imagem no bucket aws
  async function uploadFoto(reg, type) {
    const { id_conta, imagem } = reg;
    setLoading(true);
    if (upcommingImage) {
      return;
    }

    if (preview) {
      reg.imagem = 'url.teste';
      if (type == 'item') {
        rastreamentoItem(reg);
      } else {
        rastreamentoInventario(reg);
      }
      return;
    }

    const option = {
      headers: { 'Content-Type': ['application/json'] },
    };

    //Upload Imagem
    await api.post(`/save_jpeg/${id_conta}`, [{ foto: imagem }], option).then(
      (response) => {
        const id_foto = response?.data.find((i) => i.id_foto);
        if (!id_foto) {
          setMessage({
            type: 'error',
            message: t('messages.errors.500'),
            title: t('messages.errors.error'),
          });
        }
        reg.imagem = id_foto.id_foto;
        if (type == 'item') {
          rastreamentoItem(reg);
        } else {
          rastreamentoInventario(reg);
        }
      },
      (error) => {
        setLoading(false);
        setMessage({
          type: 'error',
          message: t('messages.errors.500'),
          title: t('messages.errors.error'),
        });
      }
    );
  }

  // Função para renderizar os campos dinamicamente
  const renderField = (field) => {
    switch (field.type) {
      case 'text':
        return (
          <TextField
            key={field.shortId}
            label={field.label}
            variant="outlined"
            fullWidth
            required={field.required}
            value={formData[field.value] || ''}
            onChange={(e) => handleChange(e, field.value)}
            margin="normal"
            error={!!errors[field.value]}
            helperText={errors[field.value]}
          />
        );

      case 'select':
        return (
          <TextField
            key={field.shortId}
            label={field.label}
            variant="outlined"
            select
            fullWidth
            required={field.required}
            value={formData[field.value] || ''}
            onChange={(e) => handleChange(e, field.value)}
            margin="normal"
            error={!!errors[field.value]}
            helperText={errors[field.value]}
          >
            {field.options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );

      case 'multiselect':
        return (
          <FormControl key={field.shortId} fullWidth margin="normal">
            <InputLabel>{field.label}</InputLabel>
            <Select multiple value={formData[field.value] || []} onChange={(e) => handleChange(e, field.value)}>
              {field.options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errors[field.value] && <Typography color="error">{errors[field.value]}</Typography>}
          </FormControl>
        );

      case 'radio-select':
        return (
          <Box key={field.shortId}>
            <InputLabel>{field.label}</InputLabel>
            {field.options.map((option, index) => (
              <FormControl key={index}>
                <RadioGroup
                  name={field.label}
                  value={formData[field.value] || ''}
                  onChange={(e) => handleChange(e, field.value)}
                >
                  <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
                </RadioGroup>
                {errors[field.value] && <Typography color="error">{errors[field.value]}</Typography>}
              </FormControl>
            ))}
          </Box>
        );

      case 'paragraph':
        return (
          <TextField
            key={field.shortId}
            label={field.label}
            variant="outlined"
            fullWidth
            multiline
            rows={1}
            required={field.required}
            value={formData[field.value] || ''}
            onChange={(e) => handleChange(e, field.value)}
            margin="normal"
            error={!!errors[field.label]}
            helperText={errors[field.label]}
          />
        );

      case 'upload-image':
        return (
          <>
            {formData[field.value] ? (
              <Stack direction="row" justifyContent="center" alignItems="flex-start" sx={{ padding: 5 }}>
                <Box
                  component="img"
                  src={formData[field.value]}
                  sx={{
                    marginTop: 2,
                    borderRadius: 3,
                    backgroundColor: 'grey.400',
                    width: '250px',
                    height: 'auto',
                  }}
                ></Box>
                <IconButton
                  onClick={(e) => {
                    handleChange(null, field.value);
                  }}
                >
                  <Icon icon="iwwa:delete" width={14} />
                </IconButton>
              </Stack>
            ) : (
              <Box
                component={'label'}
                role={undefined}
                variant={'standard'}
                tabIndex={-1}
                onDrop={(e) => {
                  handleUpload(e, field.value);
                }}
                sx={{
                  p: 2,
                  py: 5,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 1,
                  borderStyle: 'dashed',
                  borderColor: 'grey.400',
                  borderRadius: 3,
                  backgroundColor: 'grey.200',
                }}
              >
                <Box sx={{ color: 'primary.main', pb: 3 }}>
                  <Icon icon="fluent:scan-camera-16-regular" width="100" />
                </Box>
                <Typography variant="subtitle" align="center" paragraph={false}>
                  Adicione fotos do produto
                </Typography>
                <Typography variant="subtitle" align="center" paragraph={false}>
                  em formato .png, .jpeg, .svg
                </Typography>
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleUpload(e, field.value);
                  }}
                />
              </Box>
            )}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ p: 3, maxWidth: '600px', margin: 'auto' }}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <div>
              <Typography variant="h4">{form.title}</Typography>
              <Typography variant="subtitle">{form.description}</Typography>
            </div>

            {preview && (
              <Button
                variant="outlined"
                color="dark"
                onClick={() => {
                  navigate('/form/detail', { state: { form: form } });
                }}
              >
                {t('actions.exit')}
              </Button>
            )}
          </Stack>

          {/* Stepper para exibir o progresso das sessões */}
          <Stepper activeStep={currentSessionIndex} alternativeLabel>
            {sessions
              .filter((session) => session.sessionTitle) // Filtra apenas as sessões com título
              .map((session) => (
                <Step key={session.sessionTitle}>
                  <StepLabel>{session.sessionTitle}</StepLabel>
                </Step>
              ))}
          </Stepper>

          {/* Renderização dos campos da sessão atual */}
          <Stack as={Paper} sx={{ p: 3, borderRadius: 3 }}>
            <Typography variant="h6">{currentSession.sessionTitle}</Typography>
            {currentSession.fields.map((field) => renderField(field))}

            <div style={{ marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={prevSession}
                disabled={currentSessionIndex === 0}
                style={{ marginRight: '10px' }}
              >
                Anterior
              </Button>

              {currentSessionIndex < sessions.length - 1 ? (
                <Button variant="outlined" color="primary" onClick={nextSession}>
                  Próximo
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Enviar
                </Button>
              )}
            </div>
          </Stack>
        </Stack>
      </Container>

      {alertMessage ? (
        <Snackbar open={true} autoHideDuration={200} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert
            severity={alertMessage?.severity}
            variant="filled"
            sx={{ width: '500px' }}
            onClose={() => {
              setAlertMessage();
            }}
          >
            <AlertTitle>{alertMessage?.title}</AlertTitle>
            {alertMessage?.text}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
    </>
  );
};

export default DynamicForm;
