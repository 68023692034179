import { Icon } from '@iconify/react/dist/iconify.js';
import { Button, ButtonGroup, Container, Stack, Typography } from '@mui/material';

import { t } from 'i18next';

export default function Registers() {
  return (
    <>
      <Container>
        <Stack spacing={3}>
          <Typography variant="h1">{t('common.registers')}</Typography>
          <Stack direction="row" justifyContent="flex-end">
            <ButtonGroup variant="outlined" sx={{ border: 1, borderColor: 'divider' }}>
              <Button variant="text">
                <Icon icon="solar:list-bold" />
              </Button>
              <Button>
                <Icon icon="mdi:dots-grid" />
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
