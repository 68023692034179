import { AiFillEdit } from 'react-icons/ai';
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

import Swal from 'sweetalert2';

import { FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { FiFilter, FiArrowLeft, FiSave, FiUpload, FiEdit, FiTrash } from 'react-icons/fi';
import { parse } from 'papaparse';

import api from '../../services/api';
import Menu from '../../components/Menu';
import ExportCSV from '../../components/exportCSV';
import ExportPDF from '../../components/exportPDF';

import './style.css';
import { Container, Row, Col, Button, Table, Form, Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { use } from 'i18next';
import { BiPlus } from 'react-icons/bi';
import { Pagination } from '@mui/material';

function Categories() {
  const { t } = useTranslation();

  const [items, setItems] = useState([]);
  const [itemsLength, setItemsLength] = useState(0);
  const [ativo, setAtivo] = useState('1');
  const [descricao, setDescricao] = useState('*');
  const [ean, setEan] = useState('*');
  const [idCategoria, setIdCategoria] = useState('0');
  const [idExterno, setIdExterno] = useState('');
  const [descricaoNew, setDescricaoNew] = useState('');
  const [eanNew, setEanNew] = useState('');
  const [ativoNew, setAtivoNew] = useState('1');
  const [infCompl1New, setInfCompl1New] = useState('');
  const [infCompl2New, setInfCompl2New] = useState('');
  const [infCompl3New, setInfCompl3New] = useState('');
  const [infCompl4New, setInfCompl4New] = useState('');
  const [infCompl5New, setInfCompl5New] = useState('');
  const [infCompl6New, setInfCompl6New] = useState('');
  const [up1New, setUp1New] = useState('');
  const [up2New, setUp2New] = useState('');
  const [up3New, setUp3New] = useState('');
  const [up4New, setUp4New] = useState('');
  const [up5New, setUp5New] = useState('');
  const [observacaoNew, setObservacaoNew] = useState('');
  const [editing, setEditing] = useState(0);
  const [change, setChange] = useState(0);
  const [importList, setImportList] = useState([]);
  const [itemsLoaded, setItemsLoaded] = useState(true);
  const [choice, setChoice] = useState(false);
  const [eanOrdered, setEanOrdered] = useState();
  const [infoOrdered, setInfoOrdered] = useState();
  const [categorieOrdered, setCategorieOrdered] = useState('asc');
  const [uploaded, setUploaded] = useState([]);
  const [importLoading, setImportLoading] = useState(false);
  const [uppingImport, setUppingImport] = useState(false);
  const [importErro, setImportErro] = useState(0);
  const [importRepetido, setImportRepetido] = useState(0);
  const [importRegistrado, setImportRegistrado] = useState(0);
  const [importPage, setImportPage] = useState(0);
  const [inBaseProps, setInBaseProps] = useState({});
  const [brandList, setBrandList] = useState([]);
  const [brandSelected, setBrandSelected] = useState();
  const [brandNew, setBrandNew] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const handleChangePage = (event, value) => {
    setPageNumber(value);
  };
  const fileTypes = ['text/csv', 'application/vnd.ms-excel', 'text/plain'];
  const headersCSV = [
    'COD_EXTERNO',
    'ATIVO',
    'DESCRIÇÃO',
    'EAN',
    'INF_COMPL_1',
    'INF_COMPL_2',
    'INF_COMPL_3',
    'INF_COMPL_4',
    'INF_COMPL_5',
    'UP_1',
    'UP_2',
    'UP_3',
    'UP_4',
    'UP_5',
    'OBSERVAÇÃO',
  ];

  useEffect(() => {
    categorias();
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    ordenateListbyEan();
  }, [eanOrdered]);

  useEffect(() => {
    ordenateListbyInfo();
  }, [infoOrdered]);

  useEffect(() => {
    ordenateListbyCategorie();
  }, [categorieOrdered]);

  useEffect(() => {
    if (change == 1) {
      verififyBase();
    }
  }, [change]);

  useEffect(() => {
    if (change == 2) {
      getInfoBrand();
    }
  }, [idCategoria]);

  function categorias() {
    setItemsLoaded(false);
    api
      .get(
        `/categoria/${sessionStorage.getItem('idConta')}/*/${descricao.replace('/', '%2F')}/${ean}/${ativo}?page=${pageNumber - 1}&limit=${pageLimit}`,
        {}
      )
      .then((response) => {
        setItems(response.data.slice(0, -1));
        setItemsLength(response.data[response.data.length - 1]);
      })
      .finally(() => {
        setItemsLoaded(true);
      });
  }

  function ordenateListbyEan(sortby) {
    if (sortby == 'asc') {
      const sortedItems = items.sort((a, b) => {
        if (a.ean === '' && b.ean !== '') {
          return 1;
        }
        if (a.ean !== '' && b.ean === '') {
          return -1;
        }
        if (a.ean === '' && b.ean === '') {
          return 0;
        }
        return a.ean.localeCompare(b.ean, undefined, { numeric: true });
      });
      setItems(sortedItems);
      setEanOrdered('asc');
      setCategorieOrdered('');
      setInfoOrdered('');
    } else if (sortby == 'dsc') {
      const sortedItems = items.sort((a, b) => {
        if (a.ean === '' && b.ean !== '') {
          return 1;
        }
        if (a.ean !== '' && b.ean === '') {
          return -1;
        }
        if (a.ean === '' && b.ean === '') {
          return 0;
        }
        return b.ean.localeCompare(a.ean, undefined, { numeric: true });
      });
      setItems(sortedItems);
      setEanOrdered('dsc');
      setCategorieOrdered('');
      setInfoOrdered('');
    }
  }

  function ordenateListbyInfo(sortby) {
    if (sortby == 'asc') {
      const sortedItems = items.sort((a, b) => {
        if (a.inf_compl_1 === '' && b.inf_compl_1 !== '') {
          return 1;
        }
        if (a.inf_compl_1 !== '' && b.inf_compl_1 === '') {
          return -1;
        }
        if (a.inf_compl_1 === '' && b.inf_compl_1 === '') {
          return 0;
        }
        return a.inf_compl_1.localeCompare(b.inf_compl_1, undefined, { numeric: true });
      });
      setItems(sortedItems);
      setInfoOrdered('asc');
      setEanOrdered('');
      setCategorieOrdered('');
    } else if (sortby == 'dsc') {
      const sortedItems = items.sort((a, b) => {
        if (a.inf_compl_1 === '' && b.inf_compl_1 !== '') {
          return 1;
        }
        if (a.inf_compl_1 !== '' && b.inf_compl_1 === '') {
          return -1;
        }
        if (a.inf_compl_1 === '' && b.inf_compl_1 === '') {
          return 0;
        }
        return b.inf_compl_1.localeCompare(a.inf_compl_1, undefined, { numeric: true });
      });
      setItems(sortedItems);
      setInfoOrdered('dsc');
      setEanOrdered('');
      setCategorieOrdered('');
    }
  }
  function ordenateListbyCategorie(sortby) {
    if (sortby == 'dsc') {
      const sortedItems = items.sort((a, b) => {
        if (a.descricao < b.descricao) {
          return 1;
        } else if (a.descricao > b.descricao) {
          return -1;
        }
        return 0;
      });
      setItems(sortedItems);
      setCategorieOrdered('dsc');
      setInfoOrdered('');
      setEanOrdered('');
    } else if (sortby == 'asc') {
      const sortedItems = items.sort((a, b) => {
        if (a.descricao < b.descricao) {
          return -1;
        } else if (a.descricao > b.descricao) {
          return 1;
        }
        return 0;
      });
      setItems(sortedItems);
      setCategorieOrdered('asc');
      setInfoOrdered('');
      setEanOrdered('');
    }
  }

  function csv() {
    let body = items.map((item, index) => {
      return [
        item.id_externo ? item.id_externo : '',
        item.ativo ? item.ativo : '',
        item.descricao ? item.descricao : '',
        item.ean ? item.ean : '',
        item.inf_compl_1 ? item.inf_compl_1 : '',
        item.inf_compl_2 ? item.inf_compl_2 : '',
        item.inf_compl_3 ? item.inf_compl_3 : '',
        item.inf_compl_4 ? item.inf_compl_4 : '',
        item.inf_compl_5 ? item.inf_compl_5 : '',
        item.up_1 ? item.up_1 : '',
        item.up_2 ? item.up_2 : '',
        item.up_3 ? item.up_3 : '',
        item.up_4 ? item.up_4 : '',
        item.up_5 ? item.up_5 : '',
        item.observacao ? item.observacao : '',
      ];
    });
    let body2 = body.filter((item) => {
      if (item) {
        return item;
      }
    });
    return [...body2];
  }

  function getInfoBrand(brandSelected) {
    api
      .get(
        `/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=${idCategoria}&marca=${brandSelected ? brandSelected : ''}&modelo=`
      )
      .then((response) => {
        setBrandList(response.data);
        if (brandSelected) {
          setShowModal(true);
        }
      });
  }

  function saveBrand(brand) {
    setShowModal(false);
    brand.marca = brandNew;
    let reg = [brand];

    let option = {
      headers: { 'Content-Type': ['application/json'] },
    };
    api.post('/infs_compl', reg, option).then(
      (response) => {
        getInfoBrand();
        Swal.fire({
          title: `${t('messages.success')}`,
          icon: 'success',
          confirmButtonText: 'Confirmar',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      },
      (error) => {
        Swal.fire({
          title: `${t('messages.error_message')}`,
          icon: 'error',
          confirmButtonText: 'Confirmar',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    );
  }

  function newModel(brand) {
    setShowModal(false);
    Swal.fire({
      title: `${t('actions.add')} Modelo`,
      input: 'text',
      inputLabel: `${t('items.insert_name')}`,
      customClass: {
        title: 'swal-title',
        input: 'swal-form text-left',
        confirmButton: 'swal-confirm-button',
        inputLabel: 'swal-input-label text-left',
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
      confirmButtonText: 'Salvar',
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        let lista = [];
        brand.modelo.push({ _id: '0', descricao: resultado.value });
        lista.push(brand);

        let option = {
          headers: { 'Content-Type': ['application/json'] },
        };
        api.post('/infs_compl', lista, option).then(
          (response) => {
            getInfoBrand();
            Swal.fire({
              title: `${t('messages.success')}`,
              icon: 'success',
              confirmButtonText: 'Confirmar',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          },
          (error) => {
            Swal.fire({
              title: `${t('messages.error_message')}`,
              icon: 'error',
              confirmButtonText: 'Confirmar',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        );
      }
    });
  }

  function deleteBrandOrModel(newInfo, type, brandId) {
    setShowModal(false);
    Swal.fire({
      title: `${t('messages.warning_delete')}`,
      confirmButtonText: 'Confirmar',
      icon: 'warning',
      customClass: {
        title: 'swal-title',
        input: 'swal-form text-left',
        confirmButton: 'swal-confirm-button',
        inputLabel: 'swal-input-label text-left',
        confirmButton: 'btn btn-primary',
      },
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        if (type == 'brand') {
          api.delete(`/infs_compl/${newInfo}`).then(
            (response) => {
              Swal.fire({
                title: `${t('messages.success')}`,
                icon: 'success',
                confirmButtonText: 'Confirmar',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            },
            (error) => {
              Swal.fire({
                title: `${t('messages.error_message')}`,
                icon: 'error',
                confirmButtonText: 'Confirmar',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            }
          );
        } else {
          api.delete(`/infs_compl/${brandId}?id_model=${newInfo}`).then(
            (response) => {
              getInfoBrand();
              Swal.fire({
                title: `${t('messages.success')}`,
                icon: 'success',
                confirmButtonText: 'Confirmar',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            },
            (error) => {
              Swal.fire({
                title: `${t('messages.error_message')}`,
                icon: 'error',
                confirmButtonText: 'Confirmar',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            }
          );
        }
      }
    });
  }

  function editModel(brand, idModel) {
    setShowModal(false);
    Swal.fire({
      title: `${t('actions.refresh')} Modelo`,
      input: 'text',
      inputLabel: `${t('items.insert_name')}`,
      customClass: {
        title: 'swal-title',
        input: 'swal-form text-left',
        confirmButton: 'swal-confirm-button',
        inputLabel: 'swal-input-label text-left',
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
      confirmButtonText: 'Salvar',
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        let reg = [];
        for (let i = 0; i < brand.modelo.length; i++) {
          if (brand.modelo[i]._id === idModel) {
            brand.modelo[i].descricao = resultado.value;
          }
        }
        reg.push(brand);
        let option = {
          headers: { 'Content-Type': ['application/json'] },
        };
        api.post('/infs_compl', reg, option).then(
          (response) => {
            getInfoBrand();
            Swal.fire({
              title: `${t('messages.success')}`,
              icon: 'success',
              confirmButtonText: 'Confirmar',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          },
          (error) => {
            Swal.fire({
              title: `${t('messages.error_message')}`,
              icon: 'error',
              confirmButtonText: 'Confirmar',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        );
      }
    });
  }
  //

  /*
    async function getListbyXml(file) {
        setImportLoading(true)
        setUploaded([])
        setImportList([])
        setImportPage(0)
        setImportErro(0)
        setImportRepetido(0)
        setImportRegistrado(0)
        const text = await file.text()
        var XMLParser = await require('react-xml-parser');
        const xml = new XMLParser().parseFromString(text);

    }*/

  async function getListbyXml(file) {
    const text = await file.text();
    var XMLParser = await require('react-xml-parser');
    const xml = new XMLParser().parseFromString(text);

    let result = [];
    for (var i = 0; i < xml.getElementsByTagName('xProd').length; i++) {
      result.push([
        '',
        '',
        xml.getElementsByTagName('xProd')[i].value,
        xml.getElementsByTagName('cEAN')[i].value,
        Number(xml.getElementsByTagName('vUnCom')[i].value),
        xml.getElementsByTagName('vProd')[i].value,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ]);
    }
    if (result.length <= 70000) {
      let catlist = [];
      let erro = 0;
      let repetido = 0;
      let registrado = 0;
      await setImportList(
        result.filter((item, index) => {
          if (item.length != 1) {
            catlist.push(item[2]);
            let importItem = item;
            if (item[2] == '') {
              importItem[22] = 'erro';
              erro++;
            } else if (catlist.indexOf(item[2]) < index) {
              importItem[22] = 'repetido';
              repetido++;
            } else if (inBaseProps.categories_desc.includes(item[2])) {
              importItem[22] = 'registrado';
              importItem[23] = inBaseProps.categories_id[inBaseProps.categories_desc.indexOf(item[2])];
              registrado++;
            } else {
              importItem[22] = 'ok';
              importItem[23] = '';
            }
            return importItem;
          }
        })
      );
      setImportErro(erro);
      setImportRepetido(repetido);
      setImportRegistrado(registrado);
    } else {
      Swal.fire({
        title: 'Ops... !',
        text: `Seu arquivo excede 70K de itens`,
        icon: 'error',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: '2500',
      });
    }
    setImportLoading(false);
  }

  async function chooseFileImport(file) {
    setImportLoading(true);
    setUploaded([]);
    setImportList([]);
    setImportPage(0);
    setImportErro(0);
    setImportRepetido(0);
    setImportRegistrado(0);
    const text = await file.text();
    const result = await parse(text);
    if (result.data.length <= 70000) {
      let catlist = [];
      let erro = 0;
      let repetido = 0;
      let registrado = 0;
      await setImportList(
        result.data.filter((item, index) => {
          if (item.length != 1) {
            catlist.push(item[2]);
            let importItem = item;
            if (item[2] == '') {
              importItem[22] = 'erro';
              erro++;
            } else if (catlist.indexOf(item[2]) < index) {
              importItem[22] = 'repetido';
              repetido++;
            } else if (inBaseProps.categories_desc.includes(item[2])) {
              importItem[22] = 'registrado';
              importItem[23] = inBaseProps.categories_id[inBaseProps.categories_desc.indexOf(item[2])];
              registrado++;
            } else {
              importItem[22] = 'ok';
              importItem[23] = '';
            }
            return importItem;
          }
        })
      );
      setImportErro(erro);
      setImportRepetido(repetido);
      setImportRegistrado(registrado);
    } else {
      Swal.fire({
        title: 'Ops... !',
        text: `Seu arquivo excede 70K de itens`,
        icon: 'error',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: '2500',
      });
    }
    setImportLoading(false);
  }

  function getID() {
    return 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function getSmallID() {
    let code = 'xxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    return sessionStorage.getItem('idConta') + code;
  }

  async function verififyBase() {
    let inBase = {
      categories_id: [],
      categories_desc: [],
      categories: [],
    };

    setImportLoading(true);

    await api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(async (response) => {
      await response.data.map((item) => {
        inBase.categories.push({
          _id: item._id,
          descricao: item.descricao,
        });
        inBase.categories_id.push(item._id);
        inBase.categories_desc.push(item.descricao);
      });
    });

    setInBaseProps(inBase);
    setImportLoading(false);
  }

  function importNumberListGet() {
    let list = [];
    let qtdpages = Math.ceil(importList.length / 1000);

    if (qtdpages >= 2 && qtdpages <= 5) {
      for (let i = 1; i <= qtdpages; i++) {
        list.push(i);
      }
    } else if (qtdpages > 5) {
      if (importPage <= 1) {
        for (let i = 1; i <= 5; i++) {
          list.push(i);
        }
      } else if (importPage > 1 && importPage < qtdpages - 2) {
        for (let i = importPage - 1; i <= importPage + 3; i++) {
          list.push(i);
        }
      } else {
        for (let i = qtdpages - 4; i <= qtdpages; i++) {
          list.push(i);
        }
      }
    }
    return list;
  }

  function colorImportList(stat) {
    if (stat == 'erro') {
      return { background: '#C40233', color: '#F8F8F8' };
    } else if (stat == 'repetido') {
      return { background: '#909090', color: '#F8F8F8' };
    } else if (stat == 'registrado') {
      return { background: '#338333', color: '#F8F8F8' };
    } else if (stat == 'ok') {
      return {};
    }
  }

  function changeFunction(key) {
    switch (key) {
      case 0:
        return (
          <div>
            <Container fluid>
              <Row className="my-3">
                <Col className="d-flex">
                  <h3>{t('common.categories')}</h3>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button variant="light" className="me-2" onClick={(e) => setChange(6)}>
                    <FaFilePdf className="me-2" />
                    {t('actions.generate')} PDF
                  </Button>
                  <Button variant="light" onClick={(e) => setChange(5)}>
                    <FaFileCsv className="me-2" />
                    {t('actions.generate')} CSV
                  </Button>
                  {(sessionStorage.getItem('idConta') === 'c68AeC-CZ' && sessionStorage.getItem('perfil') == 'usu') ||
                  (sessionStorage.getItem('idConta') === 'JAxNy36WU' && sessionStorage.getItem('perfil') == 'usu') ? (
                    ''
                  ) : (
                    <>
                      <Button variant="light" className="me-2" onClick={(e) => setChange(1)}>
                        {t('actions.import')}
                      </Button>
                      <Button variant="custom-primary" className="ms-2" onClick={(e) => setChange(2)}>
                        <span className="me-2">+</span>
                        {t('actions.create')}
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <Col className="p-3 bg-white rounded border shadow-sm">
                <Col className="rounded p-3 mt-3" style={{ background: '#f5f6fb' }}>
                  <div className="d-flex align-items-center">
                    <h5 className="fw-bold text-primary">
                      <FiFilter />
                      <span className="px-2">{t('common.filter')}</span>
                    </h5>
                  </div>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-bold">{t('common.description')}</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          e.target.value == '' ? setDescricao('*') : setDescricao(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-bold">EAN</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          e.target.value == '' ? setEan('*') : setEan(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-bold">{t('common.active')}</Form.Label>
                      <Form.Select
                        value={ativo}
                        onChange={(e) => {
                          setAtivo(e.target.value);
                        }}
                      >
                        <option value={'*'}>Todos</option>
                        <option value={'1'}>Sim</option>
                        <option value={'0'}>Não</option>
                      </Form.Select>
                    </Form.Group>
                    <Row className="justify-content-end px-0 py-3">
                      <Button
                        variant="primary"
                        style={{ width: '150px' }}
                        onClick={(e) => {
                          categorias();
                        }}
                      >
                        {t('actions.search')}
                      </Button>
                    </Row>
                  </Row>
                </Col>
                <Col className="mt-3">
                  <Table responsive>
                    <thead className="bg-white text-uppercase">
                      <tr>
                        <th>#</th>
                        <th>
                          <span className="d-flex flex-row">
                            {t('common.categories')}
                            <span className="d-flex flex-column align-items">
                              <IoMdArrowDropup
                                role="button"
                                className={categorieOrdered == 'asc' ? 'text-dark' : 'text-secondary'}
                                onClick={(e) => {
                                  ordenateListbyCategorie('asc');
                                }}
                              />
                              <IoMdArrowDropdown
                                role="button"
                                className={categorieOrdered == 'dsc' ? 'text-dark' : 'text-secondary'}
                                onClick={(e) => {
                                  ordenateListbyCategorie('dsc');
                                }}
                                style={{ marginTop: '-6px' }}
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span className="d-flex flex-row">
                            EAN
                            <span className="d-flex flex-column align-items">
                              <IoMdArrowDropup
                                role="button"
                                className={eanOrdered == 'asc' ? 'text-dark' : 'text-secondary'}
                                onClick={(e) => {
                                  ordenateListbyEan('asc');
                                }}
                              />
                              <IoMdArrowDropdown
                                role="button"
                                className={eanOrdered == 'dsc' ? 'text-dark' : 'text-secondary'}
                                onClick={(e) => {
                                  ordenateListbyEan('dsc');
                                }}
                                style={{ marginTop: '-6px' }}
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span className="d-flex flex-row">
                            {t('common.info_label')} 1
                            <span className="d-flex flex-column align-items">
                              <IoMdArrowDropup
                                role="button"
                                className={infoOrdered == 'asc' ? 'text-dark' : 'text-secondary'}
                                onClick={(e) => {
                                  ordenateListbyInfo('asc');
                                }}
                              />
                              <IoMdArrowDropdown
                                role="button"
                                className={infoOrdered == 'dsc' ? 'text-dark' : 'text-secondary'}
                                onClick={(e) => {
                                  ordenateListbyInfo('dsc');
                                }}
                                style={{ marginTop: '-6px' }}
                              />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    {!itemsLoaded ? (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150} />
                      </div>
                    ) : (
                      <tbody>
                        {items.map((item, index) => (
                          <tr
                            className="edit"
                            key={index}
                            onClick={(e) => {
                              editItem(item);
                            }}
                          >
                            <th>{index + 1}</th>
                            <th>{item.descricao}</th>
                            <th>{item.ean}</th>
                            <th>{item.inf_compl_1}</th>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                  <div className="d-flex justify-content-end g-5">
                    <div>
                      <Form.Group as={Row} className="justify-content-end flex-nowrap">
                        <Form.Label column className="text-end text-nowrap fw-medium text-black-50">
                          Itens por página:
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Select
                            onChange={(e) => {
                              setPageLimit(e.target.value);
                            }}
                          >
                            <option value="10">10</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value={Math.ceil(itemsLength.total_reg)}>Todos</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </div>
                    <div>
                      <Pagination
                        count={Math.ceil(itemsLength.total_reg / pageLimit)}
                        page={pageNumber}
                        onChange={handleChangePage}
                        shape="rounded"
                      />
                    </div>
                  </div>
                </Col>
              </Col>
            </Container>
          </div>
        );
        break;
      case 1:
        return (
          <div>
            <Container fluid>
              <Row>
                <Col>
                  <h3>{t('common.categories')}</h3>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button
                    variant="light"
                    className="me-2 text-uppercase"
                    onClick={(e) => {
                      importSave();
                    }}
                  >
                    <FiSave className="me-2" />
                    {t('actions.import')}
                  </Button>
                  <Button
                    variant="light"
                    className="text-uppercase"
                    onClick={(e) => {
                      categorias();
                      cleaner();
                    }}
                  >
                    <FiArrowLeft className="me-2" />
                    {t('actions.exit')}
                  </Button>
                </Col>
              </Row>
              <Col className="bg-white rounded border p-3">
                <Row className="justify-content-end p-3">
                  <Form.Check
                    type={'switch'}
                    label="Importar xml"
                    onChange={(e) => {
                      setChoice(!choice);
                    }}
                  />
                </Row>
                {choice ? (
                  <>
                    <Form.Control
                      type="file"
                      accept={'text/xml'}
                      onChange={(e) => {
                        getListbyXml(e.target.files[0]);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <input
                      id="dropFile"
                      style={{ display: 'none' }}
                      type="file"
                      accept={fileTypes}
                      onChange={(e) => {
                        e.preventDefault();
                        if (e.target.files[0]) {
                          chooseFileImport(e.target.files[0]);
                        }
                      }}
                    />
                    <div
                      className="p-5 rounded d-flex justify-content-center align-items-center flex-column input-file"
                      style={{
                        border: '3px dashed #d8e4f7',
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        if (fileTypes.includes(e.dataTransfer.files[0].type)) chooseFileImport(e.dataTransfer.files[0]);
                      }}
                      onClick={(e) => {
                        let getFile = window.document.getElementById('dropFile');
                        getFile.click();
                      }}
                    >
                      <FiUpload fontSize={64} style={{ color: 'var(--primary-color)' }} />
                      <h6>{t('items.upload_file')} ( .CSV, .TXT)</h6>
                    </div>
                  </>
                )}
                {!importLoading ? (
                  <Row className="justify-content-between align-items-end">
                    {importList.length > 1000 ? (
                      <Col className="pagesContent" style={{ marginTop: '10px' }}>
                        <div
                          className="numberPages"
                          onClick={(e) => {
                            importPage > 0 ? setImportPage(importPage - 1) : setImportPage(importPage);
                          }}
                        >
                          Anterior
                        </div>
                        {importNumberListGet().map((number, index) => (
                          <div
                            className="numberPages"
                            key={index}
                            onClick={(e) => {
                              setImportPage(number - 1);
                            }}
                          >
                            {number}
                          </div>
                        ))}
                        <div
                          className="numberPages"
                          onClick={(e) => {
                            importPage < Math.ceil(importList.length / 1000) - 1
                              ? setImportPage(importPage + 1)
                              : setImportPage(importPage);
                          }}
                        >
                          Próximo
                        </div>
                        <div className="numberPages">Itens: {importList.length}</div>
                        <div className="numberPages">Importados: {uploaded.length}</div>
                      </Col>
                    ) : (
                      <Col className="pagesContent" style={{ marginTop: '10px' }}>
                        <div className="numberPages">Itens: {importList.length}</div>
                        <div className="numberPages">Importados: {uploaded.length}</div>
                      </Col>
                    )}
                    <Col className="d-flex flex-row justify-content-end">
                      <div className="d-flex flex-row align-items-center me-3">
                        <div
                          className="custom-checkbox fw-bold me-1 border text-dark"
                          style={{ backgroundColor: '#FFFFFF', textAlign: 'center', cursor: 'pointer' }}
                        >
                          {''}
                        </div>{' '}
                        {t('common.registered')}: {importRegistrado}
                      </div>
                      <div className="d-flex flex-row align-items-center me-3">
                        <div
                          className="custom-checkbox fw-bold me-1 border text-dark"
                          style={{ backgroundColor: '#909090', textAlign: 'center', cursor: 'pointer' }}
                        >
                          {''}
                        </div>{' '}
                        {t('common.tag_repeted')}: {importRepetido}
                      </div>
                      <div className="d-flex flex-row align-items-center me-3">
                        <div
                          className="custom-checkbox fw-bold me-1 border text-dark"
                          style={{ backgroundColor: '#C40233', textAlign: 'center', cursor: 'pointer' }}
                        >
                          {''}
                        </div>{' '}
                        {t('common.items_incorrect')}: {importErro}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                {importLoading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '150px' }}>
                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150} />
                  </div>
                ) : (
                  <>
                    <Table responsive>
                      <thead>
                        <tr className="bg-white">
                          <th>#</th>
                          <th>CÓD._EXTERNO</th>
                          <th>ATIVO</th>
                          <th>DESCRIÇÃO</th>
                          <th>EAN</th>
                          <th>INF._COMPL._1</th>
                          <th>INF._COMPL._2</th>
                          <th>INF._COMPL._3</th>
                          <th>INF._COMPL._4</th>
                          <th>INF._COMPL._5</th>
                          <th>UP_1</th>
                          <th>UP_2</th>
                          <th>UP_3</th>
                          <th>UP_4</th>
                          <th>UP_5</th>
                          <th>OBSERVAÇÃO</th>
                          <th>MARCA</th>
                          <th>MODELO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {importList.slice(importPage * 1000, importPage * 1000 + 1000).map((item, index) => (
                          <tr key={index} style={colorImportList(item[22])}>
                            <th>{importPage * 1000 + index + 1}</th>
                            <th>{item[0]}</th>
                            <th>{item[1]}</th>
                            <th>{item[2]}</th>
                            <th>{item[3]}</th>
                            <th>{item[4]}</th>
                            <th>{item[5]}</th>
                            <th>{item[6]}</th>
                            <th>{item[7]}</th>
                            <th>{item[8]}</th>
                            <th>{item[9]}</th>
                            <th>{item[10]}</th>
                            <th>{item[11]}</th>
                            <th>{item[12]}</th>
                            <th>{item[13]}</th>
                            <th>{item[14]}</th>
                            <th>{item[24]}</th>
                            <th>{item[25]}</th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
              </Col>
            </Container>
          </div>
        );
        break;
      case 2:
        return (
          <div>
            <Container fluid>
              <Row>
                <Col>
                  <h3>{t('common.categories')}</h3>
                </Col>
                <Col className="d-flex justify-content-end">
                  {(sessionStorage.getItem('idConta') === 'c68AeC-CZ' && sessionStorage.getItem('perfil') == 'usu') ||
                  (sessionStorage.getItem('idConta') === 'JAxNy36WU' && sessionStorage.getItem('perfil') == 'usu') ? (
                    ''
                  ) : (
                    <>
                      {editing == 1 ? (
                        <Button
                          variant="light"
                          className="me-2 text-uppercase"
                          onClick={(e) => {
                            remove();
                          }}
                        >
                          EXCLUIR
                        </Button>
                      ) : (
                        ''
                      )}
                      <Button
                        variant="custom-danger"
                        className="me-2 text-uppercase"
                        onClick={(e) => {
                          save();
                        }}
                      >
                        <FiSave className="me-2" />
                        {t('actions.save')}
                      </Button>
                    </>
                  )}
                  <Button variant="light" className="text-uppercase" onClick={(e) => cleaner()}>
                    <FiArrowLeft className="me-2" />
                    {t('actions.exit')}
                  </Button>
                </Col>
              </Row>
              <Col className="mt-3 border rounded bg-white p-3">
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.description')}</Form.Label>
                    <Form.Control
                      type="text"
                      value={descricaoNew}
                      onChange={(e) => {
                        setDescricaoNew(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>EAN</Form.Label>
                    <Form.Control
                      type="text"
                      value={eanNew}
                      onChange={(e) => {
                        setEanNew(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.active')}</Form.Label>
                    <Form.Select
                      value={ativoNew}
                      onChange={(e) => {
                        setAtivoNew(e.target.value);
                      }}
                    >
                      <option value="1">{t('export.yes')}</option>
                      <option value="0">{t('export.no')}</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.info')} 1</Form.Label>
                    <Form.Control
                      type="text"
                      value={infCompl1New}
                      onChange={(e) => {
                        setInfCompl1New(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.info')} 2</Form.Label>
                    <Form.Control
                      type="text"
                      value={infCompl2New}
                      onChange={(e) => {
                        setInfCompl2New(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.info')} 3</Form.Label>
                    <Form.Control
                      type="text"
                      value={infCompl3New}
                      onChange={(e) => {
                        setInfCompl3New(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.info')} 4</Form.Label>
                    <Form.Control
                      type="text"
                      value={infCompl4New}
                      onChange={(e) => {
                        setInfCompl4New(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.info')} 5</Form.Label>
                    <Form.Control
                      type="text"
                      value={infCompl5New}
                      onChange={(e) => {
                        setInfCompl5New(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.info')} 6</Form.Label>
                    <Form.Control
                      type="text"
                      value={infCompl6New}
                      onChange={(e) => {
                        setInfCompl6New(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>UP 1</Form.Label>
                    <Form.Control
                      type="text"
                      value={up1New}
                      onChange={(e) => {
                        setUp1New(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>UP 2</Form.Label>
                    <Form.Control
                      type="text"
                      value={up2New}
                      onChange={(e) => {
                        setUp2New(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>UP 3</Form.Label>
                    <Form.Control
                      type="text"
                      value={up3New}
                      onChange={(e) => {
                        setUp3New(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>UP 4</Form.Label>
                    <Form.Control
                      type="text"
                      value={up4New}
                      onChange={(e) => {
                        setUp4New(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>UP 5</Form.Label>
                    <Form.Control
                      type="text"
                      value={up5New}
                      onChange={(e) => {
                        setUp5New(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>{t('common.observation')}</Form.Label>
                    <Form.Control
                      type="text"
                      value={observacaoNew}
                      onChange={(e) => {
                        setObservacaoNew(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Marcas/Modelos</Form.Label>
                    <Form.Select onChange={(e) => getInfoBrand(e.target.value)}>
                      <option className="text-secondary">Toque para ver as marcas</option>
                      {brandList?.map((b, i) => (
                        <option key={i} value={b.marca}>
                          {b.marca}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  {showModal &&
                    brandList?.map((b, i) => (
                      <>
                        <Modal
                          show={showModal}
                          centered
                          size="md"
                          onHide={(e) => {
                            setShowModal(false);
                          }}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>{b.marca}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <h5>Detalhes:</h5>
                            <Form.Group>
                              <Form.Label>Nome:</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={b.marca}
                                value={brandNew}
                                onChange={(e) => {
                                  setBrandNew(e.target.value);
                                }}
                              />
                            </Form.Group>
                            {b.modelo ? (
                              <Table className="mt-3">
                                <thead>
                                  <tr>
                                    <th>Modelos</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {b.modelo.map((m) => (
                                    <tr>
                                      <td>{m.descricao}</td>
                                      <td
                                        className={
                                          (sessionStorage.getItem('idConta') === 'c68AeC-CZ' &&
                                            sessionStorage.getItem('perfil') == 'usu') ||
                                          (sessionStorage.getItem('idConta') === 'JAxNy36WU' &&
                                            sessionStorage.getItem('perfil') == 'usu')
                                            ? 'visually-hidden'
                                            : ''
                                        }
                                      >
                                        <button
                                          className="btn"
                                          onClick={(e) => {
                                            editModel(b, m._id);
                                          }}
                                        >
                                          <FiEdit />
                                        </button>
                                        <button
                                          className="btn"
                                          onClick={(e) => {
                                            deleteBrandOrModel(m._id, 'model', b._id);
                                          }}
                                        >
                                          <FiTrash />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            ) : (
                              <p>Não possui modelos cadastrados</p>
                            )}
                            {(sessionStorage.getItem('idConta') === 'c68AeC-CZ' &&
                              sessionStorage.getItem('perfil') == 'usu') ||
                            (sessionStorage.getItem('idConta') === 'JAxNy36WU' &&
                              sessionStorage.getItem('perfil') == 'usu') ? (
                              ''
                            ) : (
                              <Button
                                variant="dark"
                                onClick={(e) => {
                                  newModel(b);
                                }}
                              >
                                <BiPlus className="me-2" />
                                Novo Modelo
                              </Button>
                            )}
                          </Modal.Body>
                          <Modal.Footer
                            className={
                              (sessionStorage.getItem('idConta') === 'c68AeC-CZ' &&
                                sessionStorage.getItem('perfil') == 'usu') ||
                              (sessionStorage.getItem('idConta') === 'JAxNy36WU' &&
                                sessionStorage.getItem('perfil') == 'usu')
                                ? 'visually-hidden'
                                : 'd-flex justify-content-between'
                            }
                          >
                            <Button
                              variant="outline-secondary"
                              onClick={(e) => {
                                deleteBrandOrModel(b._id, 'brand');
                              }}
                            >
                              Excluir
                            </Button>
                            <div>
                              <Button
                                variant="outline-secondary mx-2"
                                onClick={(e) => {
                                  setShowModal(false);
                                  getInfoBrand();
                                }}
                              >
                                {t('actions.close')}
                              </Button>
                              <Button
                                variant="danger"
                                onClick={(e) => {
                                  saveBrand(b);
                                }}
                              >
                                {t('actions.save')}
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </>
                    ))}
                </Row>
              </Col>
            </Container>

            <div className="list_account"></div>
          </div>
        );
        break;
      case 5:
        return (
          <div>
            <Container fluid>
              <Col className="d-flex justify-content-between" id="colection">
                <h3>{t('actions.generate')} CSV</h3>
                <Button
                  variant="light"
                  className="text-uppercase"
                  onClick={(e) => {
                    setChange(0);
                  }}
                >
                  <FiArrowLeft className="me-2" />
                  {t('actions.exit')}
                </Button>
              </Col>
              <ExportCSV headers={headersCSV} listItens={csv()} modulo={'categorias'} />
            </Container>
          </div>
        );
        break;
      case 6:
        return (
          <div>
            <Container fluid>
              <Col className="d-flex justify-content-between" id="colection">
                <h3>{t('actions.generate')} CSV</h3>
                <Button
                  variant="light"
                  className="text-uppercase"
                  onClick={(e) => {
                    setChange(0);
                  }}
                >
                  <FiArrowLeft className="me-2" />
                  {t('actions.exit')}
                </Button>
              </Col>
              <ExportPDF headers={headersCSV} listItens={csv()} modulo={'categorias'} />
            </Container>
          </div>
        );
        break;
      default:
        return <div>Error</div>;
        break;
    }
  }

  function editItem(item) {
    setChange(2);
    setEditing(1);
    setIdCategoria(item._id);
    setIdExterno(item.id_externo ? item.id_externo : '');
    setDescricaoNew(item.descricao ? item.descricao : '');
    setEanNew(item.ean ? item.ean : '');
    setAtivoNew(item.ativo ? item.ativo : '1');
    setInfCompl1New(item.inf_compl_1 ? item.inf_compl_1 : '');
    setInfCompl2New(item.inf_compl_2 ? item.inf_compl_2 : '');
    setInfCompl3New(item.inf_compl_3 ? item.inf_compl_3 : '');
    setInfCompl4New(item.inf_compl_4 ? item.inf_compl_4 : '');
    setInfCompl5New(item.inf_compl_5 ? item.inf_compl_5 : '');
    setInfCompl6New(item.inf_compl_6 ? item.inf_compl_6 : '');
    setUp1New(item.up_1 ? item.up_1 : '');
    setUp2New(item.up_2 ? item.up_2 : '');
    setUp3New(item.up_3 ? item.up_3 : '');
    setUp4New(item.up_4 ? item.up_4 : '');
    setUp5New(item.up_5 ? item.up_5 : '');
    setObservacaoNew(item.observacao ? item.observacao : '');
  }

  function cleaner() {
    setChange(0);
    setEditing(0);
    setUploaded(0);
    setIdCategoria('0');
    setIdExterno('');
    setDescricaoNew('');
    setEanNew('');
    setAtivoNew('1');
    setInfCompl1New('');
    setInfCompl2New('');
    setInfCompl3New('');
    setInfCompl4New('');
    setInfCompl5New('');
    setInfCompl6New('');
    setUp1New('');
    setUp2New('');
    setUp3New('');
    setUp4New('');
    setUp5New('');
    setObservacaoNew('');
    setImportList([]);
    setBrandList([]);
  }

  function save() {
    Swal.fire({
      title: 'Atenção !',
      text: idCategoria == '0' ? 'Deseja realmente criar este Item ?' : 'Deseja realmente editar este Item ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        if (descricaoNew == '') {
          Swal.fire({
            title: 'Ops... !',
            text: 'Informe a descrição  da Categoria',
            icon: 'error',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: '2500',
          });
        } else {
          let reg = [];
          reg.push({
            ativo: ativoNew,
            descricao: descricaoNew,
            ean: eanNew,
            id_conta: sessionStorage.getItem('idConta'),
            id_externo: idExterno,
            inf_compl_1: infCompl1New,
            inf_compl_2: infCompl2New,
            inf_compl_3: infCompl3New,
            inf_compl_4: infCompl4New,
            inf_compl_5: infCompl5New,
            inf_compl_6: infCompl6New,
            observacao: observacaoNew,
            up_1: up1New,
            up_2: up2New,
            up_3: up3New,
            up_4: up4New,
            up_5: up5New,
          });

          if (idCategoria != '0') {
            reg[0]['_id'] = idCategoria;
          }

          let option = {
            headers: { 'Content-Type': ['application/json'] },
          };

          api.post('/categoria', reg, option).then(
            (response) => {
              categorias();
              Swal.fire({
                title: 'Sucesso !',
                text: idCategoria == '0' ? 'Categoria criada com sucesso' : 'Categoria editada com sucesso',
                icon: 'success',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500',
              });
              cleaner();
            },
            (response) => {
              if (response.response.data.error && response.response.data.error == 'Descrição já existente!') {
                Swal.fire({
                  title: 'Ops... !',
                  html: 'Esta descrição já está cadastrada em sua base',
                  icon: 'error',
                  showConfirmButton: false,
                  timerProgressBar: true,
                  timer: '4000',
                });
              } else if (response.response.data.error && response.response.data.error.includes('EAN JÁ EXISTENTE')) {
                Swal.fire({
                  title: 'Ops... !',
                  html: 'Esse ean já está cadastrada em sua base',
                  icon: 'error',
                  showConfirmButton: false,
                  timerProgressBar: true,
                  timer: '4000',
                });
              } else {
                Swal.fire({
                  title: 'Ops... !',
                  text: 'Algo deu errado, por favor tente novamente mais tarde',
                  icon: 'error',
                  showConfirmButton: false,
                  timerProgressBar: true,
                  timer: '2500',
                });
              }
            }
          );
        }
      }
    });
  }

  useEffect(() => {
    getMarcaModelo();
  }, []);

  const [marcaLista, setMarcaLista] = useState([]);
  const [modeloLista, setModeloLista] = useState([]);

  function getMarcaModelo() {
    let brandList = [];
    let modeloList = [];

    api.get(`/infs_compl/${sessionStorage.getItem('idConta')}`).then((response) => {
      // Itera sobre os dados da resposta e extrai as informações desejadas
      response.data.forEach((item) => {
        brandList.push(item);
      });
      setMarcaLista(brandList);

      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].modelo.length > 0) {
          modeloList.push(response.data[i].modelo);
        }
      }
      setModeloLista(modeloList);
    });
  }

  function addModelo(categoria, marca, modelo, list) {
    let modelsList =
      marcaLista.filter((item) => item.marca === marca && item.id_categoria === categoria)[0]?.modelo ?? [];
    let modeloReg = [];
    if (modelsList) {
      modeloReg = modelsList;
    }

    if (modelsList.filter((item) => item?.descricao === modelo).length === 0) {
      modeloReg.push({
        _id: '0',
        descricao: modelo,
      });
    }

    return modeloReg;
  }

  function importSave() {
    Swal.fire({
      title: 'Atenção !',
      html: 'Categorias repetidas e incorretas serão ignoradas.<br/>Categorias já registradas serão atualizadas.<br/>Tem certeza que deseja importar estas categorias?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let regMarcaModelo = [];
        setUppingImport(true);
        let categoriasI = [];
        let importado = [];
        let status = true;
        await importList.map(async (item, index) => {
          if (item[22] == 'ok' || item[22] == 'registrado') {
            if (status) {
              if (item[2] != '') {
                let ok = true;
                let have = false;
                categoriasI.map((it) => {
                  if (it.descricao == item[4]) {
                    ok = false;
                  }
                });
                if (ok) {
                  have = false;
                  if (item[23] && item[23] != '') {
                    categoriasI.push({
                      descricao: item[2],
                      id_conta: sessionStorage.getItem('idConta'),
                      _id: item[23],

                      id_externo: item[0] ? item[0] : '',
                      ativo: item[1] ? item[1] : '1',
                      ean: item[3] ? item[3] : '',
                      inf_compl_1: item[4] ? item[4] : '',
                      inf_compl_2: item[5] ? item[5] : '',
                      inf_compl_3: item[6] ? item[6] : '',
                      inf_compl_4: item[7] ? item[7] : '',
                      inf_compl_5: item[8] ? item[8] : '',
                      up_1: item[9] ? item[9] : '',
                      up_2: item[10] ? item[10] : '',
                      up_3: item[11] ? item[11] : '',
                      up_4: item[12] ? item[12] : '',
                      up_5: item[13] ? item[13] : '',
                      observacao: item[14] ? item[14] : '',
                    });
                    if (item[24]) {
                      regMarcaModelo.push({
                        _id: getSmallID(),
                        id_conta: sessionStorage.getItem('idConta'),
                        id_categoria: item[23],
                        marca: item[24],
                        modelo: !item[25] ? [] : addModelo(item[23], item[24], item[25], regMarcaModelo),
                      });
                    }
                    have = true;
                  }
                  if (!have) {
                    let randomId_cat = getID();
                    categoriasI.push({
                      descricao: item[2],
                      id_conta: sessionStorage.getItem('idConta'),
                      _id: randomId_cat,

                      id_externo: item[0] ? item[0] : '',
                      ativo: item[1] ? item[1] : '1',
                      ean: item[3] ? item[3] : '',
                      inf_compl_1: item[4] ? item[4] : '',
                      inf_compl_2: item[5] ? item[5] : '',
                      inf_compl_3: item[6] ? item[6] : '',
                      inf_compl_4: item[7] ? item[7] : '',
                      inf_compl_5: item[8] ? item[8] : '',
                      up_1: item[9] ? item[9] : '',
                      up_2: item[10] ? item[10] : '',
                      up_3: item[11] ? item[11] : '',
                      up_4: item[12] ? item[12] : '',
                      up_5: item[13] ? item[13] : '',
                      observacao: item[14] ? item[14] : '',
                    });

                    if (item[24]) {
                      regMarcaModelo.push({
                        _id: getSmallID(),
                        id_conta: sessionStorage.getItem('idConta'),
                        id_categoria: randomId_cat,
                        marca: item[24],
                        modelo: item[25]
                          ? [
                              {
                                _id: 0,
                                descricao: item[25],
                              },
                            ]
                          : [],
                      });
                    }
                  }
                }

                importado.push(index);
              } else {
                status = false;
              }
            }
          }
        });
        if (status) {
          let option = {
            headers: {
              'Content-Type': ['application/json'],
            },
          };
          let reg = [
            {
              categoria: categoriasI,
              itens: [],
              nivel1: [],
              nivel2: [],
              nivel3: [],
              nivel4: [],
              parceiros: [],
            },
          ];

          api.post(`/itensDef/${sessionStorage.getItem('idConta')}`, reg, option).then(
            async (response) => {
              if (regMarcaModelo.length > 0) {
                for (let i = 0; i < regMarcaModelo.length; i++) {
                  await api.post(`/infs_compl`, [regMarcaModelo[i]]).then(
                    (response) => {
                      console.log('Marca e Modelo atualizadas com sucesso');
                      setUppingImport(false);
                    },
                    (response) => {
                      Swal.fire({
                        title: 'Ops... !',
                        text: 'Algo deu errado ao atualizar marca/modelo, por favor tente novamente mais tarde',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500',
                      });
                    }
                  );
                }
              }
              Swal.fire({
                title: 'Sucesso !',
                text: 'Categorias importados com sucesso',
                icon: 'success',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500',
              });
              setUppingImport(false);
            },
            (response) => {
              Swal.fire({
                title: 'Ops... !',
                text: 'Algo deu errado, por favor tente novamente mais tarde',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500',
              });
            }
          );
        }
      }
    });
  }

  function remove() {
    Swal.fire({
      title: 'Atenção !',
      text: 'Tem certeza que deseja excluir esta categoria ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        let option = {
          headers: { 'Content-Type': ['application/json'] },
        };

        api.delete(`/categoria/${idCategoria}`, option).then(
          (response) => {
            categorias();
            Swal.fire({
              title: 'Sucesso !',
              text: 'Categoria foi excluida de sua base',
              icon: 'success',
              showConfirmButton: false,
              timerProgressBar: true,
              timer: '2500',
            });
            cleaner();
          },
          (response) => {
            if (
              response.response.data.error &&
              response.response.data.error == 'Existe itens relacionado a essa categoria!'
            ) {
              Swal.fire({
                title: 'Ops... !',
                html: 'Ainda há itens vinculados a esta categoria</br>Exclua estes itens, desvincule ou altere esta categoria nestes itens',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '4000',
              });
            } else {
              Swal.fire({
                title: 'Ops... !',
                text: 'Algo deu errado, por favor tente novamente mais tarde',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500',
              });
            }
          }
        );
      }
    });
  }

  return <>{changeFunction(change)}</>;
}

export default Categories;
