import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';

import Stepper from '@mui/material/Stepper';

import Step from '@mui/material/Step';

import StepLabel from '@mui/material/StepLabel';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { Icon } from '@iconify/react/dist/iconify.js';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';

import { Backdrop, Box, Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import CustomModal from '../../components/ui/CustomModal';
import { fontWeight } from '@mui/system';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4'
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Icon icon="uis:check" className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const steps = [
  {
    value: 'login',
    label: 'Login',
  },
  {
    value: 'qrcode',
    label: 'Scanear QRCode',
  },
  {
    value: 'upload-foto',
    label: 'Adicionar Foto',
  },
  {
    value: 'confirm-data',
    label: 'Enviar dados',
  }
]

export default function NTI() {
  const navigate = useNavigate()

  const location = useLocation()

  const [activeStep, setActiveStep] = useState(2)

  useEffect(()=> {
    const link = location?.pathname?.replace('/nti/', '')

    const index = steps.findIndex(item => link.startsWith(item.value));
    
    setActiveStep(index)
  }, [location])

  useEffect(()=>{
    if(!secureLocalStorage.getItem('transport-user')){
      const link = location?.pathname?.replace('/nti/', '')
      const redirect = link && link.includes('qrcode') ? link?.replace('qrcode', '') : ""
      navigate('/nti/login', {state: { redirect}})
    }
  },[])

  return (
    <>
       <Stack direction="column" sx={{ height: '100vh', p: 3}}>
        <Container maxWidth="md">
          <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
            {steps.map((step) => (
              <Step key={step?.label} onClick={()=>{navigate(step?.value)}}>
                <StepLabel StepIconComponent={QontoStepIcon}>{step?.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Container>

        <Container maxWidth="sm" sx={{ flex: 1, overflowY: 'auto', py: 3}}>
          <Outlet />
        </Container>
      </Stack>
    </>
  );
}
