import { forwardRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import InputMask from 'react-input-mask';

import { Icon } from "@iconify/react/dist/iconify.js"

import { Alert, Box, Button, Container, FilledInput, FormControl, Input, InputAdornment, InputLabel, Snackbar, Stack, TextField, Typography } from "@mui/material"

import { t } from "i18next"

import logo from "../../assets/logo/inovaone/logotype.png"

import secureLocalStorage from 'react-secure-storage'; 

//-----------------------------------------------------------------

export default function NTILogin(){
    const navigate = useNavigate()

    const location = useLocation()

    const [nome, setUsername] = useState()

    const [cellphone, setCellphone] = useState()

    const [error, setError] = useState()

    function handleLogin(){
        if(!nome || !cellphone){
            setError("Insira email e telefone.")
            return
        }

        const usuario = {
            "nome": nome,
            "telefone": cellphone
        }

        const redirect = location?.state?.redirect

        secureLocalStorage.setItem('transport-user', usuario)

        navigate(`/nti/qrcode${redirect || ""}`)
    }

    return (
        <>
            <Stack direction="row" alignItems="center" sx={{height: '100%'}}>
                <Stack spacing={5} sx={{width: '100%'}}>
                    <Stack justifyContent="center" alignItems="center">
                        <Box
                            component="img"
                            width={150}
                            src={logo}
                        />
                    </Stack>
                    <Stack spacing={3}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel>Nome</InputLabel>
                            <FilledInput
                                id="inovaone-name-input"
                                name="inovaone-name-input"
                                onChange={e=>{setUsername(e.target.value)}}
                                startAdornment={<InputAdornment position="start">
                                    <Icon icon="ph:user-bold"/>
                                </InputAdornment>}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="filled">
                            <InputLabel>Telefone</InputLabel>
                            <InputMask
                                id="inovaone-cellphone-input"
                                name="inovaone-cellphone-input"
                                mask="(99) 99999-9999"
                                value={cellphone}
                                onChange={(event) => setCellphone(event.target.value)}
                            >
                                {(inputProps) => (
                                    <FilledInput
                                        {...inputProps}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Icon icon="iconamoon:phone" />
                                            </InputAdornment>
                                        }
                                    />
                                )}
                            </InputMask>
                        </FormControl>
                    </Stack>
                    <Button variant="contained" fullWidth onClick={()=>{handleLogin()}}>Entrar</Button>
                </Stack>
            </Stack>

            {error ? 
                <> 
                    <Snackbar
                        open={error}
                        autoHideDuration={3000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        onClose={()=>{setError()}}
                    >
                        <Alert variant="filled" severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                </>
            : ''}
        </>
    )
}