import { Button, Container, Paper, Stack } from "@mui/material";

import ConfigImport from "../../../components/ImportXML/ConfigImport";
import { useState } from "react";
import { t } from "i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const itemsField = [
    { label: 'Nível 1 (Descrição)', value: 'nivel_loc_1' },
    { label: 'Ativo (Nivel 1)', value: 'nivel_loc_1_ativo' },
    { label: 'Tag (Nivel 1)', value: 'nivel_loc_1_tag' },
    { label: 'Cor (Nivel 1)', value: 'nivel_loc_1_cor' },

    { label: 'Nível 2 (Descrição)', value: 'nivel_loc_2' },
    { label: 'Ativo (Nivel 2)', value: 'nivel_loc_2_ativo' },
    { label: 'Tag (Nivel 2)', value: 'nivel_loc_2_tag' },
    { label: 'Cor (Nivel 2)', value: 'nivel_loc_2_cor' },

    { label: 'Nível 3 (Descrição)', value: 'nivel_loc_3' },
    { label: 'Ativo (Nivel 3)', value: 'nivel_loc_3_ativo' },
    { label: 'Tag (Nivel 3)', value: 'nivel_loc_3_tag' },
    { label: 'Cor (Nivel 3)', value: 'nivel_loc_3_cor' },

    { label: 'Nível 4 (Descrição)', value: 'nivel_loc_4' },
    { label: 'Ativo (Nivel 4)', value: 'nivel_loc_4_ativo' },
    { label: 'Tag (Nivel 4)', value: 'nivel_loc_4_tag' },
    { label: 'Cor (Nivel 4)', value: 'nivel_loc_4_cor' },
]

export default function ImportConfig(){
    let {file} = useParams()

    const navigate = useNavigate()

    const location = useLocation()

    const [configImport, setConfigImport] = useState(location?.state?.config || '')

    const [preLoad, setPreload] = useState(location?.state?.preLoad || null)

    const [xmlField, setXmlField] = useState(location?.state?.xmlfields || null)

    return (
        <>
            <Container>

                <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{py: 3}}>
                    <Button variant="outlined" color="dark" onClick={()=>{navigate(`/locations/import`)}}>{t('actions.cancel')}</Button>
                    <Button variant="contained" color="error" onClick={()=>{navigate(`/locations/import}`, { state: { config: configImport, preLoad: preLoad } })}}>{t('actions.save')}</Button>
                </Stack>

                <Stack as={Paper} sx={{p: 3}}>
                    <ConfigImport 
                        module="locations"
                        format='csv'
                        optionsFields={itemsField} 
                        xmlFieldOptions={xmlField}
                        onChangeConfig={e=>{
                            setConfigImport(e)
                        }}
                        selectedModel={{
                            position: configImport?.infoPosition, 
                            keyTag: configImport?.keyTag, 
                            fields: configImport?.upcomingFields, 
                            xmlUploadFields: configImport?.XMLFields,
                            count: configImport?.countField
                        }}
                    />
                </Stack>

            </Container>
        </>
    )
}