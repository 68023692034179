import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import Stack from "@mui/material/Stack";

import Stepper from "@mui/material/Stepper";

import Step from "@mui/material/Step";

import StepLabel from "@mui/material/StepLabel";

import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

import { Icon } from "@iconify/react/dist/iconify.js";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import secureLocalStorage from "react-secure-storage";

import {
	Backdrop,
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Paper,
	Typography,
} from "@mui/material";
import CustomModal from "../../components/ui/CustomModal";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#784af4",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#784af4",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
	display: "flex",
	height: 22,
	alignItems: "center",
	...(ownerState.active && {
		color: "#784af4",
	}),
	"& .QontoStepIcon-completedIcon": {
		color: "#784af4",
		zIndex: 1,
		fontSize: 18,
	},
	"& .QontoStepIcon-circle": {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Icon icon="uis:check" className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

export default function Transport() {
	const navigate = useNavigate();

	const location = useLocation();

	const pathname = location.pathname.replace("/transport/", "");

	const steps = ["qrcode", "upload-foto", "confirm-data"];

	const [activeStepper, setActiveStepper] = useState(steps.indexOf(pathname)); // alterar

	const [cameraPermission, setCameraPermission] = useState("");

	useEffect(() => {
		if (!secureLocalStorage.getItem("transport-user")) {
			const redirect =
				pathname && pathname.includes("qrcode") ? pathname?.replace("qrcode", "") : "";
			navigate("/transport/login", { state: { redirect } });
		}
	}, []);

	useEffect(() => {
		navigator.permissions
			.query({ name: "camera" })
			.then((permissionStatus) => {
				const hasPermission = permissionStatus.state === "granted";
				setCameraPermission(hasPermission);
				permissionStatus.onchange = () => {
					const hasPermission = permissionStatus.state === "granted";
					setCameraPermission(hasPermission);
				};
			})
			.catch((err) => console.error("Erro ao verificar permissão da câmera:", err));
	}, []);

	return (
		<>
			<Stack alignItems="center">
				<Container maxWidth="md">
					<Stack spacing={3} sx={{ p: 2, py: 3 }}>
						<Stepper alternativeLabel activeStep={activeStepper} connector={<QontoConnector />}>
							<Step key={0}>
								<StepLabel StepIconComponent={QontoStepIcon}>Scanear QRCode</StepLabel>
							</Step>
							<Step key={1}>
								<StepLabel StepIconComponent={QontoStepIcon}>Adicionar foto</StepLabel>
							</Step>
							<Step key={2}>
								<StepLabel StepIconComponent={QontoStepIcon}>Enviar Dados</StepLabel>
							</Step>
						</Stepper>
						<Outlet />
					</Stack>
				</Container>
			</Stack>
		</>
	);
}
