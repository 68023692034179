import { useEffect, useState } from "react";

import { t } from "i18next";

import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Switch,
	TextField,
	Tooltip,
} from "@mui/material";

import api from "../../services/api";

import Alert from "../../components/ui/Alert";

import { Icon } from "@iconify/react/dist/iconify.js";

import AlertDialog from "../../components/AlertDialog";

import Loading from "../../components/loading";

export default function LocationForm({
	nivel,
	level,
	preValue = {},
	onUpdate = () => {},
}) {
	const [_id, setID] = useState(nivel?._id || null);

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState("");

	const [descricao, setDescricao] = useState("");

	const [tag, setTag] = useState("");

	const [ativo, setAtivo] = useState(1);

	const [observacao, setObservacao] = useState("");

	const [cor, setCor] = useState("");

	const [baixado, setBaixado] = useState(false);

	useEffect(() => {
		function getDetails() {
			const { descricao, ativo, observacao, tag, cor, loc_baixados } = nivel;
			setDescricao(descricao);
			setAtivo(ativo);
			setObservacao(observacao);
			setTag(tag);
			setCor(cor);
			setBaixado(loc_baixados || false);
		}
		if (nivel) {
			getDetails();
		}
	}, [nivel]);

	const handleUpdate = () => {
		onUpdate(null);
	};

	function handleSave() {
		const preConfig = nivel || {};
		let option = {
			headers: { "Content-Type": ["application/json"] },
		};
		const upload = {
			...preConfig,
			ativo: ativo || 1,
			descricao: descricao?.trim() || "",
			id_conta: sessionStorage.getItem("idConta"),
			observacao: observacao || "",
			tag: tag || "",
			cor: cor || "",
			loc_baixados: baixado,
			...preValue,
		};
		if (_id) {
			upload._id = _id;
		}
		api.post(`/nivel_loc${level}`, [upload], option).then(
			(response) => {
				setMessage({
					type: "success",
					message: t("messages.success_save"),
					title: t("messages.success"),
				});
				handleUpdate();
			},
			(error) => {
				const message = error?.response?.data;
				var code = "500";
				if (message == "Nivel com descrição já cadastrado!") {
					code = "409";
				}
				setMessage({
					type: "error",
					message: t(`messages.errors.${code}`),
					title: t("messages.errors.error"),
				});
			},
		);
	}

	function handleDelete(id_nivel) {
		AlertDialog.fire({
			title: t("messages.attention"),
			message: t("messages.warning_delete"),
			confirmText: t("actions.continue"),
			cancelText: t("actions.cancel"),
			onConfirm: () => {
				setLoading(true);
				deleteLevel(Number(level), id_nivel);
			},
			onCancel: () => console.log("Formulário cancelado"),
		});
	}

	async function deleteLevel(level, id) {
		try {
			// Deleta o nível atual
			await api.delete(`/nivel_loc${level}/${id}`);

			if (level == 4) {
				return;
			}

			// Busca subníveis relacionados
			const nextLevel = level + 1;
			const response = await api.get(
				`/nivel_loc${nextLevel}/${sessionStorage.getItem("idConta")}/${id}/*/*/*/*`,
			);
			const sublevels = response.data;

			if (!sublevels?.length) {
				setLoading(false);
			}

			// Deleta cada subnível recursivamente
			for (const sublevel of sublevels) {
				await deleteLevel(nextLevel, sublevel._id);
			}

			setMessage({
				type: "success",
				message: t("messages.success_save"),
				title: t("messages.success"),
			});
			handleUpdate();
		} catch (error) {
			setLoading(false);
			console.error(`Erro ao deletar nível ${level} com ID ${id}:`, error);
		}
	}

	return (
		<>
			<Stack spacing={3}>
				<FormControl>
					<FormLabel>{t("common.name")}</FormLabel>
					<TextField
						size="small"
						value={descricao}
						onChange={(e) => {
							setDescricao(e.target.value);
						}}
					/>
				</FormControl>

				<FormControl>
					<FormLabel>{t("common.observation")}</FormLabel>
					<TextField
						size="small"
						value={observacao}
						onChange={(e) => {
							setObservacao(e.target.value);
						}}
					/>
				</FormControl>

				<FormControl>
					<FormLabel>{t("common.color")}</FormLabel>
					<Stack direction="row">
						<TextField
							size="small"
							type="color"
							value={cor}
							onChange={(e) => {
								setCor(e.target.value);
							}}
							sx={{ width: "15ch" }}
						/>
						<Tooltip title={t("actions.delete")}>
							<IconButton
								onClick={() => {
									setCor("");
								}}
							>
								<Icon icon="line-md:close" />
							</IconButton>
						</Tooltip>
					</Stack>
				</FormControl>

				<FormControl>
					<FormLabel>{t("common.active")}</FormLabel>
					<Select
						size="small"
						value={ativo}
						onChange={(e) => {
							setAtivo(e.target.value);
						}}
					>
						<MenuItem value={"1"}>{t("common.yes")}</MenuItem>
						<MenuItem value={"0"}>{t("common.no")}</MenuItem>
					</Select>
				</FormControl>

				<FormControl>
					<FormLabel>Tag</FormLabel>
					<TextField
						size="small"
						value={tag}
						onChange={(e) => {
							setTag(e.target.value);
						}}
					/>
				</FormControl>

				<FormControl>
					<FormControlLabel
						control={
							<Switch
								checked={baixado}
								onChange={(e) => {
									setBaixado(!baixado);
								}}
							/>
						}
						label="Baixado"
					/>
				</FormControl>

				<Button
					variant="contained"
					onClick={() => {
						handleSave();
					}}
				>
					{t("actions.save")}
				</Button>
				{_id && (
					<Button
						startIcon={<Icon icon="solar:trash-bin-minimalistic-linear" />}
						variant="outlined"
						color="dark"
						onClick={() => {
							handleDelete(_id);
						}}
					>
						{t("actions.delete")}
					</Button>
				)}
			</Stack>

			<Loading show={loading} />

			{message ? (
				<Alert
					type={message.type}
					title={message.title}
					message={message.message}
					onClose={(e) => {
						setMessage(null);
					}}
				/>
			) : (
				""
			)}
		</>
	);
}
