import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';

import QrCodeScan from '../../components/QrcodeScan/QrcodeScan';

import { Alert, Box, Button, IconButton, Snackbar, Stack, TextField, Typography } from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

import api from '../../services/api';

import Loading from '../../components/loading';

import { green } from '@mui/material/colors';

//---------------------------------------------------------

export default function NTIScanQRCode() {
  const params = useParams();

  const location = useLocation();

  const { usuario } = location || {};

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const [item, setItem] = useState();

  const [error, setError] = useState();

  useEffect(() => {
    if (params) {
      const { id_conta, tag } = params;
      if (id_conta && tag) {
        getItem(id_conta.replace(/[${}]/g, ''), tag.replace(/[${}]/g, ''));
      }
    }
  }, []);

  function handleQRCode(value) {
    if (!value || typeof value !== 'string') {
      setError('Código lido inválido. Tente novamente ou entre em contato com o suporte.');
      return;
    }

    const baseUrl = 'https://www.inovacode.app.br/nti/qrcode/';
    if (!value.startsWith(baseUrl)) {
      setError('Link em formato incorreto. Tente novamente ou entre em contato com o suporte.');
      return;
    }

    const dataParts = value.replace(baseUrl, '').split('/');
    const [idConta, readTag] = dataParts.map((part) => part?.replace(/[${}]/g, ''));

    if (!idConta || !readTag) {
      setError('Tag e ID da conta não identificados. Tente novamente ou entre em contato com o suporte.');
      return;
    }

    getItem(idConta, readTag);
  }

  async function getItem(id_conta, tag) {
    setLoading(true);
    try {
      setLoading(true);
      const response = await api.get(`/itensPage/${id_conta}/*/*/${tag}/*/*/*/*/*/*/*/*/*/*/*/0?limit=10`);

      const item = response.data.find((i) => i?._id);

      if (!item) {
        setError('Item não encontrado. Tente novamente ou entre em contato com o suporte.');
      } else {
        setItem({ ...item, email: item?.inf_compl_1, usuario: item?.id_usuario });
        setLoading(false);
      }
    } catch (error) {
      setError('Tag não encontrada. Tente novamente ou entre em contato com o suporte.');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function handleContinue() {
    const props = {
      item,
    };
    navigate('/nti/upload-foto', { state: { ...props } });
  }

  return (
    <>
      <Loading show={loading} />

      <Stack spacing={5}>
        <div>
          <Typography variant="h1" textAlign="left">
            QRCode Scan
          </Typography>
          <Typography variant="subtitle">Aponte a camera e escaneie o código QR.</Typography>
        </div>

        {!item && (
          <Stack alignItems="center">
            <Box sx={{ width: { xs: '90vw', md: '400px' }, height: 'auto' }}>
              <QrCodeScan
                onChange={(e) => {
                  handleQRCode(e);
                }}
              />
            </Box>
          </Stack>
        )}

        {item && (
          <>
            <Stack justifyContent="center" alignItems="center" sx={{ padding: 2 }}>
              <IconButton
                sx={{ border: 5, borderColor: green[100], backgroundColor: 'green', color: 'white', marginBlock: 5 }}
              >
                <Icon icon="uis:check" width="32" />
              </IconButton>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                Lido com sucesso
              </Typography>
              <Typography variant="subtitle" textAlign="center" sx={{ width: '50%' }}>
                Clique no botão abaixo para continuar o registro.
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="dark"
              onClick={(e) => {
                handleContinue();
              }}
              endIcon={<Icon icon="ic:round-double-arrow" width={12} />}
              fullWidth
            >
              Continuar
            </Button>
          </>
        )}

        {error ? (
          <>
            <Snackbar
              open={error}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={() => {
                setError();
              }}
            >
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            </Snackbar>
          </>
        ) : (
          ''
        )}
      </Stack>
    </>
  );
}
