import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Stack,
  Container,
  IconButton,
  Grid2,
  Snackbar,
  AlertTitle,
} from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { Icon } from '@iconify/react/dist/iconify.js';
import { t } from 'i18next';
import api from '../../../services/api';
import Loading from '../../../components/loading';
import Alert from '../../../components/ui/Alert';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const GroupView = () => {
  const navigate = useNavigate();

  const { cod, epc } = useParams(); // Parametros

  const location = useLocation();

  const { groupState, preview } = location?.state || {};

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(); // Mensagem que aparece nos popups

  const [group, setGroup] = useState(groupState || {}); // Armazena o esquema do formulário

  useEffect(() => {
    function getGroup() {
      // Chamada à API para buscar o formulário (simulação de um ID de formulário)
      setLoading(true);
      api
        .get(`/agrupamentoFormularios/${cod}/*/*/*`)
        .then((response) => {
          const result = response.data.filter((item) => item._id);
          if (result.length) {
            setGroup(result[0]);
          } else {
            setMessage({
              type: 'error',
              message: 'Formulário não encontrado',
              title: t('messages.errors.error'),
            });
          }
        })
        .catch((error) => {
          setMessage({
            type: 'error',
            message: 'Formulário não encontrado',
            title: t('messages.errors.error'),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (cod && !groupState) {
      getGroup();
    }
  }, []);

  return (
    <Container maxWidth="sm" sx={{ p: 3, maxWidth: '600px', margin: 'auto' }}>
      <Stack spacing={5} sx={{ py: 5 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <div>
            <Typography variant="h4">{group?.title}</Typography>
            <Typography variant="subtitle">{group?.descricao}</Typography>
          </div>

          {preview && (
            <Button
              variant="outlined"
              color="dark"
              onClick={() => {
                navigate('/form-group/detail', { state: { group: groupState } });
              }}
            >
              {t('actions.exit')}
            </Button>
          )}
        </Stack>
        <Stack spacing={3}>
          {group?.formularios?.map((form, index) => (
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/form/${form?.id_formulario?.cod}${epc ? '/' + epc : ''}`);
              }}
              sx={{
                p: 2,
                borderRadius: 3,
              }}
              endIcon={<Icon icon="iconamoon:arrow-right-1-duotone" />}
            >
              {form?.id_formulario?.title}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

export default GroupView;
