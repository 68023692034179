import { Icon } from "@iconify/react/dist/iconify.js";

import { FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Stack, Typography } from "@mui/material";

import { useState } from "react";

import CustomModal from "../../../components/ui/CustomModal";

import QrcodeGenerator from "../../../components/QrcodeGenerator";

import { t } from "i18next";

export default function ItemsQrcode({tag = ""}){
    const [show, setShow] = useState(false)

    const [url, setURL] = useState('https://www.inovacode.app.br/items')

    return (
        <>
            <IconButton sx={{borderRadius: 1, border: 1, borderColor: 'divider'}} onClick={()=>{setShow(!show)}}>
                <Icon icon="icomoon-free:qrcode" width={16}/>
            </IconButton>

            {show && (
                <CustomModal dividers={false}>
                    <Stack sx={{p: 1}} spacing={3}>
                        <div>
                            <Typography variant="h1" textAlign="center">Qrcode</Typography>
                            <Typography variant="subtitle" textAlign="center">{t('items.qrcode_subtitle')}</Typography>
                            <FormControl>
                                <FormLabel id="Módulo">Módulo</FormLabel>
                                <RadioGroup
                                    aria-labelledby="Módulo"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={url}
                                    onChange={e=>{setURL(e.target.value)}}
                                >
                                    <FormControlLabel value="https://www.inovacode.app.br/items" control={<Radio />} label="Acesso padrão (login)" />
                                    <FormControlLabel value={`https://www.inovacode.app.br/transport/qrcode/${sessionStorage.getItem('idConta')}`} control={<Radio />} label="Módulo transporte (acesso externo)" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <QrcodeGenerator value={`${url}/${tag}`} width="50%"/>
                    </Stack>
                </CustomModal>
            )}
        </>
    )
}