import { useEffect, useState } from "react";

import { t } from "i18next";

import Loading from "../../../components/ui/Loading";

import api from "../../../services/api";

import { Box, Button, Chip, Divider, IconButton, Modal, Paper, Stack, styled, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js"

import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

import { ptBR } from '@mui/x-data-grid/locales';

import { useLocation, useNavigate } from "react-router-dom";

import Alert from "../../../components/ui/Alert";

import ImportXML from "../../../components/ImportXML/ImportXML.js";

import ConfigImport from "../../../components/ImportXML/ConfigImport.js";
import { green, grey, red, yellow } from "@mui/material/colors";

// ----------------------------------------------------------   

const columns = [
    { 
        field: 'id', 
        headerName: 'ID', 
        width: 90
    },
    { 
        headerName: 'Status', 
        field: 'status', 
        width: 200, 
        filterable: true,
        renderCell: (params) => (
            <Chip 
                label={params.row.status} 
                sx={{
                    borderRadius: 1,
                    fontWeight: 600,
                    backgroundColor: params.row.status.includes("Erro") ? red[100] : params.row.status == "Correto" ? green[50] : "",
                    color: params.row.status.includes("Erro") ? red[600] : params.row.status == "Correto" ? green[600] : grey[600]
                }}
            />)   
    },
    { headerName: 'Tag', field: 'tag', width: 300 },
    { headerName: 'Categoria', field: 'categoria', width: 300 },
    { headerName: 'EAN', field: 'ean' },
    { headerName: 'Nível Loc 1', field: 'nivel_loc_1' },
    { headerName: 'Nível Loc 2', field: 'nivel_loc_2' },
    { headerName: 'Nível Loc 3', field: 'nivel_loc_3' },
    { headerName: 'Nível Loc 4', field: 'nivel_loc_4' },
    { headerName: 'Informação Complementar 1', field: 'inf_compl_1' },
    { headerName: 'Informação Complementar 2', field: 'inf_compl_2' },
    { headerName: 'Informação Complementar 3', field: 'inf_compl_3' },
    { headerName: 'Informação Complementar 4', field: 'inf_compl_4' },
    { headerName: 'Informação Complementar 5', field: 'inf_compl_5' },
    { headerName: 'Informação Complementar 6', field: 'inf_compl_6' },
    { headerName: 'Informação Complementar 7', field: 'inf_compl_7' },
    { headerName: 'Informação Complementar 8', field: 'inf_compl_8' },
    { headerName: 'Informação Complementar 9', field: 'inf_compl_9' },
    { headerName: 'Informação Complementar 10', field: 'inf_compl_10' },
    { headerName: 'Marca', field: 'marca' },
    { headerName: 'Modelo', field: 'modelo' },
    { headerName: 'Valor', field: 'valor' },
    { headerName: 'Parceiro', field: 'parceiro' },
    { headerName: 'Observação', field: 'observacao' },
    { headerName: 'Ativo', field: 'ativo' },
    { headerName: 'Foto', field: 'foto' },
    { headerName: 'ID Externo', field: 'id_externo' },
    { headerName: 'Item Vinculado', field: 'id_item_vinculado' },
    { headerName: 'UP 1', field: 'up_1' },
    { headerName: 'UP 2', field: 'up_2' },
    { headerName: 'UP 3', field: 'up_3' },
    { headerName: 'UP 4', field: 'up_4' },
    { headerName: 'UP 5', field: 'up_5' }
]

export default function ImportItensXML(){
    const navigate = useNavigate()

    const location = useLocation();

    const id_conta = sessionStorage.getItem('idConta')

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState() // Armazena mensagens e erros

    const [configImport, setConfigImport] = useState(location?.state?.config || null) // Configuração do import

    const [categorias, setCategorias] = useState([]) // Lista de categorias na base

    const [itemsList, setitemsList] = useState([]) // Lista de itens na base

    const [parceiros, setParceiros] = useState([]) // Lista de parceiros na base

    // Lista de niveis na base
    const [nivel1, setNivel1] = useState([])

    const [nivel2, setNivel2] = useState([])

    const [nivel3, setNivel3] = useState([])

    const [nivel4, setNivel4] = useState([])

    const [marcas, setMarcas] = useState([])

    const [items, setItems] = useState([]) // Itens que foram importados

    const [upComming, setUpComming] = useState() // Itens que serão importados

    const [upMarcas, setUpMarcas] = useState([]) // Marcas que serão importadas

    const [importCount, setImportCount] = useState() // Contagem de itens por status

    const [prefixConfig, setPrefixConfig] = useState() // Prefixo cadastrado na conta - será adicionado no começo da tag

    useEffect(()=>{
        function getModels() {
            api.get(`/conta_config/${sessionStorage.getItem('idConta')}`).then(
                response => {
                    let data = response.data[0] ? response.data[0] : []
                    const modules = data?.import?.itens

                    const prefix = data?.item_verif_inicial
                    setPrefixConfig(prefix)
                    
                    if(modules && modules.length){
                        const {fields, xmlUploadFields, position, keyTag, fixedField} = modules[0]
                        setConfigImport({
                            infoPosition: position, 
                            upcomingFields: fields, 
                            XMLFields: xmlUploadFields, 
                            keyTag: keyTag,
                            fixedField: fixedField
                        })
                    }                    
                }
            )
        }
        if(!configImport){
            getModels()
        }
    },  [])

    useEffect(()=>{
        async function getItems() {
            setLoading(true)
            await api.get(`/item/${id_conta}/*/*/*/*/*/*/*/*/*/*/*/*/*`)
            .then(
                response => {
                    setitemsList(response.data.filter(item => item._id))
                    setLoading(false)
                },
                error=>{
                    setLoading(false)
                }
            )
        }

        getItems()
    }, [])

    useEffect(()=>{
        async function getcategorias() {
            
            await api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/1`, {})
            .then(
                response => {
                    setCategorias(response.data.filter(item => item._id))
                }
            ).finally(
                () => {
                    
                }
            )

            await api.get(`/infs_compl/${sessionStorage.getItem('idConta')}`)
            .then(
                response => {
                    setMarcas(response.data?.filter(item => item._id))
                }
            ).finally(
                () => {
                    
                }
            )
        }

        getcategorias()
    }, [])

    useEffect(()=>{
        async function getPartners() {
            
            await api.get(`/parceiro/${id_conta}/*/*/*/1`, {})
            .then(
                response => {
                    setParceiros(response.data.filter(item => item._id))
                }
            ).finally(
                () => {
                    
                }
            )
        }

        getPartners()
    }, [])

    useEffect(()=>{
        async function getLocates() {
            
            await api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {})
            .then(
                response => {
                    setNivel1(response.data.filter(item => item._id))
                }
            ).finally(
                () => {
                    
                }
            )
            await api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/*/*/*/*/*`, {})
            .then(
                response => {
                    setNivel2(response.data.filter(item => item._id))
                }
            ).finally(
                () => {
                    
                }
            )
            await api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/*/*/*/*/*`, {})
            .then(
                response => {
                    setNivel3(response.data.filter(item => item._id))
                }
            ).finally(
                () => {
                    
                }
            )
            await api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/*/*/*/*/*`, {})
            .then(
                response => {
                    setNivel4(response.data.filter(item => item._id))
                }
            ).finally(
                () => {
                    
                }
            )
        }

        getLocates()
    }, [])

    function getID(){	
        return 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    }

    function handleSave(){
        save()
    }

    async function save(){
        
        let option = {headers: { 'Content-Type': ['application/json']}}
        
        await api.post(`/itensDef/${sessionStorage.getItem('idConta')}`, [upComming], option)
        .then(
            response => {
                if(upMarcas.length){
                    api.post('/infs_compl', upMarcas, option).then(
                        response => {
                            setMessage({
                                "type": "success",
                                "title": t('messages.success'),
                                "message": t('settings.clean_base.success_message')
                            })
                        },
                        error=>{
                            setMessage({
                                "type": "error",
                                "title": t('messages.errors.error'),
                                "message": t('messages.errors.500')
                            })
                            
                        }
                    )
                }else{
                    setMessage({
                        "type": "success",
                        "title": t('messages.success'),
                        "message": t('settings.clean_base.success_message')
                    })
                }
            },
            error=>{
                setMessage({
                    "type": "error",
                    "title": t('messages.errors.error'),
                    "message": t('messages.errors.500')
                })
                
            }
        )  
    }

    function handleUpload(uploadFile){
        if(!configImport || !uploadFile || !uploadFile?.length){
            setMessage({
                "type": "error",
                "title": t('messages.error'),
                "message": "Ocorreu um erro ao tentar ler a planilha. Por favor, verifique as configurações de importação e tente novamente."
            })
            return
        }
        handleUploadFile(uploadFile)
    }

    function getItemID() {
        // Obtém a data e a hora atuais
        const now = new Date();
        
        // Formata a data no formato AAAAMMDD
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Mês de 0-11 para 1-12
        const day = String(now.getDate()).padStart(2, '0'); // Dia do mês de 1-31
    
        // Concatena a data e a hora no formato AAAAMMDD-HHMMSS
        const formattedDateTime = `${year}${month}${day}`;
    
        // Gera a parte aleatória do ID
        const randomID = 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    
        // Combina a data e a hora com o ID aleatório
        return `${randomID}_${sessionStorage.getItem('idConta')}${formattedDateTime}`;
    } 

    function getStatus(tag, categoria, duplicado) {
        const regex = /^[0-9A-Fa-f]+$/; 
        if (typeof tag === "string" && !regex.test(tag)) {
            return "Erro: Tag Inválida"; 
        }

        if (!categoria) {
            return "Erro: Categoria Ausente"; 
        }

        if(duplicado){
            return "Erro: Tag Duplicada";
        }
    
        if (itemsList.find(item => item.tag === tag)) {
            return "Registrado";
        }
    
        return "Correto"; 
    }

    function getTag(tag){
        var prefix = prefixConfig
        if(!prefix && sessionStorage.getItem('idConta') == 'OLZO6ZLX9'){ // Tratativa ALRS
            prefix = 'AE02'
        }
        const tagLength = 24 - prefix.length 
        const tagFormat = prefix + tag?.padStart(tagLength, '0')
        return tagFormat
    }

    async function handleUploadFile(uploadFile){
        const upItems = []
        const upCategorias = [] // Categorias não cadastradas
        const upNivel1 = []
        const upNivel2 = []
        const upNivel3 = []
        const upNivel4 = []
        const upParceiros = []
        let upcomingMarcas = []
        
        const categoriaList = categorias || []
        const parceiroList = parceiros || []
        const nivel1List = nivel1 || []
        const nivel2List = nivel2 || []
        const nivel3List = nivel3 || []
        const nivel4List = nivel4 || []
        let marcaList = marcas || []
 
        for (var i = 0; i < uploadFile.length; i++) {
            const {
                tag,
                id_categoria,
                ean,
                id_nivel_loc_1,
                id_nivel_loc_2,
                id_nivel_loc_3,
                id_nivel_loc_4,
                inf_compl_1,
                inf_compl_2,
                inf_compl_3,
                inf_compl_4,
                inf_compl_5,
                inf_compl_6,
                inf_compl_7,
                inf_compl_8,
                inf_compl_9,
                inf_compl_10,
                id_marca,
                id_modelo,
                valor,
                id_parceiro,
                observacao,
                ativo,
                foto,
                id_externo,
                id_item_vinculado,
                up_1,
                up_2,
                up_3,
                up_4,
                up_5
            } =  uploadFile[i]
            const getCategoria = categoriaList.find(c => c.descricao === id_categoria || c.ean === ean)
            const getParceiro = parceiroList.find(c => c.nome === parceiros)
            const getNivel1 = nivel1List.find(c => c.descricao === id_nivel_loc_1) 
            const getNivel2 = nivel2List.find(c => c.descricao === id_nivel_loc_2) 
            const getNivel3 = nivel3List.find(c => c.descricao === id_nivel_loc_3) 
            const getNivel4 = nivel4List.find(c => c.descricao === id_nivel_loc_4) 
            const getMarca = getCategoria && id_marca ? marcaList.find(c => c.marca == id_marca && c.id_categoria == getCategoria._id) : ""
            const getModelo = getMarca ? getMarca?.modelo?.find(c => c.descricao === id_modelo) : ""
            
            const formatTag = tag ? getTag(tag) : '0'.padStart(24, '0')
            const idItem = tag ? itemsList.find(item => item.tag == tag.padStart(24, '0')) : ""
            const isDuplicated = tag ? upItems.find(item=>item.tag == formatTag) : false

            const item = {
                id: i + 1,
                _id: idItem ? idItem?._id : getItemID(),
                id_conta: id_conta,
                id_categoria: id_categoria ? (getCategoria?._id || getID()) : null,
                categoria: getCategoria?.descricao || id_categoria,
                ean: getCategoria?.ean || ean || "",
                tag: formatTag,
                status: getStatus(formatTag, id_categoria, isDuplicated),
                id_nivel_loc_1: id_nivel_loc_1 ? (getNivel1?._id || getID()) : "",
                id_nivel_loc_2: id_nivel_loc_1 ? (getNivel2?._id || getID()) : "",
                id_nivel_loc_3: id_nivel_loc_1 ? (getNivel3?._id || getID()) : "",
                id_nivel_loc_4: id_nivel_loc_1 ? (getNivel4?._id || getID()) : "",
                nivel_loc_1: getNivel1?.descricao || id_nivel_loc_1,
                nivel_loc_2: getNivel2?.descricao || id_nivel_loc_2,
                nivel_loc_3: getNivel3?.descricao || id_nivel_loc_3,
                nivel_loc_4: getNivel4?.descricao || id_nivel_loc_4,
                inf_compl_1: inf_compl_1 || "",
                inf_compl_2: inf_compl_2 || "",
                inf_compl_3: inf_compl_3 || "",
                inf_compl_4: inf_compl_4 || "",
                inf_compl_5: inf_compl_5 || "",
                inf_compl_6: inf_compl_6 || "",
                inf_compl_7: inf_compl_7 || "",
                inf_compl_8: inf_compl_8 || "",
                inf_compl_9: inf_compl_9 || "",
                inf_compl_10: inf_compl_10 || "",
                id_marca: id_marca ? (getMarca?._id || getID()) : "",
                id_modelo: id_modelo ? (getModelo?._id || getID()) : "",
                marca: id_marca,
                modelo: id_modelo,
                valor: valor || "",
                id_parceiro: id_parceiro ? (getParceiro?._id || getID()) : "",
                parceiro: getParceiro?.descricao || id_parceiro,
                ativo: ativo || 1,
                foto: foto || "",
                id_externo: id_externo || "",
                id_item_vinculado: id_item_vinculado || "",
                up_1: up_1 || "",
                up_2: up_2 || "",
                up_3: up_3 || "",
                up_4: up_4 || "",
                up_5: up_5 || "",
                observacao: observacao || ""
            }

            upItems.push(item)

            if(!item.status.includes("Erro")){
                if(!getCategoria && id_categoria){
                    const upCat = {
                        _id: item.id_categoria,
                        descricao: item.categoria,
                        ean: item.ean,
                        id_conta: id_conta
                    }
                    upCategorias.push(upCat)
                    categoriaList.push(upCat)
                }
    
                if(!getNivel1 && id_nivel_loc_1){
                    const upNivel = {
                        _id: item.id_nivel_loc_1,
                        descricao: item.nivel_loc_1,
                        id_conta: id_conta
                    }
                    upNivel1.push(upNivel)
                    nivel1.push(upNivel)
                }
    
                if(!getNivel2 && id_nivel_loc_2){
                    const upNivel = {
                        _id: item.id_nivel_loc_2,
                        descricao: item.nivel_loc_2,
                        id_nivel_loc1: item.id_nivel_loc_1,
                        id_conta: id_conta
                    }
                    upNivel2.push(upNivel)
                    nivel2.push(upNivel)
                }
    
                if(!getNivel3 && id_nivel_loc_3){
                    const upNivel = {
                        _id: item.id_nivel_loc_3,
                        descricao: item.nivel_loc_3,
                        id_nivel_loc2: item.id_nivel_loc_2,
                        id_conta: id_conta
                    }
                    upNivel3.push(upNivel)
                    nivel3.push(upNivel)
                }
    
                if(!getNivel4 && id_nivel_loc_4){
                    const upNivel = {
                        _id: item.id_nivel_loc_4,
                        descricao: item.nivel_loc_4,
                        id_nivel_loc3: item.id_nivel_loc_3,
                        id_conta: id_conta
                    }
                    upNivel4.push(upNivel)
                    nivel4.push(upNivel)
                }

                if(!getMarca && id_marca){
                    const upMarca = {
                        _id: item.id_marca,
                        marca: item.marca,
                        id_categoria: item.id_categoria,
                        modelo: [],
                        id_conta: id_conta
                    }
                    marcaList.push(upMarca)
                    upcomingMarcas.push(upMarca)
                }
    
                if(!getModelo && id_modelo){
                    const upMarca = marcaList?.find(marca => marca.marca === id_marca && marca.id_categoria === item.id_categoria);
                    if (upMarca) {
                        const newModelo = {
                            "_id": item.id_modelo,
                            "id_conta": id_conta,
                            "descricao": item.modelo
                        };
                        const updatedMarca = {
                            ...upMarca,
                            modelo: [...upMarca.modelo, newModelo]
                        };
                        marcaList = marcaList.map(marca => 
                            marca._id === upMarca._id ? updatedMarca : marca
                        );
                        upcomingMarcas = upcomingMarcas.map(marca => 
                            marca._id === upMarca._id ? updatedMarca : marca
                        );
                    }

                }
    
                if(!getParceiro && id_parceiro){
                    upParceiros.push({
                        _id: item.id_parceiro,
                        descricao: item.parceiro,
                        id_nivel_loc3: item.id_nivel_loc_3,
                        id_conta: id_conta
                    })
                }
            }
        }

        setItems(upItems)
        setUpMarcas(upcomingMarcas)
        setUpComming({
            categoria: upCategorias,
            itens: upItems.filter(item => !item.status.includes("Erro")),
            nivel1: upNivel1,
            nivel2: upNivel2,
            nivel3: upNivel3,
            nivel4: upNivel4,
            parceiros: upParceiros
        })
        const grouped = [
            {
                color: green[600],
                label: "Novos Itens",
                icon: "lets-icons:check-ring-duotone",
                value: upItems.filter(item => item.status.includes("Correto"))?.length
            },
            {
                color: grey[500],
                label: "Já cadastrados",
                icon: "lets-icons:check-ring-duotone",
                value: upItems.filter(item => item.status.includes("Registrado"))?.length
            },
            {
                color: red[300],
                label: "Tag repetidas",
                icon: "lets-icons:close-ring-duotone",
                value: upItems.filter(item => item.status.includes("Duplicada"))?.length
            },
            {
                color: red[300],
                label: "Itens incorretos",
                icon: "lets-icons:close-ring-duotone",
                value: upItems.filter(item => item.status.includes("Erro"))?.length
            },
            {
                color: grey[500],
                label: "Itens importados",
                icon: "lets-icons:folder-check-duotone",
                value: upItems.filter(item => !item.status.includes("Erro"))?.length
            },
            {
                color: grey[500],
                label: "Total de itens",
                icon: "lets-icons:folder-up-duotone",
                value: upItems?.length
            }
        ]
        setImportCount(grouped)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{py: 3}}>
                <GridToolbarFilterButton/>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <Stack spacing={3}>
                
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h1">Importar arquivo XML</Typography>
                    <Stack spacing={1} direction="row">
                        <Button variant="outlined" color="dark" onClick={e=> {navigate('/items')}}>{t('actions.exit')}</Button>
                        <Button variant="contained" onClick={e=> {handleSave()}}>{t('actions.save')}</Button>
                    </Stack>
                </Stack>

                <Stack spacing={3} as={Paper} sx={{borderRadius: 3, p: 3}}>
                    <Stack direction="row" justifyContent="end">
                        <Button variant="outlined" color="dark" startIcon={<Icon icon="solar:settings-linear"/>} onClick={e=>{navigate('/items/import/config/xml', { state: { config: configImport } })}}>
                            Configurar
                        </Button>
                    </Stack>

                    <ImportXML 
                        {...configImport}
                        onUpload={e=>{handleUpload(e)}}
                    />

                    {importCount ? 
                        <Stack direction="row" justifyContent="center">
                            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1} sx={{boxShadow: 1, p: 2, borderRadius: 2, border: 1, borderColor: grey[100]}}>
                                {importCount?.map((item)=>(
                                    <>
                                        <Stack direction="row" alignItems="center" spacing={1} sx={{p: 1, px:2}}>
                                            <Icon width="32" icon={item.icon} color={item?.color}/>
                                            <Typography>{item.label}:</Typography>
                                            <Typography>{item.value}</Typography>
                                        </Stack> 
                                    </>
                                ))}
                            </Stack>
                        </Stack>
                    : ""}

                    <DataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={items}
                        columns={columns}
                        editMode="row"
                        disableRowSelectionOnClick={true}
                        sx={{
                            border: 0
                        }}
                        slots={{ toolbar: CustomToolbar }}
                        pageSizeOptions={[10, 50, 100, 500, 1000]}
                        processRowUpdate={(updatedRow, originalRow) => {
                            setItems(prevRows => {
                                const newRows = [...prevRows];
                                newRows[originalRow.id] = updatedRow;
                                return newRows;
                            });
                        }}                        
                        getRowHeight={({ id, densityFactor }) => {
                            if (id % 2 === 0) {
                              return 50 * densityFactor;
                            }
                  
                            return null;
                        }}
                        slotProps={{
                            columnsManagement: {
                                toggleAllMode: 'filteredOnly', 
                            },
                        }}
                    />
                </Stack>
            </Stack>

            

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{
                        setMessage(e)
                    }}
                />
            : ''}            
        </>
    )
}